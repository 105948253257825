import { AssessmentDecision } from "models/thorEvents/eventModels";
import { ReactNode } from "react";

export enum BatchHoursReduced {
    Yes = 0,
    No = 1,
    NoDecisionYet = 2,
}

export interface LynxAssessmentCardProps {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    action: string;
    actionDate: string | Date | null;
    decision: AssessmentDecision;
    isWithFirstEconomicCustomer: boolean | null;
    batchHoursReduced: BatchHoursReduced;
    justification?: string | null;
    infoBadgeText: ReactNode;
}
