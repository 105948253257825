import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventStatusBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        statusPadding: {
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
        },
        new: {
            color: theme.colors.dataVisualization.sky,
            backgroundColor: theme.colors.dataVisualization.sky100,
        },
        pendingReview: {
            color: theme.colors.warning.warning600,
            backgroundColor: theme.colors.warning.warning100,
        },
        pendingApproval: {
            color: theme.colors.dataVisualization.plum,
            backgroundColor: theme.colors.dataVisualization.plum100,
        },
        completed: {
            color: theme.colors.success.success600,
            backgroundColor: theme.colors.success.success100,
        },
        customEventStatusBox: {
            whiteSpace: "nowrap",
            fontSize: "0.875rem",
            lineHeight: "1rem",
            fontFamily: "Gibson-Semibold",
            textTransform: "uppercase",
            letterSpacing: "0.07rem",
            display: "inline-block",
        },
    })
);
