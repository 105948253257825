import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const authModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        formErrorYes: {
            display: "block",
        },

        formErrorNo: {
            display: "none",
        },

        form: {
            width: "100%",
        },

        input: {
            width: "60%",
        },

        iconButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },

        forgotPasswordLink: {
            width: "fit-content",
            marginTop: "1rem",
        },
    })
);
