import dayjs, { Dayjs } from "dayjs";

export function convertDateToSelectedTimezone(
    date: Date | string | number | null | undefined | Dayjs,
    timezone: string,
    keepLocalTime?: boolean
): Dayjs {
    const convertedDate = dayjs(date).tz(timezone, keepLocalTime);

    // We have an issues (displaying, selecting) with timezones +00 offset due to converting dates into these timezones
    // https://day.js.org/docs/en/timezone/converting-to-zone

    // In this case dayjs recommend parse timezones, but it does`t cover all our cases.
    // https://day.js.org/docs/en/timezone/parsing-in-zone

    if (convertedDate.utcOffset() === 0) {
        // So here we use converting with forced keeping local time.
        return dayjs(date).tz(timezone, true);
    }

    return convertedDate;
}

export function dateInUTC(date: Date | string | number | null | undefined | Dayjs, keepLocalTime?: boolean): Dayjs {
    return keepLocalTime ? dayjs(date).utc(true) : dayjs.utc(date);
}

export function dateInLocalTimezone(date: Date | string | number | null | undefined | Dayjs): Dayjs {
    return dayjs(date);
}

export function getFormatedTimezoneOffset(
    date: Date | string | number | null | undefined | Dayjs,
    timezone?: string
): string {
    if (!timezone) {
        return "";
    }

    const offset = convertDateToSelectedTimezone(date, timezone).utcOffset();

    return `(UTC ${offset >= 0 ? "+" : "-"}${Math.floor(Math.abs(offset) / 60)
        .toString()
        .padStart(2, "0")}${Math.abs(offset) % 60 ? `:${(Math.abs(offset) % 60).toString().padStart(2, "0")}` : ""})`;
}
