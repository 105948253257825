import { observer } from "mobx-react";
import { duplicateDevicesWarningModalStyles } from "./DuplicateDevicesWarningModalStyles";
import { DuplicateDevicesWarningModalProps } from "./DuplicateDevicesWarningModalProps";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxRedirectLink } from "../LynxRedirectLink/LynxRedirectLink";
import { useFormikContext } from "formik";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import { convertToLinkItems, getUniqueSerialNumbers } from "components/ThorEvents/EventCreation/helper-functions";

export const DuplicateDevicesWarningModal = observer((props: DuplicateDevicesWarningModalProps) => {
    const classes = duplicateDevicesWarningModalStyles();
    const formik = useFormikContext();
    const { onClose, header, isEventEditing, duplicateDeviceEvents, ...rest } = props;

    const duplicateDeviceEventsLinks = convertToLinkItems(duplicateDeviceEvents);

    const onCancel = () => {
        onClose();
        formik.setSubmitting(false);
    };

    if (!duplicateDeviceEventsLinks) {
        return null;
    }

    return (
        <LynxModal
            header="Duplicate found!"
            primaryButtonTitle={isEventEditing ? "Save Changes" : "Create Event"}
            backButtonTitle="Back to Edit"
            onClose={onCancel}
            size="xsmall"
            {...rest}
        >
            <LynxTypography variant="body" color="neutral500">
                {getUniqueSerialNumbers(duplicateDeviceEvents.map((x) => x.devices).flat())}
            </LynxTypography>

            {duplicateDeviceEventsLinks && duplicateDeviceEventsLinks.length > 0 ? (
                <ul className={classes.listItem}>
                    {duplicateDeviceEventsLinks.map((item, index) => (
                        <li className={classes.listItemText} key={index}>
                            <LynxRedirectLink to={item.url} target="_blank" typographyComponent="span">
                                {item.text}
                            </LynxRedirectLink>
                        </li>
                    ))}
                </ul>
            ) : null}
            <LynxTypography variant="body" color="neutral500">
                {isEventEditing
                    ? "Would you like to still save changes or get back to editing it?"
                    : "Would you like to still create an event or get back to editing it?"}
            </LynxTypography>
        </LynxModal>
    );
});
