import { GetStabilityFormChangesHistoryRequest } from "api/models/stabilityForms/stabilityFormsApi";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";
import { getPermissionKey } from "helpers/permissionHelpers";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { FormSection } from "./SummarySections/FormSection";
import HistorySection from "./SummarySections/HistorySection";
import ProductSection from "./SummarySections/ProductSection";
import RangesSection from "./SummarySections/RangesSection";

export const StabilityFormSummary = observer(() => {
    const classes = stabilityFormStyles();
    const navigate = useNavigate();
    const { stabilityFormId } = useParams();
    const { stabilityFormStore, identityStore, permissionsStore, commonStore } = useStore();

    const key = getPermissionKey(actions.customer.tor.stabilityForms.viewDetails, identityStore.currentCustomer.id);
    const viewDetailsPermissionStatus = permissionsStore.getPermissionStatus(key);

    const waitingForPermissionCheck =
        viewDetailsPermissionStatus === undefined || viewDetailsPermissionStatus === MarvelPermissionStatus.Loading;

    const accessDenied = identityStore.isSystemSpace || viewDetailsPermissionStatus === MarvelPermissionStatus.Deny;
    const isValid = isGuidValid(stabilityFormId);

    useEffect(() => {
        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied]);

    useEffect(() => {
        if (!isValid) {
            return navigate(routes.pageNotFound);
        }
    }, [stabilityFormId]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }
        if (stabilityFormId && isValid) {
            stabilityFormStore.getStabilityFormDetails(identityStore.currentCustomer.id, stabilityFormId);
        }
        return () => {
            stabilityFormStore.resetStabilityFormData();
            commonStore.setShowGeneralErrorPageToFalse();
        };
    }, [viewDetailsPermissionStatus, stabilityFormId]);

    useEffect(() => {
        if (stabilityFormId && isValid) {
            const request: GetStabilityFormChangesHistoryRequest = {
                customerId: identityStore.currentCustomer.id,
                stabilityFormId: stabilityFormId,
                pageNumber: stabilityFormStore.currentHistoryPage,
                pageSize: stabilityFormStore.pageHistorySize,
            };
            stabilityFormStore.loadStabilityFormHistoryChanges(request);
        }
    }, [stabilityFormStore.currentHistoryPage, stabilityFormStore.pageHistorySize]);

    return (
        <main className={classes.root}>
            <Helmet>
                <title>{`${stabilityFormStore.formDetails.product.name} Stability Form`}</title>
            </Helmet>
            {stabilityFormStore.progressFlags.loadingStabilityForm ? (
                <div>
                    <FullScreenLoadingIndicator />
                </div>
            ) : (
                <>
                    <div className={classes.buttonsAboveHeaderContainer}>
                        <LynxBackButton path={routes.stabilityForms} title={"Stability Forms List"} />
                        <div>
                            <LynxButton
                                variant="tertiary"
                                onClick={() => navigate(`/stabilityForms/${stabilityFormStore.formDetails.id}/audit`)}
                                leftIcon={<LynxIcon name="history" />}
                            >
                                Audit Trail
                            </LynxButton>
                            <LynxButton
                                variant="tertiary"
                                size="medium"
                                onClick={() =>
                                    stabilityFormStore.generatePdf(
                                        identityStore.currentCustomer.id,
                                        stabilityFormStore.formDetails.id
                                    )
                                }
                                loading={stabilityFormStore.progressFlags.exportingPdf}
                                disabled={stabilityFormStore.progressFlags.exportingPdf}
                                leftIcon={<LynxIcon name="print" />}
                            >
                                Print
                            </LynxButton>
                        </div>
                    </div>

                    {commonStore.showGeneralErrorPage ? (
                        <GeneralErrorPage />
                    ) : (
                        <>
                            <FormSection />
                            <ProductSection />
                            <RangesSection />
                            {stabilityFormStore.stabilityFormChangesHistory.length > 0 && <HistorySection />}
                        </>
                    )}
                </>
            )}
        </main>
    );
});
