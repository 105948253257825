import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventInfoStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0.5rem 0",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
            alignItems: "stretch",
            justifyContent: "space-between",

            "&.sticky": {
                padding: "0.5rem 0 0 0",
                borderBottom: "none",
                alignItems: "end",
            },
        },

        eventIdCaption: {
            "&.sticky": {
                display: "none",
            },
        },

        idAndStatus: {
            alignContent: "center",
            padding: "0 1rem",
        },

        eventId: {
            "&.sticky": {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },

        secondSection: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
        },

        buttons: {
            display: "flex",
            justifyContent: "flex-end",
        },

        dates: {
            padding: "0.5rem 0 0 0",
            justifyContent: "flex-end",
            gap: "0.5rem",
            "&.sticky": {
                display: "none",
                padding: "0",
            },
        },
    })
);
