import { CustomerLevelRequest } from "../sharedModels/CustomerLevelRequest";
import { PageModel } from "../sharedModels/PageModel";
import { Page } from "models/shared/Page";
import { JSONObject } from "helpers/typeHelpers";

export enum RootEntityType {
    Event = 0,
    StabilityForm = 1,
    User = 2,
    UserGroup = 3,
    Customer = 4,
}

export enum EntityAction {
    Added = 0,
    Updated = 1,
    Deleted = 2,
}

export enum AuditFieldType {
    String = 0,
    Number = 1,
    Duration = 2,
    Date = 3,
    DateTime = 4,
    User = 5,
    Temperature = 6,
    Array = 7,
}

export interface AuditEntryModel {
    id: string;
    timestamp: string;
    userId: string;
    email: string | null;
    customerId: string | null;
    businessActionId: string;
    businessActionName: string;
    entityType: RootEntityType | null;
    entityId: string | null;
    payload: string | null;
}

export interface AuditEntityRecordBase {
    table: string;
    pk: string | number | { [key: string]: string | number };
}

export interface AuditFullEntityPayload extends AuditEntityRecordBase {
    columns: { [key: string]: JSONObject };
}

export interface AuditPropertyModification {
    column: string;
    old: JSONObject;
    new: JSONObject;
}

export interface AuditModifiedEntityRecord extends AuditEntityRecordBase {
    columns: AuditPropertyModification[];
}

export interface AuditEfEntityChangesPayload {
    added: AuditFullEntityPayload[];
    modified: AuditModifiedEntityRecord[];
    deleted: AuditFullEntityPayload[];
    customData: JSONObject;
}

export interface GetAuditEntriesRequest extends CustomerLevelRequest, PageModel {
    entityId: string;
    entityType: RootEntityType;
}

export type GetAuditEntriesResponse = Page<AuditEntryModel>;

export interface HumanReadableFieldChangeModel {
    column: string;
    columnDisplayName: string;
    oldValue: JSONObject;
    newValue: JSONObject;
    type: AuditFieldType;
}

export interface AuditReasonsModel {
    triggeringEntityType: string | null;
    triggeringEntityDisplayName: string | null;
    reasons: string[];
}

export interface HumanReadableAuditEntryPayloadModel {
    currentLevelName: string | null;
    currentLevelAction: EntityAction | null;
    changedFields: HumanReadableFieldChangeModel[];
    lowerLevels: HumanReadableAuditEntryPayloadModel[];
    initialEventDisplayId: number | null;
    reasons: AuditReasonsModel[];
}

export interface HumanReadableAuditEntryModel {
    id: string;
    actionId: string;
    actionName: string;
    timestamp: string;
    userId: string;
    userFirstName: string | null;
    userLastName: string | null;
    payloadExists: boolean;
    payloadParserDefined: boolean;
    payloadParsedSuccessfully: boolean;
    payload: HumanReadableAuditEntryPayloadModel | null;
    displayAction: boolean;
}

// audit entries models
interface GetAuditEntriesBaseRequest {
    entityId: string;
    entityType: RootEntityType;
}
type GetHumanReadableAuditEntriesBaseRequest = GetAuditEntriesBaseRequest & PageModel;

interface ExportAuditEntriesToPdfRequest extends GetHumanReadableAuditEntriesBaseRequest {
    currentTimezone: string;
}

export type GetHumanReadableCustomerAuditEntriesRequest = CustomerLevelRequest &
    GetHumanReadableAuditEntriesBaseRequest;
export type GetHumanReadableSystemAuditEntriesRequest = GetHumanReadableAuditEntriesBaseRequest;
export type GetHumanReadableAuditEntriesResponse = Page<HumanReadableAuditEntryModel>;

// audit entity short info models
export type GetSystemAuditEntityShortInfoRequest = GetAuditEntriesBaseRequest;
export type GetCustomerAuditEntityShortInfoRequest = GetAuditEntriesBaseRequest & CustomerLevelRequest;

export interface AuditEntityShortInfoResponse {
    id: string;
    displayId: string;
    customerId: string | null;
    timezone: string | null;
}

// audit pdf/csv models
export type ExportCustomerAuditEntriesToPdfRequest = ExportAuditEntriesToPdfRequest & CustomerLevelRequest;
export type ExportSystemAuditEntriesToPdfRequest = ExportAuditEntriesToPdfRequest;

export type ExportCustomerAuditEntriesToCsvRequest = GetHumanReadableAuditEntriesBaseRequest & CustomerLevelRequest;
export type ExportSystemAuditEntriesToCsvRequest = GetHumanReadableAuditEntriesBaseRequest;

export interface AuditUserSignInFailureRequest {
    email: string;
    oktaError: string;
}
