import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxDateTimePickerStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            marginBottom: "0.25rem",
        },

        container: {
            padding: "0.5re 1rem",
            borderRadius: "0.25rem",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            boxShadow: "none",

            "& svg": {
                fill: theme.colors.neutrals.neutral400,
                color: theme.colors.neutrals.neutral400,
            },

            "&:hover": {
                borderColor: theme.colors.neutrals.neutral300,
                boxShadow: "none",
            },

            // picker-focused
            "&.ant-picker-focused": {
                borderColor: theme.colors.primary.blue400,
            },

            // input
            "&.ant-picker .ant-picker-input >input": {
                height: "1.5rem",
                fontSize: "1rem",
                fontFamily: "Gibson",
                color: theme.colors.neutrals.neutral600,
            },

            "&.ant-picker .ant-picker-input >input::placeholder ": {
                height: "1.5rem",
                fontSize: "1rem",
                fontFamily: "Gibson",
                color: theme.colors.neutrals.neutral300,
            },

            // picker-disabled
            "&.ant-picker.ant-picker-disabled": {
                borderColor: theme.colors.neutrals.neutral300,
                backgroundColor: theme.colors.neutrals.neutral200,
                "& svg": {
                    fill: theme.colors.neutrals.neutral300,
                    color: theme.colors.neutrals.neutral300,
                },
            },

            // disabled input
            "&.ant-picker .ant-picker-input >input[disabled]": {
                color: theme.colors.neutrals.neutral300,
                borderColor: theme.colors.neutrals.neutral300,
                backgroundColor: theme.colors.neutrals.neutral200,
            },
        },

        popup: {
            fontFamily: "Gibson",

            /* picker action buttons */
            "&.ant-picker-dropdown .ant-picker-header >button": {
                color: theme.colors.neutrals.neutral500,
            },
            /* current date */
            "&.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before": {
                borderColor: theme.colors.primary.blue400,
            },
            /* selected date */
            "&.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner":
                {
                    color: theme.colors.neutrals.white,
                    background: theme.colors.primary.blue500,
                },
            /* today button */
            "&.ant-picker-dropdown .ant-picker-today-btn": {
                color: theme.colors.primary.blue500,
            },
            /* today button  active */

            "&.ant-picker-dropdown .ant-picker-today-btn:active": {
                color: theme.colors.primary.blue600,
            },
            /* today button hover */
            "&.ant-picker-dropdown .ant-picker-today-btn:hover": {
                color: theme.colors.primary.blue400,
            },
            /* header buttons hover */
            "&.ant-picker-dropdown .ant-picker-header-view button:hover": {
                color: theme.colors.primary.blue500,
            },

            /* selected time */
            "&.ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner":
                {
                    backgroundColor: theme.colors.primary.blue100,
                },

            /* time hover */
            "&.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover":
                {
                    background: theme.colors.primary.blue400,
                    color: theme.colors.neutrals.white,
                },

            /* now btn */
            "& a ": {
                padding: "0.25rem 0.5rem",
                borderRadius: "0.25rem",
                color: theme.colors.primary.blue500,
            },

            /* now btn hover */
            "& a:hover": {
                backgroundColor: theme.colors.primary.blue100,
                color: theme.colors.primary.blue400,
            },
        },
    })
);
