import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const dismissPopupsButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            justifyContent: "end",
        },
        dismissBtnBoxShadow: {
            boxShadow: "0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05)",
            backgroundColor: theme.colors.neutrals.white,
        },
    })
);
