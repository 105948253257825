import { createContext, useContext } from "react";
import { AuditStore } from "./AuditStore";
import CommonStore from "./CommonStore";
import CustomerDataStore from "./CustomerDataStore";
import { CustomerStore } from "./CustomerStore";
import { GroupStore } from "./GroupStore";
import IdentityStore from "./IdentityStore";
import { PermissionsStore } from "./PermissionsStore";
import { StabilityFormStore } from "./StabilityFormStore";
import { ThorEventViewStore } from "./ThorEventStore";
import { UserStore } from "./UserStore";

interface Store {
    commonStore: CommonStore;
    thorEventViewStore: ThorEventViewStore;
    userStore: UserStore;
    customerStore: CustomerStore;
    identityStore: IdentityStore;
    groupStore: GroupStore;
    permissionsStore: PermissionsStore;
    stabilityFormStore: StabilityFormStore;
    customerDataStore: CustomerDataStore;
    auditStore: AuditStore;
}

const customerDataStore = new CustomerDataStore();
const identityStore = new IdentityStore(customerDataStore);
const commonStore = new CommonStore();

export const store: Store = {
    commonStore: commonStore,
    thorEventViewStore: new ThorEventViewStore(identityStore, commonStore),
    userStore: new UserStore(identityStore, commonStore),
    customerStore: new CustomerStore(identityStore, commonStore),
    identityStore: identityStore,
    groupStore: new GroupStore(identityStore, commonStore),
    permissionsStore: new PermissionsStore(),
    stabilityFormStore: new StabilityFormStore(commonStore),
    customerDataStore: customerDataStore,
    auditStore: new AuditStore(commonStore),
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
