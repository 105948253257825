import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { SetFlowForAssessmentRequest } from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { commonConstants , promptTooltipMessages } from "lynxConstants";
import { formatDurationToString } from "helpers/formatDurationToString";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { observer } from "mobx-react";
import { AssessmentStatus } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import React, { ReactNode } from "react";
import { useStore } from "store/StoreConfigs";

import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { AssessmentGeneralInformationProps } from "./AssessmentGeneralInformationProps";

export const AssessmentGeneralInformation = observer((props: AssessmentGeneralInformationProps) => {
    const { permissionsStore, thorEventViewStore, identityStore } = useStore();
    const { assessment, flows } = props;

    const permissionKey = getPermissionKey(actions.customer.tor.events.editDetails, thorEventViewStore.eventDetails.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    const classes = assessmentDetailsStyles();

    const isEventWithHandlingUnits = thorEventViewStore.eventDetails.handlingUnits.some((x) => x);

    const getTooltipMessage = () => {
        const details = thorEventViewStore.eventDetails;
        const isCurrentUserIsReviever = details.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = details.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case assessment.status === AssessmentStatus.EvaluationCompleted:
                return promptTooltipMessages.event.reviewCompleted;
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;
            case permissionStatus !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    const tableColumnHeader = (name: ReactNode) => {
        return (
            <TableCell className={classes.generalInfoHeaderCell}>
                <LynxTypography color="neutral400" variant="body-s">
                    {name}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <AssessmentDetailsSection title="General Information">
            <Table
                className={clsx({
                    [classes.generalInfoTable]: !isEventWithHandlingUnits,
                })}
            >
                <TableHead>
                    <TableRow>
                        {tableColumnHeader("Excursion Date")}
                        {tableColumnHeader("Event Excursion Time")}
                        {tableColumnHeader("Number of Spikes")}
                        {tableColumnHeader("Flow")}
                        {isEventWithHandlingUnits && tableColumnHeader("Temperature Recording Device")}
                        {isEventWithHandlingUnits && tableColumnHeader("Handling Unit Number")}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.verticalAlignTop}>
                        <TableCell className={classes.generalInfoRowCell}>
                            <LynxTypography>
                                {!assessment.excursionStartedAt
                                    ? commonConstants.emptyValue
                                    : convertDateToSelectedTimezone(
                                          assessment.excursionStartedAt,
                                          thorEventViewStore.eventDetails.timezone
                                      ).format(commonConstants.fullDateTimeFormat)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell className={classes.generalInfoRowCell}>
                            <LynxTypography>{formatDurationToString(assessment.totalExcursionDuration)}</LynxTypography>
                        </TableCell>
                        <TableCell className={classes.generalInfoRowCell}>
                            <LynxTypography>
                                {!assessment.totalSpikesCount ? "—" : assessment.totalSpikesCount}
                            </LynxTypography>
                        </TableCell>
                        <TableCell className={classes.generalInfoRowCell}>
                            <PromptTooltip placement="top" title={getTooltipMessage()}>
                                <div className={classes.tooltipSection}>
                                    <LynxSelect
                                        value={assessment.flowId || "no-flows"}
                                        disabled={
                                            assessment.status === AssessmentStatus.EvaluationCompleted ||
                                            thorEventViewStore.progressFlags.loadingProductFlows ||
                                            thorEventViewStore.progressFlags.selectingProductFlow ||
                                            permissionStatus !== MarvelPermissionStatus.Allow ||
                                            flows.length === 0
                                        }
                                        loading={
                                            thorEventViewStore.progressFlags.loadingProductFlows ||
                                            thorEventViewStore.progressFlags.selectingProductFlow ||
                                            permissionStatus === MarvelPermissionStatus.Loading
                                        }
                                        onChange={(e) => {
                                            const request: SetFlowForAssessmentRequest = {
                                                customerId: identityStore.currentCustomer.id,
                                                eventId: thorEventViewStore.eventDetails.id,
                                                assessmentId: assessment.id,
                                                flowId: e.target.value as string,
                                            };

                                            thorEventViewStore.setFlowForAssessment(request);
                                        }}
                                    >
                                        {flows.length > 0 ? (
                                            flows.map((flow) => (
                                                <MenuItem value={flow.id} key={flow.id}>
                                                    {flow.region ?? "All Regions and Countries"}:{" "}
                                                    {flow.name ?? flow.steps ?? commonConstants.emptyValue}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="no-flows">All Regions and Countries</MenuItem>
                                        )}
                                    </LynxSelect>
                                </div>
                            </PromptTooltip>
                        </TableCell>
                        {isEventWithHandlingUnits && (
                            <TableCell className={classes.generalInfoRowCell}>
                                <LynxTypography>
                                    {assessment.devices.map((x, index) => (
                                        <React.Fragment key={x}>
                                            {`${x}${index !== assessment.devices.length - 1 ? "," : ""}`} <br />
                                        </React.Fragment>
                                    ))}
                                </LynxTypography>
                            </TableCell>
                        )}
                        {isEventWithHandlingUnits && (
                            <TableCell className={classes.generalInfoRowCell}>
                                {assessment.handlingUnitIsVirtual ? (
                                    <LynxTypography color="critical500">
                                        &lt;{assessment.handlingUnitNumber}&gt;
                                    </LynxTypography>
                                ) : (
                                    <LynxTypography>{assessment.handlingUnitNumber}</LynxTypography>
                                )}
                            </TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </AssessmentDetailsSection>
    );
});
