import { isNumber } from "lodash";
import { RangeOperatorFrom, RangeOperatorTo } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ValidationConstants } from "validation/constants";
import { number, object } from "yup";

export const transportationConditionsValidationSchema = () =>
    object({
        transportationLowerLimit: number()
            .when("transportationLowerLimitOperator", {
                is: RangeOperatorFrom.INFINITY,
                then: (x) =>
                    x
                        .notRequired()
                        .nullable()
                        .transform((value, originalValue) => (originalValue === "" ? null : value)),
                otherwise: (x) => x.required(),
            })
            .min(ValidationConstants.minAllowedTemperature)
            .max(ValidationConstants.maxAllowedTemperature)
            .typeError("Must be a number")
            .test((lower, ctx) => {
                const upper = ctx.parent.transportationUpperLimit;

                if (isNumber(lower) && isFinite(lower) && isNumber(upper) && isFinite(upper)) {
                    return lower < upper ? true : ctx.createError({ message: `Must be less than ${upper}` });
                }

                return true;
            }),

        transportationUpperLimit: number()
            .when("transportationUpperLimitOperator", {
                is: RangeOperatorTo.INFINITY,
                then: (x) =>
                    x
                        .notRequired()
                        .nullable()
                        .transform((value, originalValue) => (originalValue === "" ? null : value)),
                otherwise: (x) => x.required(),
            })
            .min(ValidationConstants.minAllowedTemperature)
            .max(ValidationConstants.maxAllowedTemperature)
            .typeError("Must be a number"),
    });
