import { Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTextarea from "components/LynxComponents/LynxTextarea/LynxTextarea";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { StabilityFlowTableHeader } from "components/StabilityForm/FormFieldsAreas/RegionFieldsArea";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { allCountriesAndRegions } from "components/StabilityForm/helper-functions";
import { getRangeDisplay } from "helpers/temperatureOperatorsParser";
import { formatDurationToString } from "helpers/formatDurationToString";
import _ from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";

interface RegionOption {
    id: string;
    value: string;
}

export default observer(function RangesSection() {
    const classes = stabilityFormStyles();
    const { stabilityFormStore } = useStore();
    const [selectedRegionId, setSelectedRegionId] = useState<string>(allCountriesAndRegions);

    const flowsAndRegionsExist = stabilityFormStore.formDetails.temperatureRanges.some(
        (x) => x.rangeRegions.length > 0
    );
    let regions: RegionOption[] = [];
    let correctedRegions: RegionOption[] = [];

    if (flowsAndRegionsExist) {
        regions = _.flatten(
            stabilityFormStore.formDetails.temperatureRanges.map((x) =>
                x.rangeRegions.map((y) => {
                    return { id: y.regionId, value: y.regionName };
                })
            )
        );

        correctedRegions = [{ id: allCountriesAndRegions, value: allCountriesAndRegions }];

        regions.forEach((x) => {
            const value = correctedRegions.find((y) => y.id === x.id);

            if (value || x.id === "") {
                return;
            }

            correctedRegions.push(x);
        });
    }

    const flowTableHeaders: StabilityFlowTableHeader[] = [
        { key: 0, label: "Flow", width: "10%", align: "left" },
        { key: 1, label: "Stops", width: "20%", align: "left" },
        { key: 2, label: "Planned Deduction", width: "18%", align: "left" },
        { key: 3, label: "Remaining Stability Budget", width: "18%", align: "left" },
        { key: 4, label: "References", width: "34%", align: "left" },
    ];

    return (
        <SectionCard>
            <LynxTypography variant="h2" className={classes.marginBottomLarge}>
                Stability Temperature Ranges {`(${stabilityFormStore.formDetails.temperatureRanges.length})`}
            </LynxTypography>
            {flowsAndRegionsExist && (
                <div>
                    <LynxTypography>Country or Region</LynxTypography>
                    <LynxSelect
                        value={selectedRegionId}
                        onChange={(e) => {
                            setSelectedRegionId(e.target.value as string);
                        }}
                        className={classes.regionSelectWidth}
                    >
                        {correctedRegions.map((region, index) => (
                            <MenuItem key={index} value={region.id}>
                                {region.value}
                            </MenuItem>
                        ))}
                    </LynxSelect>
                </div>
            )}
            {stabilityFormStore.formDetails.temperatureRanges.map((range) =>
                range.rangeRegions.length <= 0 ? (
                    <Grid container key={range.id} className={clsx(classes.rangeSection, classes.marginTopMedium)}>
                        <Grid item xs={2}>
                            <LynxTextarea
                                className={classes.textArea}
                                value={getRangeDisplay(range.lowerLimit, range.upperLimit)}
                                readOnly={true}
                                label="Temperature Range"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <LynxTextarea
                                value={formatDurationToString(range.totalStabilityBudget)}
                                readOnly={true}
                                label="Total Stability Budget"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <LynxTextarea
                                value={formatDurationToString(range.medicalInfo)}
                                readOnly={true}
                                label="Medical Info"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <LynxTextarea
                                value={formatDurationToString(range.remainingStabilityBudget)}
                                readOnly={true}
                                label="Remaining Stability Budget"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <LynxTextarea value={range.references} readOnly={true} label="References" />
                        </Grid>
                    </Grid>
                ) : range.rangeRegions.some((x) => x.regionId === selectedRegionId) ||
                  selectedRegionId === allCountriesAndRegions ? (
                    <Grid container className={clsx(classes.rangeSection, classes.marginTopMedium)} key={range.id}>
                        <Grid
                            container
                            item
                            className={clsx(
                                classes.dividerBottom,
                                classes.marginBottomSmall,
                                classes.paddingBottomSmall
                            )}
                        >
                            <Grid item lg={3} md={4} sm={4}>
                                <LynxTextarea
                                    className={classes.textArea}
                                    value={getRangeDisplay(range.lowerLimit, range.upperLimit)}
                                    readOnly={true}
                                    label="Temperature Range"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LynxTextarea
                                    value={formatDurationToString(range.totalStabilityBudget)}
                                    readOnly={true}
                                    label="Total Stability Budget"
                                />
                            </Grid>
                            <Grid item lg={7} md={6} sm={6}>
                                <LynxTextarea value={range.references} readOnly={true} label="References" />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <LynxTypography variant="h3" color="neutral400" className={classes.marginTopMedium}>
                                Countries & regions {`(${range.rangeRegions.length})`}
                            </LynxTypography>
                        </Grid>

                        {range.rangeRegions.map((rangeRegion) =>
                            rangeRegion.regionId === selectedRegionId || selectedRegionId === allCountriesAndRegions ? (
                                <Grid
                                    container
                                    key={rangeRegion.id}
                                    item
                                    className={clsx(classes.marginBottomMedium, classes.marginTopMedium)}
                                >
                                    <Grid container item spacing={4} xs={12}>
                                        <Grid item lg={3} md={4} sm={4}>
                                            <LynxTextarea
                                                value={
                                                    rangeRegion.regionName
                                                        ? rangeRegion.regionName
                                                        : "All Countries & Regions"
                                                }
                                                label="Region"
                                                readOnly={true}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4}>
                                            <LynxTextarea
                                                value={formatDurationToString(rangeRegion.medicalInfo)}
                                                readOnly={true}
                                                label="Medical Info"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {flowTableHeaders.map((x) => (
                                                        <TableCell
                                                            key={x.key}
                                                            className={clsx(
                                                                classes.tableCellNoBorder,
                                                                classes.verticalAlignBottom
                                                            )}
                                                            align={x.align}
                                                            style={{ width: x.width }}
                                                        >
                                                            <LynxTypography variant="body-s" color="neutral400">
                                                                {x.label}
                                                            </LynxTypography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rangeRegion.flows.map((flow) => (
                                                    <TableRow key={flow.id} className={classes.tableContentRow}>
                                                        <TableCell>
                                                            <LynxTextarea value={flow.name} readOnly={true} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LynxTextarea value={flow.steps} readOnly={true} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LynxTextarea
                                                                value={formatDurationToString(flow.plannedDeduction)}
                                                                readOnly={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LynxTextarea
                                                                value={formatDurationToString(
                                                                    flow.remainingStabilityBudget
                                                                )}
                                                                readOnly={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LynxTextarea value={flow.references} readOnly={true} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            ) : null
                        )}
                    </Grid>
                ) : null
            )}
        </SectionCard>
    );
});
