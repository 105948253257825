import { allCountriesAndRegions } from "components/StabilityForm/helper-functions";
import _ from "lodash";
import {
    RangeOperatorFrom,
    RangeOperatorTo,
    TemperatureRangeRegionModel,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import { helperObjects } from "validation/helper-objects";
import { array, number, object, string } from "yup";

export const stabilityFormValidationSchema = () =>
    object({
        number: string().notRequired().max(100),
        version: string().notRequired().max(100),
        effectiveDate: string().required(),
        ownerEmail: string().notRequired().max(200),
        product: object({
            displayId: string().required().trim().test(helperObjects.zerosNotAllowed).max(100),
            name: string().required().max(200),
            genericName: string().notRequired().max(200),
            storageConditionFrom: string()
                .max(10)
                .when("rangeOperatorFrom", {
                    is: (rangeOperatorFrom: RangeOperatorFrom) => {
                        if (rangeOperatorFrom === RangeOperatorFrom.INFINITY) {
                            return false;
                        }
                        return true;
                    },
                    then: (schema) => schema.required().trim().test(helperObjects.isNumber),
                    otherwise: (schema) => schema.notRequired(),
                })
                .when("storageConditionTo", (storageConditionTo: string, schema: any) => {
                    return schema.test({
                        name: "lowNotGreaterThanOrEqualToHigh",
                        message: `Must be less than ${Number(storageConditionTo)}`,
                        test: (storageConditionFrom: string) => {
                            if (!isNaN(Number(storageConditionTo)) && !isNaN(Number(storageConditionFrom))) {
                                return Number(storageConditionFrom) < Number(storageConditionTo);
                            }
                            return true;
                        },
                    });
                }),
            rangeOperatorFrom: string().required(),
            storageConditionTo: string()
                .max(10)
                .when("rangeOperatorTo", {
                    is: (rangeOperatorTo: RangeOperatorTo) => {
                        if (rangeOperatorTo === RangeOperatorTo.INFINITY) {
                            return false;
                        }
                        return true;
                    },
                    then: (schema) => schema.required().trim().test(helperObjects.isNumber),
                    otherwise: (schema) => schema.notRequired(),
                }),
            rangeOperatorTo: string().required(),
            SKUs: array().notRequired().of(string().max(18).matches(helperObjects.regexForInteger, "Invalid value")),
            storageTypeId: string().required(),
            presentationId: string().notRequired(),
            categoryId: string().notRequired(),
            expiryPeriodInHours: string().notRequired().trim().max(10).test(helperObjects.isPositiveNumberIfNotEmpty),
            productSpec: string().notRequired().max(500),
            freezeCycleLimit: string()
                .notRequired()
                .matches(helperObjects.regexForInteger, "Must be a positive integer")
                .test({
                    name: "IntegerInRange",
                    message: "Must be between 0 and 100 (inclusive)",
                    test: (value, ctx) => (!value ? true : _.inRange(Number(value), 0, 101)),
                }),
            heatCycleLimit: string()
                .notRequired()
                .matches(helperObjects.regexForInteger, "Must be a positive integer")
                .test({
                    name: "IntegerInRange",
                    message: "Must be between 0 and 100 (inclusive)",
                    test: (value, ctx) => (!value ? true : _.inRange(Number(value), 0, 101)),
                }),
            representations: array()
                .min(1, "You should specify at least one dose form and dosage")
                .of(
                    object({
                        doseFormId: string().required(),
                        dosage: string()
                            .max(10)
                            .when("allDosagesFlag", {
                                is: (allDosagesFlag: string) => allDosagesFlag === "true",
                                then: (schema) => schema.notRequired(),
                                otherwise: (schema) =>
                                    schema.required().trim().test(helperObjects.isPositiveNumberIfNotEmpty),
                            }),
                        unitOfMeasureId: string().when("allDosagesFlag", {
                            is: (allDosagesFlag: string) => allDosagesFlag === "true",
                            then: (schema) => schema.notRequired(),
                            otherwise: (schema) => schema.required(),
                        }),
                        allDosagesFlag: string().required(),
                    })
                )
                .test({
                    name: "noRepresentationDuplicates",
                    message: "Duplicate Dose Form/Dosage combinations",
                    test: (value, ctx) => {
                        let index = 0;

                        const isValid = value?.every((x) => {
                            const duplicates = value
                                ?.filter(
                                    (y) =>
                                        y.doseFormId === x.doseFormId &&
                                        (y.allDosagesFlag === "true" ||
                                            x.allDosagesFlag === "true" ||
                                            (y.dosage === x.dosage && y.unitOfMeasureId === x.unitOfMeasureId))
                                )
                                .map((y) => value?.indexOf(y));

                            if (duplicates.length > 1) {
                                index = duplicates[0];
                                return false;
                            }

                            return true;
                        });

                        if (!isValid) {
                            return ctx.createError({
                                path: `${ctx.path}[${index}].doseFormId`,
                            });
                        }

                        return true;
                    },
                }),
        }),
        temperatureRanges: array()
            .min(1, "You should specify at least one temperature range")
            .of(
                object({
                    lowerLimit: string()
                        .max(10)
                        .when("rangeOperatorFrom", {
                            is: (rangeOperatorFrom: RangeOperatorFrom) => {
                                if (rangeOperatorFrom === RangeOperatorFrom.INFINITY) {
                                    return false;
                                }
                                return true;
                            },
                            then: (schema) => schema.required().trim().test(helperObjects.isNumber),
                            otherwise: (schema) => schema.notRequired(),
                        })
                        .when("upperLimit", (upperLimit: string, schema: any) => {
                            return schema.test({
                                name: "lowNotGreaterThanOrEqualToHigh",
                                message: `Must be less than ${Number(upperLimit)}`,
                                test: (lowerLimit: string) => {
                                    if (!isNaN(Number(upperLimit)) && !isNaN(Number(lowerLimit))) {
                                        return Number(lowerLimit) < Number(upperLimit);
                                    }
                                    return true;
                                },
                            });
                        }),
                    rangeOperatorFrom: string().required(),
                    upperLimit: string()
                        .max(10)
                        .when("rangeOperatorTo", {
                            is: (rangeOperatorTo: RangeOperatorTo) => {
                                if (rangeOperatorTo === RangeOperatorTo.INFINITY) {
                                    return false;
                                }
                                return true;
                            },
                            then: (schema) => schema.required().trim().test(helperObjects.isNumber),
                            otherwise: (schema) => schema.notRequired(),
                        }),
                    rangeOperatorTo: string().required(),
                    references: string().notRequired().max(500),
                    totalStabilityBudget: number()
                        .typeError("Invalid value")
                        .notRequired()
                        .test(helperObjects.lessThanOrEqualToForDurationInput),
                    remainingStabilityBudget: number()
                        .typeError("Invalid value")
                        .test(helperObjects.lessThanOrEqualToForDurationInput)
                        .when("rangeRegions", {
                            is: (rangeRegions: TemperatureRangeRegionModel[]) => {
                                if (!!rangeRegions && rangeRegions.length <= 0) {
                                    return true;
                                }
                                return false;
                            },
                            then: (schema) => schema.required(),
                            otherwise: (schema) => schema.notRequired(),
                        }),
                    medicalInfo: number()
                        .typeError("Invalid value")
                        .notRequired()
                        .test(helperObjects.lessThanOrEqualToForDurationInput),
                    rangeRegions: array()
                        .of(
                            object({
                                id: string().notRequired(),
                                regionId: string().required(),
                                medicalInfo: number()
                                    .typeError("Invalid value")
                                    .notRequired()
                                    .test(helperObjects.lessThanOrEqualToForDurationInput),
                                flows: array().of(
                                    object({
                                        id: string().notRequired(),
                                        name: string().notRequired().max(100),
                                        references: string().notRequired().max(500),
                                        steps: string().notRequired().max(250),
                                        plannedDeduction: number()
                                            .typeError("Invalid value")
                                            .notRequired()
                                            .test(helperObjects.lessThanOrEqualToForDurationInput),
                                        remainingStabilityBudget: number()
                                            .typeError("Invalid value")
                                            .required()
                                            .test(helperObjects.lessThanOrEqualToForDurationInput),
                                    })
                                ),
                            })
                        )
                        .test({
                            name: "regionsHaveNoDuplicateId",
                            message: "Duplicate regions per one range",
                            test: (value, ctx) => {
                                const uniqueRegionIds: { regionId: string | undefined }[] = [];
                                let isValid = true;
                                let index = 0;
                                value?.forEach((x) => {
                                    const uniqueIndex = uniqueRegionIds.findIndex((y) => x.regionId === y.regionId);
                                    if (uniqueIndex !== -1) {
                                        index = value?.findIndex((y) => x.regionId === y.regionId);
                                        isValid = false;
                                        return;
                                    }
                                    uniqueRegionIds.push({ regionId: x.regionId });
                                });
                                if (!isValid) {
                                    return ctx.createError({
                                        path: `${ctx.path}[${index}].regionId`,
                                        message: "Duplicate regions per one range",
                                    });
                                }
                                return true;
                            },
                        })
                        .test({
                            name: "eitherAllRegionsOrOnlySpecificRegions",
                            message:
                                "Both all countries and regions and any specific regions cannot be defined per one range",
                            test: (value, ctx) => {
                                if (value && value.length > 0) {
                                    const allRegionsIndex = value.findIndex(
                                        (x) => x.regionId === allCountriesAndRegions
                                    );
                                    if (allRegionsIndex !== -1 && value.length !== 1) {
                                        return ctx.createError({
                                            path: `${ctx.path}[${allRegionsIndex}].regionId`,
                                            message:
                                                "Both all countries and regions and any specific regions cannot be defined per one range",
                                        });
                                    }
                                }
                                return true;
                            },
                        }),
                })
            ),
    });
