import { useNavigate } from "react-router-dom";
import { useBlocker } from "./useBlocker";

export function usePrompt(when: boolean, message: string) {
    const navigate = useNavigate();

    useBlocker((tx) => {
        if (when) {
            if (window.confirm(message)) {
                tx.retry();
            }
        }
    }, when);
}
