import { createStyles, makeStyles } from "@material-ui/core";

export const LynxScrollToTopButtonStyles = makeStyles((theme) =>
    createStyles({
        buttonWithIcon: {
            zIndex: 1,
            position: "fixed",
            bottom: "0.3125rem",
            right: "0.3125rem",
            border: `1px solid ${theme.colors.primary.blue100}`,
            borderRadius: theme.borderRadius,
            background: theme.colors.neutrals.white,
        },
    })
);
