import { productDecisionsText } from "lynxConstants";
import { AssessmentDecision } from "models/thorEvents/eventModels";

export function getAssessmentDecisionText(
    recommendation: AssessmentDecision | null,
    isPersonDecision: boolean = false
): string {
    if (isPersonDecision && recommendation === AssessmentDecision.AdditionalReviewRequired) {
        throw new Error(`Final assessment decision should not be ${productDecisionsText.additionalReviewRequired}`);
    }

    switch (recommendation) {
        case AssessmentDecision.NoImpactOnProductQuality:
            return productDecisionsText.productCanBeUsed;
        case AssessmentDecision.ExistingImpactOnProductQuality:
            return productDecisionsText.productCannotBeUsed;
        case AssessmentDecision.AdditionalReviewRequired:
            return productDecisionsText.additionalReviewRequired;
        default:
            return isPersonDecision ? productDecisionsText.noDecision : productDecisionsText.noAssessment;
    }
}
