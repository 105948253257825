import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { ReactNode } from "react";
import { useStore } from "store/StoreConfigs";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { AssessmentProductInformationProps } from "./AssessmentProductInformationProps";

export const AssessmentProductInformation = observer(({ assessment }: AssessmentProductInformationProps) => {
    const { identityStore, permissionsStore, thorEventViewStore } = useStore();
    const classes = assessmentDetailsStyles();

    const handleEmptyState = (value: string | number | undefined | null) => value || commonConstants.emptyValue;

    const viewFormDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        identityStore.currentCustomer.id
    );

    const openStabilityForm = (stabilityFormId: string) => {
        window.open(`/stabilityForms/${stabilityFormId}`, "_blank");
    };

    const linkToStabilityForm = (
        <LynxButton
            variant="tertiary"
            disabled={!permissionsStore.hasPermission(viewFormDetailsPermissionKey) || !assessment.stabilityFormId}
            loading={permissionsStore.permissionLoading(viewFormDetailsPermissionKey)}
            onClick={() => openStabilityForm(assessment.stabilityFormId || "")}
            leftIcon={<LynxIcon name="arrowUpRight" />}
        >
            Go to Product Stability Form
        </LynxButton>
    );

    const tableColumnHeader = (name: ReactNode) => {
        return (
            <TableCell className={classes.productInfoHeader}>
                <LynxTypography color="neutral400" variant="body-s">
                    {name}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <AssessmentDetailsSection title="Product Information" titleRightSide={linkToStabilityForm}>
            <Table>
                <TableHead>
                    <TableRow className={classes.verticalAlignBottom}>
                        {tableColumnHeader("Product Name / ID (GMID)")}
                        {tableColumnHeader("Dosage")}
                        {tableColumnHeader("Category")}
                        {tableColumnHeader("Type")}
                        {tableColumnHeader("Storage Cond.")}
                        {tableColumnHeader("Exp. Date")}
                        {tableColumnHeader("Exp. Period")}
                        {tableColumnHeader("Dose Form")}
                        {tableColumnHeader("Presentation")}
                        {tableColumnHeader("Freeze Cycles")}
                        {/* {tableColumnHeader("Heat")} */}
                        {tableColumnHeader("Product Spec")}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={clsx(classes.productInfoBody, classes.verticalAlignTop)}>
                        <TableCell className={classes.productName}>
                            <LynxTypography variant="body-medium">
                                {handleEmptyState(assessment.productName)}
                            </LynxTypography>
                            <LynxTypography variant="body-s" color="neutral400">
                                {handleEmptyState(assessment.productDisplayId)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {!assessment.productDosage && !assessment.productDosageUomName
                                    ? commonConstants.emptyValue
                                    : `${assessment.productDosage ?? ""} ${assessment.productDosageUomName ?? ""}`}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {handleEmptyState(assessment.productCategoryName)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {handleEmptyState(assessment.productStorageTypeName)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {formatTemperatureRange(
                                    assessment.productStorageConditionsFrom,
                                    assessment.productStorageConditionsTo,
                                    assessment.productStorageConditionsFromOperator,
                                    assessment.productStorageConditionsToOperator
                                )}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {assessment.batchExpirationDate
                                    ? convertDateToSelectedTimezone(
                                          assessment.batchExpirationDate,
                                          thorEventViewStore.eventDetails.timezone
                                      ).format(commonConstants.shortDateFormat)
                                    : commonConstants.emptyValue}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {assessment.productExpiryPeriodInHours ?? commonConstants.emptyValue}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {handleEmptyState(assessment.productDoseFormName)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {handleEmptyState(assessment.productPresentationName)}
                            </LynxTypography>
                        </TableCell>
                        <TableCell>
                            <LynxTypography paragraph>
                                {assessment.productFreezeCycleLimit !== null
                                    ? `${assessment.freezeCyclesCount === null ? "-" : assessment.freezeCyclesCount}/${
                                          assessment.productFreezeCycleLimit
                                      }`
                                    : commonConstants.emptyValue}
                            </LynxTypography>
                        </TableCell>
                        {/* <TableCell>
                            <LynxTypography paragraph>
                                {product.heatCycleLimit !== undefined && product.heatCycleLimit !== null
                                    ? `${
                                          assessment.heatCycleCount === null || assessment.heatCycleCount === undefined
                                              ? "- "
                                              : assessment.heatCycleCount
                                      }/${product.heatCycleLimit}`
                                    : EmptyValue}
                            </LynxTypography>
                        </TableCell> */}
                        <TableCell>
                            <LynxTypography paragraph>{handleEmptyState(assessment.productSpec)}</LynxTypography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </AssessmentDetailsSection>
    );
});
