import { FormControl, Grid, RadioGroup } from "@material-ui/core";
import { CompleteAssessmentReviewRequest } from "api/models/events/eventsApi";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRadio } from "components/LynxComponents/LynxRadio/LynxRadio";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { pluralize } from "helpers/pluralize";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { promptTooltipMessages } from "lynxConstants";
import { eventReviewStyles } from "./EventReviewStyles";

export interface EventReviewProps {
    assessmentsCount: number;
}

export default observer(function EventReview({ assessmentsCount }: EventReviewProps) {
    const { thorEventViewStore, permissionsStore, identityStore } = useStore();
    const event = thorEventViewStore.eventDetails;
    const classes = eventReviewStyles();
    const [isProductsWithCustomer, setisProductsWithCustomer] = useState("");

    const eventReviewPermissionKey = getPermissionKey(actions.customer.tor.events.review, event.id);

    const eventReviewPermission = permissionsStore.getPermissionStatus(eventReviewPermissionKey);

    const completeReview = () => {
        const request: CompleteAssessmentReviewRequest = {
            eventId: event.id,
            customerId: identityStore.currentCustomer.id,
            isWithCustomer: isProductsWithCustomer === "yes",
        };

        thorEventViewStore.completeEventReview(request, event.assessments);
    };

    const isUserAssignedToEvent = event.reviewerUserId === identityStore.currentUser.id;
    const isPermissionAllowed = eventReviewPermission === MarvelPermissionStatus.Allow;
    const isLoading = thorEventViewStore.progressFlags.settingCompleteReview;
    const allProductsHaveStabilityForm = event.assessments.every((x) => x.productId !== null);

    const isButtonDisabled =
        !isPermissionAllowed || !isProductsWithCustomer || isLoading || !allProductsHaveStabilityForm;
    const isRadioButtonDisabled =
        !isPermissionAllowed || !isUserAssignedToEvent || isLoading || !allProductsHaveStabilityForm;

    const getTooltipMessage = (isForButton: boolean) => {
        switch (true) {
            case !isUserAssignedToEvent:
                return promptTooltipMessages.event.assignToYourself;
            case !isPermissionAllowed:
                return promptTooltipMessages.accessDenied;
            case isForButton && !isProductsWithCustomer:
                return promptTooltipMessages.event.productWithCustomer;
            default:
                return promptTooltipMessages.empty;
        }
    };

    return (
        <div className={classes.container}>
            <LynxTypography variant="h3" color="neutral400" className={classes.sectionTitle}>
                Event Review
            </LynxTypography>

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <LynxTypography color="neutral500">
                        Are all of the products in this event with the customer? This response will apply to
                        {assessmentsCount > 1 && ` all ${assessmentsCount}`}
                        {pluralize(" assessment", assessmentsCount)} in this event.
                    </LynxTypography>

                    <PromptTooltip title={getTooltipMessage(false)} placement="bottom">
                        <FormControl component="fieldset" disabled={isRadioButtonDisabled}>
                            <RadioGroup
                                className={classes.radioGroup}
                                onChange={(e) => {
                                    setisProductsWithCustomer(e.target.value as string);
                                }}
                                value={isProductsWithCustomer}
                            >
                                <LynxRadio value={"yes"} label="Yes" />
                                <LynxRadio value={"no"} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </PromptTooltip>
                </Grid>
                <Grid item>
                    <PromptTooltip placement="top" title={getTooltipMessage(true)}>
                        <LynxButton
                            disableRipple={false}
                            onClick={completeReview}
                            size="large"
                            loading={isLoading}
                            disabled={isButtonDisabled}
                        >
                            Complete Review
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
        </div>
    );
});
