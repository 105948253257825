import { Switch } from "@material-ui/core";
import clsx from "clsx";
import { LynxSwitchProps } from "./LynxSwitchProps";
import { lynxSwitchStyles } from "./LynxSwitchStyles";

export const LynxSwitch = (props: LynxSwitchProps) => {
    const { className, ...otherProps } = props;
    const classes = lynxSwitchStyles();

    return <Switch className={clsx(classes.root, className)} {...otherProps} />;
};
