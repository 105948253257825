import { TextareaAutosize } from "@material-ui/core";
import clsx from "clsx";
import { commonConstants } from "lynxConstants";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxTextareaProps } from "./LynxTextareaProps";
import { lynxTextareaStyles } from "./LynxTextareaStyles";

export default function LynxTextarea(props: LynxTextareaProps) {
    const classes = lynxTextareaStyles();

    return (
        <>
            {props.label && (
                <LynxTypography variant="body-s" color="neutral400" className={classes.label}>
                    {props.label}
                </LynxTypography>
            )}
            <TextareaAutosize
                value={props.value || commonConstants.emptyValue}
                readOnly={props.readOnly}
                className={clsx(classes.textArea, props.className)}
            ></TextareaAutosize>
        </>
    );
}
