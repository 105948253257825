import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxCheckBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: "0.125rem",

            "&:hover": {
                "& .MuiTypography-root": {
                    color: theme.colors.primary.blue500,
                },

                "& .MuiCheckbox-root": {
                    backgroundColor: theme.colors.neutrals.neutral200,
                },

                "& .Mui-checked, &.MuiCheckbox-indeterminate": {
                    color: theme.colors.neutrals.white,
                    backgroundColor: theme.colors.primary.blue600,
                    borderColor: "transparent",
                },
            },

            "&.Mui-disabled, &.Mui-disabled:hover": {
                color: theme.colors.neutrals.neutral200,
                cursor: "not-allowed",
                "& .MuiIconButton-root": {
                    pointerEvents: "all",
                    backgroundColor: theme.colors.neutrals.neutral300,
                },

                "& .MuiTypography-root": {
                    color: theme.colors.neutrals.neutral300,
                },
            },
        },

        label: {
            marginLeft: "0.5rem",
        },

        defaultIcon: {
            width: "1.5rem",
            height: "1.5rem",
            border: `1px ${theme.colors.neutrals.neutral300} solid`,
            borderRadius: theme.borderRadius,
        },

        checkboxStyle: {
            padding: "0rem",
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: theme.borderRadius,

            "&.Mui-focusVisible": {
                outline: theme.outline,
            },
            "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                color: theme.colors.neutrals.white,
                backgroundColor: theme.colors.primary.blue500,
                borderColor: "transparent",
                "&:hover": {
                    backgroundColor: theme.colors.primary.blue600,
                },
            },
            "&.Mui-disabled, &.Mui-disabled:hover": {
                cursor: "not-allowed",
                pointerEvents: "all",
                backgroundColor: theme.colors.neutrals.neutral300,
                color: theme.colors.neutrals.neutral200,
            },
            "&.Mui-checked.Mui-disabled, &.Mui-checked.Mui-disabled:hover, &.MuiCheckbox-indeterminate.Mui-disabled, &.MuiCheckbox-indeterminate.Mui-disabled:hover":
                {
                    "&.MuiIconButton-root": {
                        backgroundColor: theme.colors.neutrals.neutral300,
                    },
                    "& svg": {
                        fill: theme.colors.neutrals.black,
                    },
                },
        },

        icon: {
            display: "flex",

            "& > svg": {
                width: "1rem",
                fill: theme.colors.neutrals.white,
            },
        },
    })
);
