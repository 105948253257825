import { RootEntityType } from "api/models/audit/auditApi";
import { AuditSection } from "components/ReusableComponents/AuditSection/AuditSection";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";

const GroupAuditPage = observer(() => {
    const { groupId = "" } = useParams();
    const navigate = useNavigate();
    const { identityStore, permissionsStore } = useStore();

    const permission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.view)
        : permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id);

    const permissionLoading = permission.status === undefined || permission.status === MarvelPermissionStatus.Loading;
    const accessDenied = permission.status === MarvelPermissionStatus.Deny;
    const conditionsToLoadUserMet = !!groupId && !accessDenied && !permissionLoading;

    useEffect(() => {
        if (!groupId) {
            return navigate(routes.pageNotFound);
        }

        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [permission.status, groupId]);

    return (
        <AuditSection
            entityId={groupId}
            entityType={RootEntityType.UserGroup}
            backButtonTitle="User Groups"
            backRedirectRoute="/userManagement/groups"
            fetchDataIsAllowed={conditionsToLoadUserMet}
            loading={!conditionsToLoadUserMet}
        />
    );
});

export default GroupAuditPage;
