import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const assessmentStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            padding: "1.5rem",
            borderRadius: "0rem",
            marginBottom: "1rem",
        },

        assessmentRow: {
            "& > td": {
                padding: "1rem 0.5rem",
            },

            verticalAlign: "top",
        },

        assessmentHeaderRow: {
            "& > th": {
                padding: "1rem 0.5rem",
            },

            verticalAlign: "bottom",
        },

        selectedRowBody: {
            backgroundColor: theme.colors.primary.blue75,
        },
        assessmentRowStatus: {
            whiteSpace: "nowrap",
            padding: "0.5rem 1rem",
            display: "inline-block",
            borderRadius: theme.borderRadius,
        },
        pendingReview: {
            color: theme.colors.warning.warning600,
            backgroundColor: theme.colors.warning.warning100,
        },
        pendingDecision: {
            color: theme.colors.dataVisualization.plum,
            backgroundColor: theme.colors.dataVisualization.plum100,
        },
        evaluationCompleted: {
            color: theme.colors.success.success600,
            backgroundColor: theme.colors.success.success100,
        },
        detailsSectionCollapse: {
            padding: "2.5rem 0rem",
        },
        hideRow: {
            display: "none",
        },
        appearRow: {
            display: "table-row",
        },
    })
);
