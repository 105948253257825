import { Divider, Paper } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { termsAndConditions } from "lynxConstants";
import { copyrightStyles } from "./CopyrightStyles";

export default function TermsAndConditions() {
    const classes = copyrightStyles();

    return (
        <Paper elevation={0} className={classes.paper}>
            <LynxTypography variant="h1">Lynx FacTOR Terms and Conditions</LynxTypography>
            <Divider className={classes.divider} />
            <LynxTypography>{termsAndConditions}</LynxTypography>
        </Paper>
    );
}
