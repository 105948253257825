import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import { InnerEventDescription } from "../EventDescription/InnerEventDescription";
import { InnerEventDescriptionProps } from "../EventDescription/InnerEventDescriptionProps";

export interface CreateEventSummaryModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    eventSummaryData: InnerEventDescriptionProps;
    isEventEditing?: boolean;
}

export const CreateEventSummaryModal = (props: CreateEventSummaryModalProps) => {
    const primaryButtonTitle = props.isEventEditing ? "Save Changes" : "Create Event";

    return (
        <LynxModal
            primaryButtonTitle={primaryButtonTitle}
            backButtonTitle="Back To Editing"
            header="Event Summary"
            {...props}
        >
            <InnerEventDescription {...props.eventSummaryData} />
        </LynxModal>
    );
};
