import { useFormikContext } from "formik";
import { usePrompt } from "hooks/usePrompt";

const PreventLeavePageUnsavedData = () => {
    const formik = useFormikContext();
    const message = "You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.";

    usePrompt(formik.dirty && !formik.isSubmitting, message);

    return null;
};

export default PreventLeavePageUnsavedData;
