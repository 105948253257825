import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { ReactNode } from "react";
import LynxTypography from "./LynxTypography/LynxTypography";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            gap: "0.25rem",
            padding: "0.5rem 1rem",
            backgroundColor: theme.colors.primary.blue75,
            borderRadius: theme.borderRadius,
        },

        icon: {
            fill: theme.colors.neutrals.neutral400,
            flexShrink: 0,
        },

        fitContent: {
            width: "fit-content",
        },
    })
);

export interface LynxInfoBadgeProps {
    iconType?: "square" | "round";
    text: ReactNode;
    fitContent?: boolean;
}

export const LynxInfoBadge = ({ iconType = "square", text, fitContent = false }: LynxInfoBadgeProps) => {
    const classes = styles();

    return (
        <div className={clsx(classes.root, fitContent && classes.fitContent)}>
            <LynxIcon className={classes.icon} name={iconType === "square" ? "infoS" : "info"} />
            <LynxTypography variant="body-s" color="neutral400">
                {text}
            </LynxTypography>
        </div>
    );
};
