import { Backdrop, Divider, Fade, Grid, Modal, Paper } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { LynxModalProps } from "./LynxModalProps";
import { lynxModalStyles } from "./LynxModalStyles";

export const LynxModal = observer((props: LynxModalProps) => {
    const classes = lynxModalStyles();

    const { sidebar = false, size = "small", ...rest } = props;

    const getModalSize = (size: string) => {
        if (sidebar) return 400;

        switch (size) {
            case "large":
                return 992;
            case "medium":
                return 768;
            case "xsmall":
                return 480;
            case "small":
            default:
                return 576;
        }
    };

    return (
        <Modal
            className={sidebar ? classes.sidebarModal : classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            {...rest}
        >
            <Fade in={props.open}>
                <Paper className={sidebar ? classes.sidebarPaper : classes.paper} style={{ width: getModalSize(size) }}>
                    <Grid container justifyContent="space-between" className={clsx(sidebar && classes.headerPadding)}>
                        <Grid item>
                            <LynxTypography variant="h2">{props.header}</LynxTypography>
                        </Grid>
                        <Grid item>
                            <LynxButton variant="icon" size="small" onClick={props.onClose}>
                                <LynxIcon name="cross" />
                            </LynxButton>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />

                    <LynxTypography className={classes.title} variant="body-s" color="neutral400">
                        {props.title}
                    </LynxTypography>

                    {props.children}

                    <Grid
                        container
                        justifyContent={"space-between"}
                        className={clsx(classes.buttonsContainer, sidebar ? classes.sidebarButtonsContainer : null)}
                    >
                        <Grid item>
                            {!!props.backButtonTitle && (
                                <LynxButton
                                    variant="tertiary"
                                    onClick={props.onBack || props.onClose}
                                    leftIcon={<LynxIcon name="arrowLeft" />}
                                >
                                    {props.backButtonTitle}
                                </LynxButton>
                            )}
                        </Grid>
                        <Grid item>
                            {!!props.secondaryButtonTitle && (
                                <LynxButton
                                    variant="tertiary"
                                    onClick={props.onClose}
                                    disabled={props.disabledCancel}
                                    className={classes.secondaryButton}
                                >
                                    {props.secondaryButtonTitle}
                                </LynxButton>
                            )}
                            {!!props.primaryButtonTitle && (
                                <LynxButton
                                    disabled={props.disabledSubmit}
                                    onClick={props.onConfirm}
                                    loading={props.loading}
                                >
                                    {props.primaryButtonTitle}
                                </LynxButton>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    );
});
