export interface FlowModel {
    id: string;
    key: string;
    name: string;
    references: string;
    steps: string;
    plannedDeduction: string;
    remainingStabilityBudget: string;
    isReferenced: boolean;
}
export interface ProductModel {
    displayId: string;
    name: string;
    genericName: string;
    storageConditionFrom: string;
    rangeOperatorFrom: RangeOperatorFrom;
    storageConditionTo: string;
    rangeOperatorTo: RangeOperatorTo;
    SKUs: string[];
    storageTypeId: string;
    presentationId: string;
    categoryId: string;
    expiryPeriodInHours: string;
    productSpec: string;
    freezeCycleLimit: string;
    heatCycleLimit: string;
    representations: ProductRepresentationModel[];
}
export interface ProductRepresentationModel {
    id: string;
    key: string;
    dosage: string;
    unitOfMeasureId: string;
    doseFormId: string;
    allDosagesFlag: string;
}
export interface TemperatureRangeRegionModel {
    id: string;
    key: string;
    regionId: string;
    medicalInfo: string;
    flows: FlowModel[];
}
export interface StabilityFormModel {
    number: string;
    version: string;
    effectiveDate: string;
    ownerEmail: string;
    product: ProductModel;
    temperatureRanges: TemperatureRangeModel[];
}
export interface TemperatureRange {
    lowerLimit: string;
    rangeOperatorFrom: RangeOperatorFrom;
    upperLimit: string;
    rangeOperatorTo: RangeOperatorTo;
    references: string;
    totalMinutes: string;
}

export interface TemperatureRangeModel {
    id: string;
    key: string;
    lowerLimit: string;
    rangeOperatorFrom: RangeOperatorFrom;
    upperLimit: string;
    rangeOperatorTo: RangeOperatorTo;
    references: string;
    totalStabilityBudget: string;
    remainingStabilityBudget: string;
    medicalInfo: string;
    isReferenced: boolean;
    rangeRegions: TemperatureRangeRegionModel[];
}

export interface Product {
    id: string;
    displayId: string;
    name: string;
    genericName: string;
    dose: string;
    storageConditionFrom: number | null;
    storageConditionTo: number | null;
    SKUs: string[];
    customerId: string;
    storageType: ProductStorageType;
    doseForm: ProductSupportInfoBase;
    presentation: ProductSupportInfoBase;
    category: ProductSupportInfoBase;
}
export interface ProductSupportInfo {
    categories: ProductSupportInfoBase[];
    doseForms: ProductSupportInfoBase[];
    storageTypes: ProductStorageType[];
    presentations: ProductSupportInfoBase[];
    unitOfMeasures: ProductSupportInfoBase[];
}
export interface ProductSupportInfoBase {
    id: string;
    name: string;
}
export interface ProductDefinition {
    id: string;
    displayId: string;
    name: string;
    genericName: string;
    storageConditionFrom: string;
    rangeOperatorFrom: RangeOperatorFrom;
    storageConditionTo: string;
    rangeOperatorTo: RangeOperatorTo;
    SKUs: string[];
    storageTypeId: string;
    storageType: string;
    presentationId: string;
    presentation: string;
    categoryId: string;
    category: string;
    expiryPeriodInHours: string;
    productSpec: string;
    freezeCycleLimit: string;
    heatCycleLimit: string;
    representations: ProductRepresentationDefinition[];
}
export interface ProductRepresentationDefinition {
    id: string;
    dosage: string;
    unitOfMeasureId: string;
    unitOfMeasure: string;
    doseFormId: string;
    doseForm: string;
    allDosagesFlag: string;
}
export interface ProductStorageType extends ProductSupportInfoBase {
    lowerLimit: number | null;
    upperLimit: number | null;
}

export enum IsInclusiveOption {
    NOT_INCLUDE = 0,
    INCLUDE = 1,
    NULL = 2,
}

export interface StabilityForm {
    id: string;
    number: number;
    createdBy: string;
    createdDateTime: Date;
    modifiedBy?: string;
    modifiedDateTime?: Date;
    statusId: StabilityStatus;
    customerId: string;
    product: Product;
}
export interface StabilityFormChangesHistory {
    id: string;
    content: string;
    editorEmail: string;
    editorFirstName: string;
    editorLastName: string;
    editedDateTime: Date;
}
export interface StabilityFormDefinition {
    id: string;
    number: string;
    version: string;
    effectiveDate: string;
    customerId: string;
    ownerEmail: string;
    ownerFirstName: string;
    ownerLastName: string;
    product: ProductDefinition;
    temperatureRanges: TemperatureRangeDefinition[];
}
export interface StabilityFormListItem {
    id: string;
    productName: string;
    number: string;
    effectiveDate: Date;
    ownerFirstName: string;
    ownerLastName: string;
    ownerEmail: string;
    productRepresentations: ProductRepresentationListItem[];
}
export interface ProductRepresentationListItem {
    id: string;
    doseForm: string;
    dosage?: number;
    unitOfMeasure: string;
    allDosagesFlag: boolean;
}
export interface StabilityFormStatus {
    id: number;
    name: string;
}
export interface FlowDefinition {
    id: string;
    name: string;
    references: string;
    steps: string;
    plannedDeduction: string;
    remainingStabilityBudget: string;
    isReferenced: boolean;
}
export interface Region {
    id: string;
    name: string;
}
export interface TemperatureRangeRegionDefinition {
    id: string;
    regionName: string;
    regionId: string;
    medicalInfo: string;
    flows: FlowDefinition[];
}
export interface TemperatureRangeDefinition {
    id: string;
    lowerLimit: string;
    upperLimit: string;
    rangeOperatorFrom: RangeOperatorFrom;
    rangeOperatorTo: RangeOperatorTo;
    totalStabilityBudget: string;
    remainingStabilityBudget: string;
    medicalInfo: string;
    references: string;
    isReferenced: boolean;
    rangeRegions: TemperatureRangeRegionDefinition[];
}
export enum RangeOperatorFrom {
    GREATER_THAN = "GreaterThan",
    GREATER_THAN_OR_EQUAL = "GreaterThanOrEqual",
    INFINITY = "Infinity",
}
export enum RangeOperatorTo {
    LESS_THAN = "LessThan",
    LESS_THAN_OR_EQUAL = "LessThanOrEqual",
    INFINITY = "Infinity",
}
export enum StabilityStatus {
    Draft = 0,
    PendingReview = 1,
    PendingApproval = 2,
    Approved = 3,
}
