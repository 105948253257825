import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { EventType } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useNavigate } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { eventDescriptionStyles } from "./EventDescriptionStyles";
import { InnerEventDescription } from "./InnerEventDescription";

export const EventDescription = observer(() => {
    const classes = eventDescriptionStyles();
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();
    const event = thorEventViewStore.eventDetails;
    const navigate = useNavigate();

    const scrollToAssessments = () => {
        const assessmentSection = document.getElementById("event_assessment_section");
        assessmentSection?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const key = getPermissionKey(`${actions.customer.tor.events.editManualEvent}::event`, event.id);
    const editEventPermissionStatus = permissionsStore.getPermissionStatus(key);

    const isDisabledEditEventButton = editEventPermissionStatus !== MarvelPermissionStatus.Allow;

    const getTooltipMessage = () => {
        const isCurrentUserIsReviever = event.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = event.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;
            case editEventPermissionStatus !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    return (
        <div className={classes.root}>
            <InfoSubheader text="description" icon={<LynxIcon name="info" />} />
            {event.assessments.some((x) => !x.productId) && (
                <div className={classes.missingFormsWarning}>
                    <LynxIcon name="triangleWarning" />
                    <div>
                        <LynxTypography variant="body-s">
                            <Span variant="body-sbold-s">Cannot Provide Automated Assessment</Span>
                            <ul className={classes.missingFormsWarningList}>
                                <li>
                                    We cannot provide an automated assessment due to product information missing or not
                                    matching.
                                </li>
                                <li>Please associate a batch to a product profile by clicking Edit Event.</li>
                                <li>
                                    The association of the batch to the product profile will define future temperature
                                    excursion events on the batch.
                                </li>
                            </ul>
                        </LynxTypography>
                        <PromptTooltip placement="top" title={getTooltipMessage()}>
                            <LynxButton
                                variant="tertiary"
                                leftIcon={<LynxIcon name="edit" />}
                                disabled={isDisabledEditEventButton}
                                loading={editEventPermissionStatus === MarvelPermissionStatus.Loading}
                                onClick={() => {
                                    navigate(routes.eventsEdit.to(event.id));
                                }}
                            >
                                Edit Event
                            </LynxButton>
                        </PromptTooltip>
                    </div>
                </div>
            )}
            <InnerEventDescription
                deliveryNumber={event.deliveryNumber}
                orderNumbers={event.orderNumbers}
                minTemperature={event.minTemperature}
                maxTemperature={event.maxTemperature}
                totalExcursionDuration={event.totalExcursionDuration}
                totalHighExcursionDuration={event.totalHighExcursionDuration}
                totalLowExcursionDuration={event.totalLowExcursionDuration}
                isSiteEvent={event.type === EventType.Site}
                manualExcursionDeviceIds={event.manualExcursionDeviceIds}
                handlingUnits={event.handlingUnits}
                assessments={event.assessments}
            />
            <LynxButton onClick={scrollToAssessments} variant="tertiary" leftIcon={<LynxIcon name="arrowUpRight" />}>
                Go to Assessments
            </LynxButton>
        </div>
    );
});
