import { theme } from "styling/theme";

function randomColor() {
    // Generate a random number between 0 and 255 for each of red, green, and blue.
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Return the color as an HTML hex string.
    return `rgb(${r}, ${g}, ${b})`;
}

const colorsForMonitorData = [
    theme.colors.dataVisualization.midnight,
    theme.colors.dataVisualization.violet,
    theme.colors.dataVisualization.plum,
    theme.colors.dataVisualization.sky,
    theme.colors.dataVisualization.teal,
    theme.colors.dataVisualization.gold100,
    theme.colors.dataVisualization.raspberry100,
    theme.colors.dataVisualization.orange,
    theme.colors.dataVisualization.turquoise100,
    theme.colors.dataVisualization.blue100,
    theme.colors.dataVisualization.darkBlue100,
    theme.colors.dataVisualization.lime,
    theme.colors.dataVisualization.pink100,
    "#D2691E",
    "#34C924",
    "#4E5452",
    "#E34234",
    "#FAEEDD",
    "#806B2A",
    "#122FAA",
];
export const getColorByIndex = (index: number) => {
    return index > colorsForMonitorData.length - 1 ? randomColor() : colorsForMonitorData[index];
};

export const getColorByRole = (role: string) => {
    if (role.toLocaleLowerCase().includes("carrier")) {
        return theme.colors.neutrals.black;
    }
    if (
        role.toLocaleLowerCase().includes("quality") ||
        role.toLocaleLowerCase().includes("manager") ||
        role.toLocaleLowerCase().includes("qa")
    ) {
        return theme.colors.primary.blue400;
    }
    if (role.toLocaleLowerCase().includes("assessor") || role.toLocaleLowerCase().includes("reviewer")) {
        return theme.colors.dataVisualization.midnight;
    }
    if (role.toLocaleLowerCase().includes("super") || role.toLocaleLowerCase().includes("admin")) {
        return theme.colors.warning.warning600;
    } else {
        return theme.colors.warning.warning100;
    }
};

export const getColorByModuleId = (id?: string | null) => {
    const sanitized = id?.toLowerCase();

    // TOR
    if (sanitized === "92ac9fb9-eead-4f04-a82c-e2811fadf246") {
        return theme.colors.dataVisualization.midnight;
        // Risk
    } else if (sanitized === "e284be13-6673-45c9-8e49-1bd84258bcea") {
        return theme.colors.dataVisualization.violet;
    } else {
        return theme.colors.warning.warning600;
    }
};
