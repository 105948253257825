import { Grid, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { RangeOperatorTo, RangeOperatorFrom } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { RangeFieldsAreaProps } from "./Props/RangeFieldsAreaProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

const RangeFieldsArea = (props: RangeFieldsAreaProps) => {
    const classes = stabilityFormStyles();

    const {
        operatorFrom,
        operatorTo,
        from,
        to,
        leaveSpaceForInputContent = true,
    } = {
        ...props,
    };

    const {
        content: operatorFromContent,
        className: operatorFromClassName,
        ...operatorFromTypographyRest
    } = { ...operatorFrom.assistiveText };
    const {
        content: operatorToContent,
        className: operatorToClassName,
        ...operatorToTypographyRest
    } = { ...operatorTo.assistiveText };

    const { content: fromContent, className: fromClassName, ...fromTypographyRest } = { ...from.errorText };
    const { content: toContent, className: toClassName, ...toTypographyRest } = { ...to.errorText };

    const operatorFromClasses = clsx(
        !operatorFromContent &&
            (leaveSpaceForInputContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone),
        operatorFromClassName
    );
    const operatorToClasses = clsx(
        !operatorToContent &&
            (leaveSpaceForInputContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone),
        operatorToClassName
    );
    const fromClasses = clsx(
        !fromContent && (leaveSpaceForInputContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone),
        fromClassName
    );
    const toClasses = clsx(
        !toContent && (leaveSpaceForInputContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone),
        toClassName
    );

    return (
        <>
            <Grid container item xs={12} alignItems="center">
                <Grid container item xs={5} alignItems="center">
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxSelectForm assistiveText="" error={false} {...operatorFrom.selectProps}>
                            <MenuItem key={0} value={RangeOperatorFrom.GREATER_THAN}>
                                {">"}
                            </MenuItem>
                            <MenuItem key={1} value={RangeOperatorFrom.GREATER_THAN_OR_EQUAL}>
                                {">="}
                            </MenuItem>
                            <MenuItem
                                key={2}
                                value={RangeOperatorFrom.INFINITY}
                                disabled={operatorFrom.infinityDisabled}
                            >
                                {"∞"}
                            </MenuItem>
                        </LynxSelectForm>
                    </Grid>
                    <Grid item xs={6} className={classes.paddingRightSmall}>
                        <LynxInputForm multiline assistiveText="" error={false} {...from.inputProps} />
                    </Grid>
                    <Grid item xs={1} className={classes.alignCenterText}>
                        <LynxTypography variant="body-s">°C</LynxTypography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={classes.alignCenterText}>
                    <LynxTypography variant="body-s">to</LynxTypography>
                </Grid>
                <Grid item container xs={5} alignItems="center">
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxSelectForm assistiveText="" error={false} {...operatorTo.selectProps}>
                            <MenuItem key={0} value={RangeOperatorTo.LESS_THAN}>
                                {"<"}
                            </MenuItem>
                            <MenuItem key={1} value={RangeOperatorTo.LESS_THAN_OR_EQUAL}>
                                {"<="}
                            </MenuItem>
                            <MenuItem key={2} value={RangeOperatorTo.INFINITY} disabled={operatorTo.infinityDisabled}>
                                {"∞"}
                            </MenuItem>
                        </LynxSelectForm>
                    </Grid>
                    <Grid item xs={6} className={classes.paddingRightSmall}>
                        <LynxInputForm multiline assistiveText="" error={false} {...to.inputProps} />
                    </Grid>
                    <Grid item xs={1} className={classes.alignCenterText}>
                        <LynxTypography variant="body-s">°C</LynxTypography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Assistive & Error Text */}
            <Grid container item xs={12}>
                <Grid container item xs={5}>
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxTypography
                            variant="body-xs"
                            color={operatorFrom.errorText ? "critical500" : "neutral400"}
                            className={operatorFromClasses}
                            {...operatorFromTypographyRest}
                        >
                            {operatorFrom.errorText ? operatorFrom.errorText : operatorFromContent ?? "Content"}
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={7}>
                        <LynxTypography
                            variant="body-s"
                            color={fromContent ? "critical500" : "neutral600"}
                            className={fromClasses}
                            {...fromTypographyRest}
                        >
                            {fromContent ?? "Error"}
                        </LynxTypography>
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid container item xs={5}>
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxTypography
                            variant="body-xs"
                            color={operatorTo.errorText ? "critical500" : "neutral400"}
                            className={operatorToClasses}
                            {...operatorToTypographyRest}
                        >
                            {operatorTo.errorText ? operatorTo.errorText : operatorToContent ?? "Content"}
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={5}>
                        <LynxTypography
                            variant="body-s"
                            color={toContent ? "critical500" : "neutral600"}
                            className={toClasses}
                            {...toTypographyRest}
                        >
                            {toContent ?? "Error"}
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RangeFieldsArea;
