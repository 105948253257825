import { FormLabel, InputLabel, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { multipleValuesHelper } from "helpers/formHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus, UserStatus, UserType } from "models/userManagement/userManagementModels";
import React, { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { ErrorList } from "../ErrorList/ErrorList";
import { formStyles } from "./FormStyles";
import { userManagementFuncs } from "../userManagementFuncs";

export interface EditFormProps {
    successCallback: (userId: string) => void;
}

export const EditUserForm = observer((props: EditFormProps) => {
    const { userStore, identityStore, permissionsStore } = useStore();
    const classes = formStyles();

    const editPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.manage)
        : permissionsStore.getPermissionResult(actions.customer.users.manage, identityStore.currentCustomer.id);

    const accessDenied = editPermission.status !== MarvelPermissionStatus.Allow;
    const isSystemUserInCustomerSpace = !identityStore.isSystemSpace && userStore.selectedUser.isSystemUser;
    const resendInviteLinkVisible =
        !accessDenied &&
        !isSystemUserInCustomerSpace &&
        userStore.selectedUser.id !== identityStore.currentUser.id &&
        userStore.selectedUser.status === UserStatus.Pending;

    const cannotAssignToCustomerGroups =
        !identityStore.isSystemSpace &&
        identityStore.currentUser.type === UserType.SystemUser &&
        userStore.selectedUser.isSystemUser;

    useEffect(() => {
        userStore.clearErrors();

        if (editPermission.status === MarvelPermissionStatus.Allow) {
            userStore.loadGroupsForSelection();

            if (identityStore.isSystemSpace) {
                userStore.loadCustomersForSelection();
            }
        }
    }, []);

    useEffect(() => {
        userStore.populateEditModel();

        if (userStore.selectedUser.id && editPermission.status !== MarvelPermissionStatus.Allow) {
            userStore.setGroupsForSelection(userStore.selectedUser.groups);

            if (identityStore.isSystemSpace) {
                userStore.setCustomersForSelection(userStore.selectedUser.customers || []);
            }
        }
    }, [userStore.selectedUser]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        userStore.clearErrors();
        userStore.editUserModel[e.target.name as string] = e.target.value;
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        userStore.clearErrors();
        userStore.editUserModel[e.target.name as string] = e.target.value;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userStore.editUser(props.successCallback);
    };

    if (userStore.progressFlags.loadingUserDetails) {
        return <LoadingIndicator style={{ margin: "0 154px", width: "300px", height: "98vh" }} />;
    }

    return (
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formSection}>
                    <LynxTypography className={classes.title} variant="h2">
                        Edit User
                    </LynxTypography>
                    <LynxTypography variant="body-s" className={classes.subText}>
                        Change a user profile&apos;s information
                    </LynxTypography>
                    <div className={classes.flexContainer}>
                        <LynxInput
                            formControlClassName={clsx(classes.formInput, classes.firstNameInput)}
                            label="First Name"
                            size="large"
                            name="firstName"
                            value={userStore.editUserModel.firstName}
                            onChange={handleInputChange}
                            placeholder="John"
                            disabled={accessDenied || isSystemUserInCustomerSpace}
                        />
                        <LynxInput
                            formControlClassName={classes.formInput}
                            size="large"
                            name="lastName"
                            label="Last Name"
                            value={userStore.editUserModel.lastName}
                            onChange={handleInputChange}
                            placeholder="Snow"
                            disabled={accessDenied || isSystemUserInCustomerSpace}
                        />
                    </div>

                    <InputLabel id="edit-user-title-label" className={classes.formInputLabel}>
                        Title
                    </InputLabel>
                    <LynxSelect
                        className={classes.formInput}
                        labelId="edit-user-title-label"
                        value={userStore.editUserModel.title}
                        name="title"
                        onChange={handleSelectChange}
                        renderValue={(x) => (x === "" ? <i>None</i> : x) as any}
                        disabled={accessDenied || isSystemUserInCustomerSpace}
                        displayEmpty
                    >
                        <MenuItem value="">
                            <i>None</i>
                        </MenuItem>
                        {userStore.titleDropdownOptions.map((x) => (
                            <MenuItem key={x} value={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </LynxSelect>

                    {/* Using explicit FormLabel component here for css purposes instead of setting "label" props in LynxInput component */}
                    <FormLabel htmlFor="email" className={classes.label}>
                        <LynxTypography variant="body-s" color="neutral400">
                            Email Address
                        </LynxTypography>
                    </FormLabel>
                    <div className={classes.flexContainer}>
                        <LynxInput
                            name="email"
                            formControlClassName={resendInviteLinkVisible ? classes.emailInput : classes.fullWidthInput}
                            disabled={true}
                            size="large"
                            value={userStore.selectedUser.id}
                        />
                        {resendInviteLinkVisible && (
                            <LynxButton
                                variant="secondary"
                                className={classes.resendInviteLink}
                                onClick={() => userManagementFuncs.reactivateUser(userStore.selectedUser.id)}
                                loading={userStore.progressFlags.reactivatingUser}
                                disabled={userStore.progressFlags.reactivatingUser}
                            >
                                Resend Invite
                            </LynxButton>
                        )}
                    </div>
                </div>

                <hr className={classes.separator} />
                <div className={classes.formSection}>
                    <InputLabel id="edit-user-userGroups-label" className={classes.formInputLabel}>
                        Select user groups
                    </InputLabel>
                    <LynxSelect
                        className={classes.fullWidthInput}
                        multiple
                        labelId="edit-user-userGroups-label"
                        name="groups"
                        label="User Groups"
                        value={userStore.editUserModel.groups}
                        loading={userStore.progressFlags.loadingGroupsForSelection}
                        onChange={handleSelectChange}
                        renderValue={(x) => multipleValuesHelper(x as string[], userStore.groupsForSelection)}
                        displayEmpty
                        disabled={accessDenied || cannotAssignToCustomerGroups}
                    >
                        {userStore.groupsForSelection.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelect>
                </div>

                {identityStore.isSystemSpace && (
                    <>
                        <hr className={classes.separator} />

                        <div className={classes.formSection}>
                            <InputLabel id="edit-user-customers-label" className={classes.formInputLabel}>
                                Assign to customers
                            </InputLabel>
                            <LynxSelect
                                className={classes.fullWidthInput}
                                multiple
                                labelId="edit-user-customers-label"
                                name="customers"
                                label="Customers"
                                value={userStore.editUserModel.customers}
                                loading={userStore.progressFlags.loadingCustomersForSelection}
                                onChange={handleSelectChange}
                                renderValue={(x) =>
                                    multipleValuesHelper(x as string[], userStore.customersForSelection)
                                }
                                displayEmpty
                                disabled={accessDenied}
                            >
                                {userStore.customersForSelection.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </LynxSelect>
                        </div>
                    </>
                )}

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <ErrorList errors={userStore.formErrors} style={{ marginBottom: "10px", maxWidth: "500px" }} />
                    <LynxButton
                        className={classes.submitButton}
                        type="submit"
                        loading={
                            userStore.progressFlags.editUserRequest ||
                            editPermission.status === MarvelPermissionStatus.Loading
                        }
                        disabled={
                            userStore.progressFlags.editUserRequest ||
                            userStore.progressFlags.loadingCustomersForSelection ||
                            userStore.progressFlags.loadingGroupsForSelection ||
                            accessDenied ||
                            (cannotAssignToCustomerGroups && isSystemUserInCustomerSpace)
                        }
                    >
                        Submit
                    </LynxButton>
                </div>
            </form>
        </div>
    );
});
