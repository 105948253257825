import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";

export const reusableFormsStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputFullWidth: {
            width: "100%",
        },
        inputPadding: {
            padding: "7px 16px",
        },
        inputBackground: {
            backgroundColor: theme.colors.neutrals.white,

            "&.MuiInputBase-root.Mui-disabled": {
                backgroundColor: theme.colors.neutrals.neutral200,
            },
        },
        inputBorderErrorColor: {
            borderColor: `${theme.colors.critical.critical500} !important`,
        },
        assistiveTextHidden: {
            opacity: "0",
        },
        assistiveTextDisplayNone: {
            display: "none",
        },
        label: {
            marginBottom: "0.25rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral400,
            },
        },
        selectFormControlRoot: {
            width: "100%",

            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: theme.shape.borderRadius,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.colors.neutrals.neutral300,
                lineHeight: "1.1876em",

                "&:hover": {
                    borderColor: theme.colors.neutrals.neutral300,
                },

                "&.Mui-error": {
                    borderColor: theme.colors.critical.critical500,
                },

                "&.Mui-focused": {
                    borderColor: theme.colors.primary.blue400,
                },
            },
        },
        selectRoot: {
            "& > .MuiSelect-root": {
                padding: "10px 35px 10px 12px",
            },

            "& .MuiSelect-select:focus-visible, & .MuiSelect-select:focus": {
                borderRadius: "4px",
            },
        },
        selectMenu: {
            backgroundColor: "none",
            color: "red",
            padding: "30px",
        },
        multipleInputRoot: {
            display: "flex",
            flexWrap: "wrap",
        },
        multipleInputFocus: {
            width: "0",
            minWidth: "60px",
            flexGrow: 1,
        },
        multipleInputBlur: {
            width: "0",
        },
        boxChip: {
            display: "flex",
            padding: "0.2rem 0.5rem 0.2rem 0.2rem",
        },
        deleteIcon: {
            "&.MuiChip-deleteIcon": {
                width: "0.8rem",
                height: "0.8rem",
                borderRadius: "0.3rem",
                position: "absolute",
                top: "-6px",
                right: "-6px",
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.colors.primary.blue400,
            },

            "& > svg": {
                fill: theme.colors.neutrals.neutral100,
                width: "0.5rem",
                height: "0.5rem",
            },
        },
        chipRoot: {
            "&.MuiChip-root": {
                color: theme.colors.primary.blue500,
                backgroundColor: theme.colors.primary.blue75,
                borderRadius: "0.25rem",
                position: "relative",
                height: "auto",
            },
        },
        chipLabel: {
            "&.MuiChip-label": {
                padding: "0 0.5rem",
            },
        },
        hiddenValuesIcon: {
            background: theme.colors.primary.blue500,
            color: "white",
            borderRadius: "5px",
            cursor: "pointer",
            width: "25px",
            textAlign: "center",
        },

        placeholderText: {
            "& input": {
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Gibson",
                color: theme.colors.neutrals.neutral500,
            },
        },

        requiredSymbol: {
            whiteSpace: "pre",
        },

        loadingIndicatorContainerForSearch: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
        },

        durationInput: {
            border: "none",
            padding: "7px",
        },

        durationBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "4px",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            padding: "0 7px",
        },

        durationBoxBorderColorError: {
            borderColor: theme.colors.critical.critical500,
        },

        durationBoxBorderColorFocus: {
            borderColor: theme.colors.primary.blue400,
        },

        durationIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        option: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `1px ${theme.colors.primary.blue100} solid`,
            padding: "0.5rem 1rem",

            "&:last-child": {
                border: "none",
            },
            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },
        },

        listBox: {
            border: `1px ${theme.colors.primary.blue100} solid`,
            borderRadius: "4px",
        },

        crossIcon: {
            marginRight: "0.5rem",
            padding: "0.25rem",
            borderRadius: "12px",
            backgroundColor: theme.colors.neutrals.neutral200,
            width: "1.25rem",
            height: "1.25rem",
            cursor: "pointer",
            fill: theme.colors.primary.blue300,
        },

        triangleIcon: {
            fill: theme.colors.critical.critical600,
            marginRight: "0.25rem",
            width: "1.25rem",
            height: "1.25rem",
            marginTop: "0.25rem",
        },
    })
);
