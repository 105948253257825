import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { Outlet } from "react-router";
import { loginLayoutStyles } from "./LoginLayoutStyles";

export const LoginLayout = (props: any) => {
    const { component, isLargeContent } = props;
    const classes = loginLayoutStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={6} className={classes.sideImage} />
            <Grid item xs={6} className={classes.rightPartOfScreenContainer} container direction="column">
                <Grid item>
                    <img
                        src="/images/Logo.png"
                        alt="logo"
                        className={clsx(classes.logo, isLargeContent ? classes.marginTopSmall : classes.marginTopLarge)}
                    />
                </Grid>

                <Grid item className={classes.mainContentContainer}>
                    {/* TODO: refactor Terms & Conditions component, move its content to a separate page */}
                    {component ?? <Outlet />}
                </Grid>
            </Grid>
        </Grid>
    );
};
