import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router";

export default function Sso() {
    const { oktaAuth } = useOktaAuth();
    const navigate = useNavigate();

    oktaAuth.signInWithRedirect({}).catch(() => {
        navigate("/events");
    });

    return null;
}
