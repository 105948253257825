import { ButtonBase, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { LynxButtonProps } from "./LynxButtonProps";
import { LynxButtonStyles } from "./LynxButtonStyles";
import LynxTypography from "../LynxTypography/LynxTypography";
import { forwardRef } from "react";

export const LynxButton = forwardRef<HTMLButtonElement, LynxButtonProps>((props, ref) => {
    const {
        variant = "primary",
        className,
        size = "small",
        children,
        loading,
        leftIcon,
        rightIcon,
        ...buttonBaseProps
    } = props;
    const classes = LynxButtonStyles();

    const getTypographyColor = (variant: string): any => {
        switch (true) {
            case props.disabled:
                return "neutral300";
            case variant === "secondary" || variant === "tertiary":
                return "blue500";
            case variant === "icon":
                return "neutral500";
            default:
                return "white";
        }
    };

    const getIcon = (icon: React.ReactNode | undefined, isLeft: boolean) => {
        if (!icon || variant === "icon") {
            return;
        }
        return (
            <span
                className={clsx(classes.iconBase, {
                    [classes.leftIcon]: isLeft,
                    [classes.rightIcon]: !isLeft,
                })}
            >
                {loading ? <CircularProgress size="1rem" color="inherit" /> : icon}
            </span>
        );
    };
    const getSizeClassName = () => {
        if (variant === "icon") {
            switch (size) {
                case "small":
                    return classes.iconSizeSmall;
                case "medium":
                    return classes.iconSizeMedium;
                case "large":
                    return classes.iconSizeLarge;
            }
        }

        if (variant === "tertiary" && size === "small") {
            return classes.tertiarySmall;
        }

        return classes[size];
    };

    return (
        <ButtonBase
            className={clsx(classes.root, classes[variant], getSizeClassName(), className)}
            ref={ref}
            {...buttonBaseProps}
        >
            <LynxTypography variant="body-medium" className={classes.typography} color={getTypographyColor(variant)}>
                {getIcon(leftIcon, true)}
                {children}
                {getIcon(rightIcon, false)}
                {loading && !leftIcon && !rightIcon && variant !== "icon" && (
                    <CircularProgress size="1rem" color="inherit" className={classes.loadingIcon} />
                )}
            </LynxTypography>
        </ButtonBase>
    );
});
