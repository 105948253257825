import { commonConstants } from "lynxConstants";
import { isNumber } from "lodash";
import { RangeOperatorFrom, RangeOperatorTo } from "models/productAndStabilityForm/productAndStabilityFormModels";

export const formatTemperatureRange = (
    lowerLimit?: number | string | null,
    upperLimit?: number | string | null,
    lowerLimitOperator?: RangeOperatorFrom | null,
    upperLimitOperator?: RangeOperatorTo | null,
    displayExclusiveSigns?: boolean
) => {
    const lowerExists = isNumber(lowerLimit) && isFinite(lowerLimit);
    const upperExists = isNumber(upperLimit) && isFinite(upperLimit);

    let lowerLimitString = "";
    let upperLimitString = "";

    if (lowerExists) {
        if (!upperExists) {
            lowerLimitString =
                lowerLimitOperator === RangeOperatorFrom.GREATER_THAN || !lowerLimitOperator
                    ? `> ${lowerLimit}`
                    : `>= ${lowerLimit}`;
        } else {
            lowerLimitString =
                displayExclusiveSigns && lowerLimitOperator === RangeOperatorFrom.GREATER_THAN
                    ? `> ${lowerLimit}`
                    : `${lowerLimit}`;
        }
    }

    if (upperExists) {
        if (!lowerExists) {
            upperLimitString =
                upperLimitOperator === RangeOperatorTo.LESS_THAN || !upperLimitOperator
                    ? `< ${upperLimit}`
                    : `<= ${upperLimit}`;
        } else {
            upperLimitString =
                displayExclusiveSigns && upperLimitOperator === RangeOperatorTo.LESS_THAN
                    ? `< ${upperLimit}`
                    : `${upperLimit}`;
        }
    }

    switch (true) {
        case !!lowerLimitString && !!upperLimitString:
            return `${lowerLimitString}°C to ${upperLimitString}°C`;
        case !!lowerLimitString && !upperLimitString:
            return `${lowerLimitString}°C`;
        case !lowerLimitString && !!upperLimitString:
            return `${upperLimitString}°C`;
        default:
            return commonConstants.emptyValue;
    }
};

export const formatTemperature = (temperature?: number | string | null) => {
    const isExists = isNumber(temperature);

    if (isExists) {
        return `${temperature}°C`;
    }

    return commonConstants.emptyValue;
};
