import { BatchHoursReduced } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCardProps";
import { AssessmentDecision } from "models/thorEvents/eventModels";

export const areBatchHoursReduced = (decision: AssessmentDecision, isWithFirstEconomicCustomer: boolean | null) => {
    switch (true) {
        case isWithFirstEconomicCustomer || decision === AssessmentDecision.ExistingImpactOnProductQuality:
            return BatchHoursReduced.No;
        case decision === AssessmentDecision.AdditionalReviewRequired:
            return BatchHoursReduced.NoDecisionYet;
        default:
            return BatchHoursReduced.Yes;
    }
};
