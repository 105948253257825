import { useOktaAuth } from "@okta/okta-react";
import { ConditionEventRequest } from "api/models/events/eventsApi";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { LynxJustificationModal } from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "store/StoreConfigs";

export interface ConditioningNotesModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    conditioningEndDate: Date;
    conditioningStartDate: Date;
}

export const TemperatureChartAuthModal = observer(
    ({ isModalOpen, setIsModalOpen, conditioningStartDate, conditioningEndDate }: ConditioningNotesModalProps) => {
        const { oktaAuth } = useOktaAuth();
        const { thorEventViewStore, identityStore } = useStore();

        const [justification, setJustification] = useState("");
        const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [numberOfRetries, setNumberOfRetries] = useState(2);

        const handleResponse = async (statusCode: number) => {
            switch (statusCode) {
                case 204:
                    setNumberOfRetries(2);
                    setIsShowReAuthenticate(false);
                    break;
                case 401:
                    setNumberOfRetries(numberOfRetries - 1);
                    setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                    if (numberOfRetries <= 0) {
                        setNumberOfRetries(2);
                        await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
                    }
                    break;
                default:
                    setErrorMessage("Something went wrong. Please, try again");
                    break;
            }
        };

        const submitRequest = async (authModel: AuthModel) => {
            const request: ConditionEventRequest = {
                customerId: identityStore.currentCustomer.id,
                eventId: thorEventViewStore.eventDetails.id,
                conditioningStartDate: conditioningStartDate,
                conditioningEndDate: conditioningEndDate,
                conditioningNote: justification,
                authModel: authModel,
                callback: handleResponse,
            };

            await thorEventViewStore.conditionEvent(request);
        };

        return (
            <>
                <LynxJustificationModal
                    open={isModalOpen}
                    title={"Confirm your reasoning for conditioning this trip by including a short summary."}
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                    onConfirm={() => {
                        setIsModalOpen(false);
                        setIsShowReAuthenticate(true);
                    }}
                    value={justification}
                    handleValueChange={setJustification}
                />
                <AuthModal
                    isOpen={isShowReAuthenticate}
                    onCancel={() => {
                        setIsShowReAuthenticate(false);
                    }}
                    onClose={() => {
                        setIsShowReAuthenticate(false);
                    }}
                    onSubmit={(authModel: AuthModel) => {
                        submitRequest(authModel);
                    }}
                    errorMessage={errorMessage}
                    loading={thorEventViewStore.progressFlags.isConditioningInProgress}
                    disabled={thorEventViewStore.progressFlags.isConditioningInProgress}
                    onBack={() => {
                        setIsShowReAuthenticate(false);
                        setIsModalOpen(true);
                    }}
                />
            </>
        );
    }
);
