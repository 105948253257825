import { MenuItem } from "@material-ui/core";
import { BatchDetailsModel } from "api/models/events/eventsApi";
import { ProductDetailsModel, ProductRepresentationResponse } from "api/models/stabilityForms/stabilityFormsApi";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import LynxTextarea from "components/LynxComponents/LynxTextarea/LynxTextarea";
import { LynxDateTimePickerForm } from "components/ReusableForms/LynxDateTimePickerForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { commonConstants } from "lynxConstants";
import { dateToFormat } from "helpers/dateFormattingHelper";
import _ from "lodash";
import { ProductSupportInfoBase } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ChangeEvent } from "react";

const stabilityFormLabel = "Stability Form";
const productNameLabel = "Product Name";
const doseFormLabel = "Dose Form";
const dosageLabel = "Dosage";
const expirationDateLabel = "Batch Expiry Date";

export const isEditable = "IsEditable";
export const isNotEditable = "IsNotEditable";

const stabilityFormField = {
    [isEditable]: (
        options: ProductDetailsModel[],
        value: ProductDetailsModel | null,
        loadOptions: (value: string) => void,
        loading: boolean,
        handleProductChange: (value: ProductDetailsModel | null) => void,
        batchFormikName: string,
        formError?: string
    ) => (
        <LynxSelectWithSearch
            disablePortal
            forcePopupIcon
            isSearchWithAutocomplete
            disablePopupIconInteractions
            freeSolo={false}
            disableClearable={false}
            multiple={false}
            options={options}
            value={value ?? null}
            search={loadOptions}
            placeholder="Search for stability form"
            loading={loading}
            onChange={(_, value) => handleProductChange(value)}
            label={<LabelWithRequiredSymbol label={stabilityFormLabel} />}
            inputProps={{
                name: `${batchFormikName}.product.stabilityFormFullName`,
                error: !!formError,
                assistiveText: formError,
            }}
            getOptionLabel={(x) => x.stabilityFormFullName}
            getOptionSelected={(x, y) => x.id === y.id}
        />
    ),
    [isNotEditable]: (value: string | null | undefined, batchFormikName: string) => (
        <LynxTextarea
            readOnly={true}
            value={value ?? ""}
            label={stabilityFormLabel}
            name={`${batchFormikName}.product.stabilityFormFullName`}
        />
    ),
};

const productNameField = {
    [isEditable]: (batchFormikName: string, disabled: boolean) => (
        <LynxInputForm
            name={`${batchFormikName}.productName`}
            label={<LabelWithRequiredSymbol label={productNameLabel} />}
            placeholder={productNameLabel}
            disabled={disabled}
        />
    ),
    [isNotEditable]: (value: string | null | undefined, batchFormikName: string) => (
        <LynxTextarea
            readOnly={true}
            value={value ?? ""}
            label={productNameLabel}
            name={`${batchFormikName}.productName`}
        />
    ),
};

const doseFormField = {
    [isEditable]: (
        batchFormikName: string,
        disabled: boolean,
        handleDoseFormChange: (
            e: ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => void,
        batch: BatchDetailsModel
    ) => (
        <LynxSelectForm
            name={`${batchFormikName}.doseFormId`}
            label={<LabelWithRequiredSymbol label={doseFormLabel} />}
            placeholder="Choose dose form"
            disabled={disabled}
            onChange={handleDoseFormChange}
            automaticallyGenerateDefaultOption={!batch.doseFormName}
        >
            {batch.product !== null
                ? _.uniqBy(batch.product!.representations, "doseFormId").map((x) => (
                      <MenuItem value={x.doseFormId} key={x.doseFormId}>
                          {x.doseForm}
                      </MenuItem>
                  ))
                : batch.doseFormId
                ? [
                      <MenuItem value={batch.doseFormId} key={batch.doseFormId}>
                          {batch.doseFormName}
                      </MenuItem>,
                  ]
                : batch.doseFormName
                ? [
                      <MenuItem value={""} key={""}>
                          {batch.doseFormName}
                      </MenuItem>,
                  ]
                : []}
        </LynxSelectForm>
    ),
    [isNotEditable]: (value: string | null | undefined, batchFormikName: string) => (
        <LynxTextarea
            readOnly={true}
            value={value ?? ""}
            label={doseFormLabel}
            name={`${batchFormikName}.doseFormId`}
        />
    ),
};

const dosageField = {
    [isEditable]: (
        currentDoseForm: ProductRepresentationResponse | null | undefined,
        containerClassName: string,
        batch: BatchDetailsModel,
        batchFormikName: string,
        unitOfMeasures: ProductSupportInfoBase[],
        handleDosageChange: (
            e: ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => void
    ) =>
        !currentDoseForm || currentDoseForm.allDosagesFlag ? (
            <div className={containerClassName}>
                <LynxInputForm
                    name={`${batchFormikName}.dosage`}
                    placeholder="Amount"
                    disabled={batch.product === null}
                    label={<LabelWithRequiredSymbol label={dosageLabel} />}
                />

                <LynxSelectForm
                    name={`${batchFormikName}.unitOfMeasureId`}
                    placeholder="Unit"
                    label={"Unit"}
                    disabled={batch.product === null}
                    automaticallyGenerateDefaultOption={!batch.unitOfMeasureName}
                    LabelProps={{
                        style: { opacity: "0" },
                    }}
                >
                    {batch.product !== null
                        ? unitOfMeasures.map((x) => (
                              <MenuItem value={x.id} key={x.id}>
                                  {x.name}
                              </MenuItem>
                          ))
                        : batch.unitOfMeasureId
                        ? [
                              <MenuItem value={batch.unitOfMeasureId} key={batch.unitOfMeasureId}>
                                  {batch.unitOfMeasureName}
                              </MenuItem>,
                          ]
                        : batch.unitOfMeasureName
                        ? [
                              <MenuItem value={""} key={""}>
                                  {batch.unitOfMeasureName}
                              </MenuItem>,
                          ]
                        : []}
                </LynxSelectForm>
            </div>
        ) : (
            <LynxSelectForm
                name={`${batchFormikName}.unitOfMeasureId`}
                value={`${batch.dosage} ${batch.unitOfMeasureId}`.trim()}
                placeholder="Choose dosage"
                disabled={!batch.isEditable}
                label={<LabelWithRequiredSymbol label={dosageLabel} />}
                onChange={(
                    e: ChangeEvent<{
                        name?: string | undefined;
                        value: unknown;
                    }>
                ) => handleDosageChange(e)}
            >
                {batch
                    .product!.representations.filter((x) => x.doseFormId === currentDoseForm.doseFormId)
                    .map((x) => (
                        <MenuItem value={`${x.dosage} ${x.unitOfMeasureId}`} key={`${x.dosage} ${x.unitOfMeasureId}`}>
                            {`${x.dosage} ${x.unitOfMeasure}`}
                        </MenuItem>
                    ))}
            </LynxSelectForm>
        ),
    [isNotEditable]: (
        dosage: string | null | number | undefined,
        uom: string | null | undefined,
        batchFormikName: string
    ) => (
        <LynxTextarea
            readOnly={true}
            value={`${dosage ?? ""} ${uom ?? ""}`.trim()}
            label={dosageLabel}
            name={`${batchFormikName}.unitOfMeasureId`}
        />
    ),
};

const expirationDateField = {
    [isEditable]: (batchFormikName: string) => (
        <LynxDateTimePickerForm
            timezone={commonConstants.UTCTimezone}
            name={`${batchFormikName}.expirationDate`}
            label={expirationDateLabel}
            placeholder="Choose a date"
            variant="date"
            format={commonConstants.shortDateFormat}
            isDateOnly
            showToday={false}
        />
    ),
    [isNotEditable]: (value: string | Date | null | undefined, batchFormikName: string) => (
        <LynxTextarea
            readOnly={true}
            value={value ? dateToFormat(value, commonConstants.shortDateFormat, true) : undefined}
            label={expirationDateLabel}
            name={`${batchFormikName}.expirationDate`}
        />
    ),
};

const quantity = (batchFormikName: string) => (
    <LynxInputForm
        name={`${batchFormikName}.quantity`}
        label={<LabelWithRequiredSymbol label="Quantity" />}
        placeholder="Quantity"
    />
);

const batchFields = {
    stabilityFormField,
    productNameField,
    doseFormField,
    dosageField,
    expirationDateField,
    quantity,
};

export default batchFields;
