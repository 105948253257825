import { RootEntityType } from "api/models/audit/auditApi";
import { AuditSection } from "components/ReusableComponents/AuditSection/AuditSection";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";

const UserAuditPage = observer(() => {
    const { userId = "" } = useParams();
    const navigate = useNavigate();
    const { identityStore, permissionsStore } = useStore();

    const permission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.view)
        : permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id);

    const permissionLoading = permission.status === undefined || permission.status === MarvelPermissionStatus.Loading;
    const accessDenied = permission.status === MarvelPermissionStatus.Deny;
    const conditionsToLoadUserMet = !!userId && !accessDenied && !permissionLoading;

    useEffect(() => {
        if (!userId) {
            return navigate(routes.pageNotFound);
        }

        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [permission.status, userId]);

    return (
        <AuditSection
            entityId={userId}
            entityType={RootEntityType.User}
            backButtonTitle="Users"
            backRedirectRoute="/userManagement/users"
            fetchDataIsAllowed={conditionsToLoadUserMet}
            loading={!conditionsToLoadUserMet}
        />
    );
});

export default UserAuditPage;
