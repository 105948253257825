import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const errorStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionCard: {
            maxWidth: "45rem",
            textAlign: "center",
            marginRight: "auto",
            marginTop: "2rem",
            marginLeft: "auto",
            padding: "5rem 10rem",

            "& > svg": {
                width: "2.5rem",
                height: "2.5rem",
                fill: theme.colors.neutrals.neutral300,
            },
        },

        marginTop: {
            marginTop: "1rem",
        },
    })
);
