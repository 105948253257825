import LoadingIndicator from "./LoadingIndicator";
import { loadingIndicatorStyles } from "./LoadingIndicatorStyles";

export const SearchLoadingIndicator = () => {
    const classes = loadingIndicatorStyles();

    return (
        <div className={classes.searchIndicatorContainer}>
            <LoadingIndicator className={classes.searchIndicator} />
        </div>
    );
};
