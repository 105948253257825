import { Chart, ChartArea, Color, Point, Scale } from "chart.js";
import { IPluginElement, SliderState } from "chartjs-plugins/crop-temperature-range/interfaces/pluginDeclarations";
import { SliderOptions } from "../crop-temperature-range-augmentation";

export abstract class Rectangle implements IPluginElement {
    protected iAmChosenOne: boolean = false;

    protected readonly svgDotsPath: Path2D = new Path2D(
        "M0 2.30769C0 1.03317 1.03317 0 2.30769 0C3.58221 0 4.61538 1.03317 4.61538 2.30769C4.61538 3.58173 3.58221 4.61538 2.30769 4.61538C1.03317 4.61538 0 3.58173 0 2.30769ZM0 10C0 8.72596 1.03317 7.69231 2.30769 7.69231C3.58221 7.69231 4.61538 8.72596 4.61538 10C4.61538 11.274 3.58221 12.3077 2.30769 12.3077C1.03317 12.3077 0 11.274 0 10ZM4.61538 17.6923C4.61538 18.9663 3.58221 20 2.30769 20C1.03317 20 0 18.9663 0 17.6923C0 16.4183 1.03317 15.3846 2.30769 15.3846C3.58221 15.3846 4.61538 16.4183 4.61538 17.6923ZM7.69231 2.30769C7.69231 1.03317 8.72596 0 10 0C11.274 0 12.3077 1.03317 12.3077 2.30769C12.3077 3.58173 11.274 4.61538 10 4.61538C8.72596 4.61538 7.69231 3.58173 7.69231 2.30769ZM12.3077 10C12.3077 11.274 11.274 12.3077 10 12.3077C8.72596 12.3077 7.69231 11.274 7.69231 10C7.69231 8.72596 8.72596 7.69231 10 7.69231C11.274 7.69231 12.3077 8.72596 12.3077 10ZM7.69231 17.6923C7.69231 16.4183 8.72596 15.3846 10 15.3846C11.274 15.3846 12.3077 16.4183 12.3077 17.6923C12.3077 18.9663 11.274 20 10 20C8.72596 20 7.69231 18.9663 7.69231 17.6923Z"
    );

    protected readonly xAxisMinimumValue: number = 0;
    protected readonly xAxisMaximumValue: number = 0;

    protected sliderFixedBorderValue: number;
    protected sliderSlidingBorderValue: number;
    protected scaleY!: Scale;
    protected scaleX!: Scale;
    protected chartArea!: ChartArea;

    private readonly grabCircleRadius: number = 10;
    private modeDisabledColor: Color;
    private modeEnabledColor: Color;

    public abstract getX(): number;
    public abstract getY(): number;
    public abstract getWidth(): number;
    public abstract getHeight(): number;

    constructor(xAxisMinimumValue: number, xAxisMaximumValue: number, options: SliderOptions) {
        this.modeDisabledColor = options.modeDisabledColor ?? "yellow";
        this.modeEnabledColor = options.modeEnabledColor ?? "yellow";
        this.xAxisMinimumValue = xAxisMinimumValue;
        this.xAxisMaximumValue = xAxisMaximumValue;
        this.sliderFixedBorderValue = options.sliderFixedBorderValue;
        this.sliderSlidingBorderValue = options.sliderSlidingBorderValue;
    }

    public uReChosenOneSet(val: boolean): void {
        this.iAmChosenOne = val;
    }

    public uReChosenOneGet(): boolean {
        return this.iAmChosenOne;
    }

    /** @virtual */
    public getPolygon(): Array<Array<number>> {
        const x = this.getX();
        const y = this.getY();
        const width = this.getWidth();
        const height = this.getHeight();
        return [
            [x, y],
            [x + width, y],
            [x + width, y + height],
            [x, y + height],
        ];
    }

    /** @virtual */
    public updateXPosition(value: number): void {
        if (value >= this.chartArea.left && value <= this.chartArea.right) {
            const xAxisValue = this.scaleX.getValueForPixel(value);

            if (xAxisValue && xAxisValue >= this.xAxisMinimumValue && xAxisValue <= this.xAxisMaximumValue) {
                this.sliderSlidingBorderValue = xAxisValue;
            }

            return;
        }

        if (value >= this.xAxisMinimumValue && value <= this.xAxisMaximumValue) {
            const xAxisChartCoordinate = this.scaleX.getPixelForValue(value);

            if (xAxisChartCoordinate >= this.chartArea.left && xAxisChartCoordinate <= this.chartArea.right) {
                this.sliderSlidingBorderValue = value;
            }
        }
    }

    /** @virtual */
    public updateYPosition(value: number): void {}

    /** @virtual */
    public isInside(point: Point): boolean {
        const vs = this.getPolygon();
        const x = point.x;
        const y = point.y;
        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            const xi = vs[i][0];
            const xj = vs[j][0];
            const yi = vs[i][1];
            const yj = vs[j][1];
            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    public drawElement(chart: Chart): void {
        const ctx = chart.ctx;
        ctx.fillStyle = chart.isConditioningEnabled() ? this.modeEnabledColor : this.modeDisabledColor;
        const x = this.getX();
        const y = this.getY();
        const width = this.getWidth();
        const height = this.getHeight();
        ctx.fillRect(x, y, width, height);
    }

    /** @virtual */
    public drawBorderImage(chart: Chart): void {
        // TODO: move to separate component?
        if (chart.isConditioningEnabled()) {
            const initialTransform = chart.ctx.getTransform();
            const xCoord = chart.scales.x.getPixelForValue(this.sliderSlidingBorderValue);
            const trueHeight = chart.scales.y.getPixelForValue(chart.scales.y.max / 2);
            // draw rectangle
            chart.ctx.fillStyle = "#999999";
            chart.ctx.fillRect(xCoord - 1, this.getY(), 2, this.getHeight());

            // draw circle
            chart.ctx.beginPath();
            chart.ctx.arc(xCoord, trueHeight, this.grabCircleRadius, 0, 2 * Math.PI);
            chart.ctx.fillStyle = "#999999";
            chart.ctx.strokeStyle = "#999999";
            chart.ctx.stroke();
            chart.ctx.fill();

            // draw dots. Check with display scales if dots svg changed.
            chart.ctx.setTransform(
                initialTransform.a * 0.5, // 0.5 - set drawing point to half of the circle with scale factor;
                0,
                0,
                initialTransform.d * 0.5, // 0.5 - set drawing point to half of the circle with scale factor;
                initialTransform.a * xCoord - 2.7 * initialTransform.a, // calculation of x coord for current dots with scale factor
                initialTransform.d * trueHeight - 5 * initialTransform.d // calculation of y coord for current dots with scale factor
            );
            chart.ctx.fillStyle = "#181818";
            chart.ctx.stroke(this.svgDotsPath);
            chart.ctx.fill(this.svgDotsPath);
            chart.ctx.setTransform(initialTransform);
        }
    }

    /** @virtual */
    public getLeftBorder(): number {
        return this.getX();
    }

    /** @virtual */
    public getRightBorder(): number {
        return this.getX() + this.getWidth();
    }

    /** @virtual */
    public getElementCenter(): Point {
        return { x: this.getX() + this.getWidth() / 2, y: this.getRightBorder() };
    }

    /** @virtual */
    public containerChanged(chartArea: ChartArea, scaleX: Scale, scaleY: Scale): void {
        this.chartArea = chartArea;
        this.scaleX = scaleX;
        this.scaleY = scaleY;
    }

    /** @virtual */
    public getXValueByCoorinate(
        chartXMinValue: number,
        chartXMaxValue: number,
        selectedXCoord: number,
        chartXMaxCoord: number
    ): number {
        const xMinMax = chartXMaxValue - chartXMinValue;
        const result = (((selectedXCoord * 100) / chartXMaxCoord) * xMinMax) / 100;
        const res = chartXMinValue + result;
        return res;
    }

    /** @virtual */
    public getSliderX(): number {
        return this.sliderSlidingBorderValue;
    }

    /** @virtual */
    public getSliderY(): number {
        return this.sliderSlidingBorderValue;
    }

    /** @virtual */
    public getSliderState(): SliderState {
        return {
            sliderFixedBorderValue: this.sliderFixedBorderValue,
            sliderSlidingBorderValue: this.sliderSlidingBorderValue,
        };
    }

    /** @virtual */
    public restoreSliderState(sliderState: SliderState) {
        this.sliderSlidingBorderValue = sliderState.sliderSlidingBorderValue;
        this.sliderFixedBorderValue = sliderState.sliderFixedBorderValue;
    }
}
