import { useOktaAuth } from "@okta/okta-react";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { Copyright } from "components/Copyright/Copyright";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { localStorageService } from "helpers/localStorageService";
import { observer } from "mobx-react";
import { LocalStorageBooleanKey, LocalStorageStringKey } from "models/shared/LocalStorageKeyEnums";
import React, { ChangeEvent, useEffect, useState } from "react";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { loginFailedMessage } from "validation/defaultErrorMessages";
import { loginStyles } from "./LoginStyles";
import { useNavigate } from "react-router";

// TODO: refactor using Formik or something similar
// TODO: add validation and error displaying
export const Login = observer(() => {
    const classes = loginStyles();

    const { commonStore, userStore } = useStore();
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState(localStorageService.getString(LocalStorageStringKey.Email) || "");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(!!localStorageService.getString(LocalStorageStringKey.Email));
    const [loading, setLoading] = useState(false);

    const handleEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setEmail(value);
    };

    const handlePasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setPassword(value);
    };

    const handleRememberMeChange = (e: React.ChangeEvent<{}>, checked: boolean) => {
        setRememberMe(checked);
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        setLoading(true);
        commonStore.setLoginError("");

        if (rememberMe) {
            localStorageService.setString(LocalStorageStringKey.Email, email);
        } else {
            localStorageService.removeString(LocalStorageStringKey.Email);
        }

        try {
            const response = await oktaAuth.signInWithCredentials({
                username: email,
                password,
            });

            localStorageService.setBoolean(LocalStorageBooleanKey.UserSignedIn, true);

            // sessionToken is a one-use token, so make sure this is only called once
            // @ts-ignore
            await oktaAuth.signInWithRedirect({ sessionToken: response.sessionToken });
        } catch (error) {
            commonStore.setLoginError(loginFailedMessage);

            await services.Audit.auditUserSignInFailure({ email, oktaError: JSON.stringify(error) });
        } finally {
            localStorageService.removeString(LocalStorageStringKey.UserLoginError);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            navigate(routes.root);
        }
    }, [authState]);

    if (!authState) {
        return <FullScreenLoadingIndicator />;
    }

    return (
        <>
            <form className={clsx(classes.loginForm, classes.marginVerticalAutoHorizontalZero)} onSubmit={onSubmit}>
                {commonStore.userLoginError && (
                    <LynxTypography color="critical500" className={classes.errorLabel}>
                        {commonStore.userLoginError}
                    </LynxTypography>
                )}
                <LynxInput
                    size="large"
                    name="email"
                    value={email}
                    label="Email"
                    onChange={handleEmailChange}
                    formControlClassName={classes.marginBottomMedium}
                />
                <LynxInput
                    size="large"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handlePasswordChange}
                    passwordInput
                    formControlClassName={classes.secondLoginInputMargin}
                />

                <LynxRedirectLink
                    to={routes.forgotPassword}
                    className={classes.forgotPasswordLink}
                    variant="body-medium"
                >
                    Forgot your Password?
                </LynxRedirectLink>

                <LynxButton
                    size="large"
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    className={classes.signInButton}
                >
                    Sign In
                </LynxButton>
                <LynxCheckBox onChange={handleRememberMeChange} checked={rememberMe} label="Remember me" />
            </form>

            <Copyright />
        </>
    );
});
