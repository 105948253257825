import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Fragment } from "react";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";

export interface AutomatedAssessmentInfoCardProps {
    icon: JSX.Element;
    messages: string[];
    title?: string;
}

export const AutomatedAssessmentInfoCard = (props: AutomatedAssessmentInfoCardProps) => {
    const classes = assessmentDetailsStyles();

    return (
        <div className={classes.infoCard}>
            {props.icon}
            <div>
                {!!props.title && (
                    <LynxTypography variant="body-sbold-s" color="neutral600">
                        {props.title}
                    </LynxTypography>
                )}
                {props.messages.map((x, i) => [
                    <Fragment key={`${i}-break`}>{i > 0 && <br />}</Fragment>,
                    <LynxTypography key={i} variant="body-s" color="neutral600">
                        {x}
                    </LynxTypography>,
                ])}
            </div>
        </div>
    );
};
