import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { services } from "api/serviceConfig";
import { localStorageService } from "helpers/localStorageService";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import { LocalStorageStringKey } from "models/shared/LocalStorageKeyEnums";
import { StartPage } from "models/shared/StartPage";
import * as usersModels from "models/userManagement/userManagementModels";
import { UserType } from "models/userManagement/userManagementModels";
import CustomerDataStore from "./CustomerDataStore";

export default class IdentityStore {
    constructor(customerDataStore: CustomerDataStore) {
        makeAutoObservable(this);
        this.customerDataStore = customerDataStore;
    }

    customerDataStore: CustomerDataStore;

    currentUser: userManagementApi.CurrentUserDetailsResponse = {
        id: "",
        oktaId: "",
        firstName: "",
        lastName: "",
        title: null,
        type: usersModels.UserType.CustomerUser,
        status: usersModels.UserStatus.Active,
        allTenantsVisibility: false,
        customers: [],
        systemGroups: [],
        termsApproved: false,
    };

    customers: usersModels.AvailableCustomer[] = [];

    currentCustomer: usersModels.AvailableCustomer = {
        id: "",
        name: "",
        status: usersModels.CustomerStatus.Active,
    };

    loading = false;

    startPageAvailable: string = `/${StartPage.EVENTS}`;

    get isSystemUser() {
        return this.currentUser.type === UserType.SystemUser;
    }

    get isSystemSpace() {
        return this.currentCustomer.id === commonConstants.system;
    }

    loadIdentity = async () => {
        this.loading = true;

        try {
            const response = await services.Users.getCurrentUserDetails();

            if (!_.inRange(response.status, 200, 300)) return;

            const user = response.data;
            this.currentUser = user;

            if (user.status === usersModels.UserStatus.Deactivated) {
                this.loading = false;
                return;
            }

            this.customers = user.customers.filter((x) => x.status === usersModels.CustomerStatus.Active);

            if (user.type === UserType.SystemUser) {
                if (user.allTenantsVisibility) {
                    const allCustomersRequest: userManagementApi.ListCustomersRequest = {
                        pageNumber: 1,
                        pageSize: 1000,
                    };

                    const allCustomersResponse = await services.Customers.listCustomers(allCustomersRequest);
                    this.customers = allCustomersResponse.data.items.filter(
                        (x) => x.status !== usersModels.CustomerStatus.Deactivated
                    );
                }

                this.customers.unshift({
                    id: commonConstants.system,
                    name: "System administration",
                    status: usersModels.CustomerStatus.Active,
                });
            }

            if (this.customers.length === 0) {
                return;
            }

            const currentCustomerId = localStorageService.getString(LocalStorageStringKey.CurrentCustomerId);
            const cachedCustomer = this.customers.find((x) => x.id === currentCustomerId);

            const customer =
                cachedCustomer === undefined || cachedCustomer.status === usersModels.CustomerStatus.Deactivated
                    ? this.customers[0]
                    : cachedCustomer;

            this.setCurrentCustomer(customer.id);
            localStorageService.setString(LocalStorageStringKey.CurrentCustomerId, this.currentCustomer.id);
        } finally {
            this.loading = false;
        }
    };

    setCurrentCustomer = (id: string) => {
        const customer = this.customers.find((x) => x.id === id);

        if (customer === undefined) {
            throw new Error("setCurrentCustomer: bug, customer should not be undefined");
        }

        this.currentCustomer = customer;
        localStorageService.setString(LocalStorageStringKey.CurrentCustomerId, this.currentCustomer.id);
        this.customerDataStore.resetAllData();
    };

    setStartPageAvailable = (page: StartPage) => {
        this.startPageAvailable = `/${page}`;
    };
}
