import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { FormikErrors } from "formik";
import _ from "lodash";

const isValidSKUs = (value: string) => {
    return /^([0-9]+)$/.test(value);
};

const generateFormikErrorsFromApi = <T>(errors: ApiValidationError[]): FormikErrors<T> => {
    const errorsWithCamelCaseProperties = _.unzip(
        errors.map((x) => [
            _.join(
                _.split(x.propertyName, ".").map((y) => _.lowerFirst(y)),
                "."
            ),
            x.errorMessage,
        ])
    );

    return _.zipObjectDeep(errorsWithCamelCaseProperties[0], errorsWithCamelCaseProperties[1]);
};

const getFieldErrorNames = <T>(formikErrors: FormikErrors<T>) => {
    const transformObjectToDotNotation = (obj: any, prefix = "", result: string[] = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];

            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "object") {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

export const validationHelpers = {
    isValidSKUs,
    generateFormikErrorsFromApi,
    getFieldErrorNames,
};
