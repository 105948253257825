import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        divider: {
            backgroundColor: theme.colors.primary.blue100,
            marginTop: "0.2rem",
            marginBottom: "1.5rem",
        },

        paper: {
            boxShadow: theme.mainShadow,
            borderRadius: theme.borderRadius,
            padding: "1.5rem 1rem",
        },

        buttonsContainer: {
            paddingTop: "1rem",
            alignItems: "baseline",
        },

        secondaryButton: {
            margin: "0 0.5rem",
        },

        title: {
            marginBottom: "1rem",
        },

        closeButtonContainer: {
            alignSelf: "flex-start",
        },

        sidebarModal: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            height: "100%",
        },

        sidebarPaper: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "25.5rem",
            minHeight: "33rem",
            height: "100%",
            borderRadius: "0",
            paddingTop: "1.25rem",
            boxSizing: "border-box",
            flex: 1,
        },

        sidebarButtonsContainer: {
            position: "absolute",
            width: "100%",
            bottom: "1rem",
            padding: "0 1.5rem",
        },

        headerPadding: {
            padding: "0 1rem",
        },
    })
);
