import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const cardStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionCard: {
            padding: "1.5rem 1.5rem 2.5rem 1.5rem",
            marginBottom: "16px",
            borderRadius: "0",
        },
    })
);
