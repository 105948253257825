import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const auditSectionStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.resourcePagePadding,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },

        paper: {
            gap: "2.5rem",
        },

        userContainer: {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
        },

        expandedRow: {
            backgroundColor: theme.colors.primary.blue75,
        },

        buttonColumn: {
            width: "10rem",
        },

        header: {
            padding: "1rem 1.5rem 0 1.5rem",
            alignItems: "start",
            justifyContent: "space-between",

            "&.sticky": {
                padding: "1rem 0 0 0",
                alignItems: "center",
            },
        },

        noResultsText: {
            padding: "1rem 1.5rem",
        },

        tableCell: {
            padding: "1rem",

            "&:first-child": {
                paddingLeft: "1.5rem",
            },

            "&:last-child": {
                paddingRight: "1.5rem",
            },
        },

        tableHeaderCell: {
            paddingTop: "2.5rem",
            whiteSpace: "nowrap",
        },

        expandedCell: {
            padding: "1.5rem",
        },
    })
);
