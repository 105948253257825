import { InputLabel, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";

import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { multipleValuesHelper } from "helpers/formHelpers";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { ErrorList } from "../ErrorList/ErrorList";
import { formStyles } from "./FormStyles";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";

interface AddUserFormProps {
    successCallback: (userId: string) => void;
}

export default observer(function AddUserForm(props: AddUserFormProps) {
    const classes = formStyles();
    const { identityStore, userStore } = useStore();

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        userStore.clearErrors();
        userStore.addUserModel[e.target.name as string] = e.target.value;
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        userStore.clearErrors();
        userStore.addUserModel[e.target.name as string] = e.target.value;
    };

    const handleWithPasswordChange = (e: React.ChangeEvent<{}>, checked: boolean) => {
        userStore.clearErrors();
        userStore.addUserModel.withPassword = checked;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userStore.addUser(props.successCallback);
    };

    useEffect(() => {
        userStore.clearFormState();
        userStore.clearErrors();
        userStore.loadGroupsForSelection();

        if (identityStore.isSystemSpace) {
            userStore.loadCustomersForSelection();
        }
    }, []);

    return (
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formSection}>
                    <LynxTypography className={classes.title} variant="h2">
                        Create a new user
                    </LynxTypography>
                    <div className={classes.flexContainer}>
                        <LynxInput
                            size="large"
                            name="firstName"
                            value={userStore.addUserModel.firstName}
                            label="First Name"
                            onChange={handleInputChange}
                            placeholder="Enter user's first name"
                            formControlClassName={clsx(classes.formInput, classes.firstNameInput)}
                        />
                        <LynxInput
                            size="large"
                            name="lastName"
                            value={userStore.addUserModel.lastName}
                            label="Last Name"
                            onChange={handleInputChange}
                            placeholder="Enter user's last name"
                            formControlClassName={classes.formInput}
                        />
                    </div>

                    <InputLabel id="create-user-title-label" className={classes.formInputLabel}>
                        Title <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxSelect
                        className={classes.formInput}
                        labelId="create-user-title-label"
                        value={userStore.addUserModel.title}
                        name="title"
                        onChange={handleSelectChange}
                        renderValue={(x: any) => (x === "" ? <i>None</i> : x)}
                        displayEmpty
                    >
                        <MenuItem value="">
                            <i>None</i>
                        </MenuItem>
                        {userStore.titleDropdownOptions.map((x) => (
                            <MenuItem key={x} value={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </LynxSelect>

                    <LynxInput
                        size="large"
                        name="email"
                        value={userStore.addUserModel.email}
                        label="Email Address"
                        onChange={handleInputChange}
                        placeholder="Enter email"
                        formControlClassName={classes.input}
                    />

                    <LynxInput
                        size="large"
                        name="repeatEmail"
                        value={userStore.addUserModel.repeatEmail}
                        label="Please confirm Email Address"
                        placeholder="Enter email"
                        onChange={handleInputChange}
                        formControlClassName={classes.input}
                    />
                </div>

                <div className={classes.formSection}>
                    <LynxCheckBox
                        label={
                            <span>
                                Create a Custom Password <span className={classes.optionalFont}>(Optional)</span>
                            </span>
                        }
                        className={classes.checkBoxLabel}
                        checked={userStore.addUserModel.withPassword}
                        onChange={handleWithPasswordChange}
                    />
                    {userStore.addUserModel.withPassword && (
                        <LynxInput
                            autoComplete="new-password"
                            formControlClassName={classes.passwordInput}
                            size="large"
                            name="password"
                            value={userStore.addUserModel.password}
                            label="Password"
                            passwordInput
                            onChange={handleInputChange}
                        />
                    )}
                </div>

                <hr className={classes.separator} />
                <div className={classes.formSection}>
                    <InputLabel id="create-user-userGroups-label" className={classes.formInputLabel}>
                        User groups <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxSelect
                        labelId="create-user-userGroups-label"
                        value={userStore.addUserModel.groups}
                        multiple
                        name="groups"
                        onChange={handleSelectChange}
                        renderValue={(x) => multipleValuesHelper(x as string[], userStore.groupsForSelection)}
                        disabled={userStore.progressFlags.loadingGroupsForSelection}
                        loading={userStore.progressFlags.loadingGroupsForSelection}
                        displayEmpty
                    >
                        {userStore.groupsForSelection.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelect>
                </div>

                {identityStore.isSystemSpace && (
                    <>
                        <hr className={classes.separator} />

                        <div className={classes.formSection}>
                            <InputLabel id="create-user-customers-label" className={classes.formInputLabel}>
                                Assign to customers <span className={classes.optionalFont}>(Optional)</span>
                            </InputLabel>
                            <LynxSelect
                                labelId="create-user-customers-label"
                                value={userStore.addUserModel.customers}
                                multiple
                                name="customers"
                                onChange={handleSelectChange}
                                renderValue={(x) =>
                                    multipleValuesHelper(x as string[], userStore.customersForSelection)
                                }
                                disabled={userStore.progressFlags.loadingCustomersForSelection}
                                loading={userStore.progressFlags.loadingCustomersForSelection}
                                displayEmpty
                            >
                                {userStore.customersForSelection.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </LynxSelect>
                        </div>
                    </>
                )}

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <ErrorList errors={userStore.formErrors} style={{ marginBottom: "10px" }} />
                    <LynxButton
                        className={classes.submitButton}
                        disabled={
                            userStore.progressFlags.loadingCustomersForSelection ||
                            userStore.progressFlags.loadingGroupsForSelection ||
                            userStore.progressFlags.addUserRequest
                        }
                        loading={userStore.progressFlags.addUserRequest}
                        type="submit"
                    >
                        Submit
                    </LynxButton>
                </div>
            </form>
        </div>
    );
});
