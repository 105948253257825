import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxRadioStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: "0.125rem",
            marginRight: "0.5rem",

            "&:hover": {
                "& .MuiTypography-root": {
                    color: theme.colors.primary.blue500,
                },

                "& .MuiIconButton-root": {
                    backgroundColor: theme.colors.neutrals.neutral200,
                },
            },

            "&.Mui-disabled, &.Mui-disabled:hover": {
                color: theme.colors.neutrals.neutral200,
                cursor: "not-allowed",
                "& .MuiIconButton-root": {
                    pointerEvents: "all",
                    backgroundColor: theme.colors.neutrals.neutral300,
                },

                "& .MuiTypography-root": {
                    color: theme.colors.neutrals.neutral300,
                },
            },
        },

        radio: {
            margin: "0.25rem",
            padding: "0",

            "&.Mui-disabled": {
                cursor: "not-allowed",
                backgroundColor: theme.colors.neutrals.neutral300,

                "& > span::after": {
                    backgroundColor: theme.colors.neutrals.neutral100,
                },
            },

            "&.Mui-checked:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },

            "&.Mui-focusVisible": {
                outline: theme.outline,
            },

            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },
        },

        icon: {
            width: "24px",
            height: "24px",
            borderRadius: "100%",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,

            "&.Mui-disabled": {
                backgroundColor: theme.colors.neutrals.neutral300,
            },

            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },
        },

        checkedIcon: {
            border: `1px solid ${theme.colors.primary.blue500}`,

            "input:disabled ~ &": {
                borderColor: theme.colors.neutrals.neutral300,
                backgroundColor: theme.colors.neutrals.neutral300,

                "&::after": {
                    backgroundColor: theme.colors.neutrals.neutral200,
                },
            },

            "&::after": {
                content: '""',
                position: "absolute",
                width: "40%",
                height: "40%",
                borderRadius: "50%",
                display: "block",
                backgroundColor: theme.colors.primary.blue500,
                top: "30%",
                left: "31%",
            },

            "&.Mui-disabled": {
                backgroundColor: theme.colors.neutrals.neutral100,

                "&::after": {
                    backgroundColor: theme.colors.neutrals.neutral100,
                },
            },
        },
    })
);
