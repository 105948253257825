import { Grid, Tooltip } from "@material-ui/core";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { userTooltipStyles } from "./UserTooltipStyles";

export interface UserTooltipProps {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    children: React.ReactElement<any, any>;
}

export const UserTooltip = ({ firstName, lastName, email, children }: UserTooltipProps) => {
    const classes = userTooltipStyles();

    const tooltipContent = (
        <Grid container spacing={1}>
            <Grid item>
                <LynxAvatar firstName={firstName} lastName={lastName} size={2.5} fontSize={0.875} />
            </Grid>
            <Grid item>
                <LynxTypography color="neutral600">
                    {firstName} {lastName}
                </LynxTypography>
                <LynxTypography color="neutral400" variant="body-s">
                    {email}
                </LynxTypography>
            </Grid>
        </Grid>
    );

    return (
        <Tooltip classes={{ tooltip: classes.root }} title={tooltipContent} placement="top">
            {children}
        </Tooltip>
    );
};
