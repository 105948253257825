import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import { AssessmentDecision } from "models/thorEvents/eventModels";
import { BatchHoursReduced, LynxAssessmentCardProps } from "./LynxAssessmentCardProps";
import { LynxAssessmentCardStyles } from "./LynxAssessmentCardStyles";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import LynxTypography from "../LynxTypography/LynxTypography";
import { useStore } from "store/StoreConfigs";
import { LynxInfoBadge } from "../LynxInfoBadge";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { Span } from "../LynxTypography/Span";
import { productDecisionsText, commonConstants } from "lynxConstants";

export const LynxAssessmentCard = observer((props: LynxAssessmentCardProps) => {
    const {
        email,
        firstName,
        lastName,
        action,
        actionDate,
        decision,
        isWithFirstEconomicCustomer,
        batchHoursReduced,
        justification,
        infoBadgeText,
    } = props;

    const classes = LynxAssessmentCardStyles();
    const getCardTableHeaders = ["Can Product be used?", "With Customer?", "Batch Hours Reduced?"];
    const { thorEventViewStore } = useStore();

    const getDecisionText = (decision: AssessmentDecision) => {
        switch (decision) {
            case AssessmentDecision.NoImpactOnProductQuality:
                return <LynxTypography className={classes.safeToUse}>Yes</LynxTypography>;
            case AssessmentDecision.ExistingImpactOnProductQuality:
                return <LynxTypography className={classes.notSafeToUse}>No</LynxTypography>;
            case AssessmentDecision.AdditionalReviewRequired:
                return <LynxTypography>{productDecisionsText.additionalReviewRequired}</LynxTypography>;
        }
    };

    const getBatchHoursReducedText = (batchHoursReduced: BatchHoursReduced) => {
        switch (batchHoursReduced) {
            case BatchHoursReduced.Yes:
                return "Yes";
            case BatchHoursReduced.No:
                return "No";
            case BatchHoursReduced.NoDecisionYet:
                return productDecisionsText.noDecision;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.userInfoSection}>
                    <LynxAvatarWithTooltip email={email} firstName={firstName} lastName={lastName} />
                    <LynxTypography variant="body-medium">
                        {firstName}&nbsp;{lastName}&nbsp;
                        <Span color="neutral400">{action}</Span>
                    </LynxTypography>
                </div>
                <LynxTypography variant="body-s" color="neutral300">
                    {convertDateToSelectedTimezone(actionDate, thorEventViewStore.eventDetails.timezone).format(
                        commonConstants.fullDateTimeFormat
                    )}
                </LynxTypography>
            </div>

            <Table>
                <TableHead>
                    <TableRow>
                        {getCardTableHeaders.map((header) => (
                            <TableCell key={header} className={classes.tableHeaderCell}>
                                <LynxTypography color="neutral400" variant="body-s">
                                    {header}
                                </LynxTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.tableRowCell}>{getDecisionText(decision)}</TableCell>
                        <TableCell className={classes.tableRowCell}>
                            <LynxTypography>{isWithFirstEconomicCustomer ? "Yes" : "No"}</LynxTypography>
                        </TableCell>
                        <TableCell className={classes.tableRowCell}>
                            <LynxTypography>{getBatchHoursReducedText(batchHoursReduced)}</LynxTypography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {justification && (
                <div className={classes.justificationContainer}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Justification
                    </LynxTypography>
                    <LynxTypography>{justification}</LynxTypography>
                </div>
            )}

            {infoBadgeText && <LynxInfoBadge text={infoBadgeText} />}
        </div>
    );
});
