import { OktaAuthOptions } from "@okta/okta-auth-js";
import { envConstants } from "./env-constants";

const oktaAuthOptions: OktaAuthOptions = {
    clientId: envConstants.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: envConstants.REACT_APP_OKTA_REDIRECT_URL,
    issuer: envConstants.REACT_APP_OKTA_ISSUER,
    scopes: ["openid", "profile", "email", "offline_access", "lynxmarvel"],
};

export const config = {
    oktaAuthOptions,
};
