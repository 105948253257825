import LoadingIndicator from "./LoadingIndicator";
import { loadingIndicatorStyles } from "./LoadingIndicatorStyles";

export const CenteredLoadingIndicator = () => {
    const classes = loadingIndicatorStyles();

    return (
        <div className={classes.centeredIndicatorContainer}>
            <LoadingIndicator className={classes.centeredIndicator} />
        </div>
    );
};
