import { Immutable } from "helpers/typeHelpers";

const productDecisionsTextInternal = {
    productCanBeUsed: "Product CAN be used",
    productCannotBeUsed: "Product CANNOT be used",
    additionalReviewRequired: "Additional Review Required",
    noAssessment: "No Assessment",
    noDecision: "No Decision Yet",
};

export const productDecisionsText: Immutable<typeof productDecisionsTextInternal> = productDecisionsTextInternal;
