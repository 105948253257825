import { useFormikContext } from "formik";
import { useEffect } from "react";
import { validationHelpers } from "validation/helper-functions";

export const ScrollToFirstFormFieldError = <T,>() => {
    const { isSubmitting, isValid, errors } = useFormikContext<T>();

    useEffect(() => {
        if (isValid || isSubmitting) return;

        const fieldErrorNames = validationHelpers.getFieldErrorNames<T>(errors);

        if (fieldErrorNames.length <= 0) return;

        const element = document.querySelector(
            `input[name='${fieldErrorNames[0]}'], textarea[name='${fieldErrorNames[0]}'], fieldset[name='${fieldErrorNames[0]}']`
        );

        if (!element) return;

        // Scroll to first known error into view and if there are any errors in collapse area - wait until it's expanded
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [isSubmitting]);

    return null;
};
