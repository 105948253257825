import { Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { FieldArray, useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { promptTooltipMessages } from "lynxConstants";
import {
    StabilityFormModel,
    TemperatureRangeModel,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ChangeEvent } from "react";
import uuid from "react-uuid";
import { stabilityFormStyles } from "../StabilityFormStyles";
import FlowFieldsArea from "./FlowFieldsArea";
import { RegionFieldsAreaProps } from "./Props/RegionFieldsAreaProps";

export interface StabilityFlowTableHeader {
    key: number;
    label: string;
    required?: boolean;
    width: string;
    align: "inherit" | "center" | "right" | "left" | "justify";
}

const RegionFieldsArea = (props: RegionFieldsAreaProps) => {
    const formik = useFormikContext<StabilityFormModel>();
    const classes = stabilityFormStyles();
    const { rangeIndex, regionIndex, regionHelpers, regions, disableSelectedRegion, enableRegionOption } = { ...props };
    const flows = formik.values.temperatureRanges[rangeIndex].rangeRegions[regionIndex].flows;

    const flowTableHeaders: StabilityFlowTableHeader[] = [
        { key: 0, label: "Flow", required: false, width: "10%", align: "left" },
        { key: 1, label: "Stops", required: false, width: "20%", align: "left" },
        { key: 2, label: "Planned Deduction", required: false, width: "17%", align: "left" },
        { key: 3, label: "Remaining Stability Budget", required: true, width: "17%", align: "left" },
        { key: 4, label: "References", required: false, width: "34%", align: "left" },
        { key: 5, label: "", required: false, width: "2%", align: "center" },
    ];

    const handleRegionChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        disableSelectedRegion(e, formik.values.temperatureRanges[rangeIndex].rangeRegions[regionIndex].regionId);
        formik.handleChange(e);

        const rangeErrors = formik.errors.temperatureRanges?.[rangeIndex] as TemperatureRangeModel | undefined;

        !!rangeErrors?.rangeRegions?.[regionIndex]?.regionId &&
            formik.setFieldError(`temperatureRanges.${rangeIndex}.rangeRegions.${regionIndex}.regionId`, undefined);
    };

    const handleRegionDelete = () => {
        enableRegionOption(formik.values.temperatureRanges[rangeIndex].rangeRegions[regionIndex].regionId);

        regionHelpers.remove(regionIndex);
    };

    return (
        <Grid container item>
            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxSelectForm
                        name={`temperatureRanges.${rangeIndex}.rangeRegions.${regionIndex}.regionId`}
                        label={<LabelWithRequiredSymbol label="Region" />}
                        placeholder="Select Region"
                        onChange={handleRegionChange}
                    >
                        {regions.map((x) => (
                            <MenuItem value={x.region.id} key={x.region.id} disabled={x.disabled}>
                                {x.region.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>
                <Grid item xs={2}>
                    <LynxDurationInputForm
                        name={`temperatureRanges.${rangeIndex}.rangeRegions.${regionIndex}.medicalInfo`}
                        label="Medical Info"
                    />
                </Grid>
                <Grid item xs={2} className={classes.deleteRegionButtonAlignment}>
                    <PromptTooltip
                        placement="top"
                        title={
                            flows.some((x) => x.isReferenced)
                                ? promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced
                                : promptTooltipMessages.empty
                        }
                    >
                        <LynxButton
                            size="medium"
                            variant="icon"
                            disabled={flows.some((x) => x.isReferenced)}
                            onClick={() => handleRegionDelete()}
                        >
                            <LynxIcon name="trash" />
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
            <Grid item>
                <FieldArray
                    name={`temperatureRanges.${rangeIndex}.rangeRegions.${regionIndex}.flows`}
                    render={(flowHelpers) => (
                        <Grid container>
                            <Grid item>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {flowTableHeaders.map((x) => (
                                                <TableCell
                                                    key={x.key}
                                                    className={clsx(
                                                        classes.tableCellNoBorder,
                                                        classes.tableHeaderCellFlow
                                                    )}
                                                    align={x.align}
                                                    style={{ width: x.width }}
                                                >
                                                    <LynxTypography variant="body-s" color="neutral400">
                                                        {x.required ? (
                                                            <LabelWithRequiredSymbol label={x.label} />
                                                        ) : (
                                                            x.label
                                                        )}
                                                    </LynxTypography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {flows.map((flow, flowIndex) => (
                                            <FlowFieldsArea
                                                key={flow.id ? flow.id : flow.key}
                                                rangeIndex={rangeIndex}
                                                regionIndex={regionIndex}
                                                flowIndex={flowIndex}
                                                flowHelpers={flowHelpers}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item className={classes.addFlowButtonContainer}>
                                <LynxButton
                                    variant="tertiary"
                                    onClick={() =>
                                        flowHelpers.push({
                                            id: "",
                                            key: uuid(),
                                            name: "",
                                            steps: "",
                                            plannedDeduction: "",
                                            remainingStabilityBudget: "",
                                            references: "",
                                        })
                                    }
                                    leftIcon={<LynxIcon name="plusSmall" />}
                                >
                                    Add Flow
                                </LynxButton>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default RegionFieldsArea;
