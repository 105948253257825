import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const uploadAreaStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropArea: {
            margin: "1.5rem 0",
        },

        drag: {
            opacity: 0.5,
            backgroundColor: theme.colors.neutrals.neutral200,
        },

        dragged: {
            width: "100%",
            height: "24.5rem",
            borderRadius: "4px",
            padding: "15% 20%",
            flexDirection: "column",
            alignItems: "center",
            border: `2px dashed ${theme.colors.neutrals.neutral300}`,
            gap: "0.5rem",
        },

        fileInputDisplayNone: {
            display: "none",
        },

        headerContainer: {
            justifyContent: "space-between",
            height: "2.5rem",
            paddingBottom: "0.5rem",
            borderBottom: `1px ${theme.colors.primary.blue100} solid`,
            marginBottom: "2rem",
        },

        attachmentIcon: {
            fill: theme.colors.neutrals.neutral500,
            width: "2rem",
            height: "2rem",
        },

        errorMessage: {
            marginTop: "0.5rem",
        },

        modalContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
        },
    })
);
