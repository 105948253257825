import { Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { RsbAdjustmentForRangeDetails } from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { LynxIcon } from "icons/LynxIcon";
import { Observer } from "mobx-react";
import { ManualEventModel, RsbAdjustmentAction } from "models/thorEvents/eventModels";
import { ChangeEvent, useEffect } from "react";
import { eventCreationStyles } from "./EventCreationStyles";

interface RsbAdjustmentProps {
    batchIndex: number;
    rsbAdjustments: RsbAdjustmentForRangeDetails[];
    handleRsbAdjustment: () => Promise<void>;
    loadRsbAdjustments: () => Promise<RsbAdjustmentForRangeDetails[]>;
    loadingRsbSummaryFlag: boolean;
}

const HandleRsbAdjustmentChangeComponent = ({
    batchIndex,
    rsbAdjustmentIndex,
}: {
    batchIndex: number;
    rsbAdjustmentIndex: number;
}) => {
    const formik = useFormikContext<ManualEventModel>();
    const value = formik.values.batches[batchIndex].rsbAdjustments[rsbAdjustmentIndex];

    useEffect(() => {
        const rsbAdjustment =
            value.rsbAdjustmentAction === RsbAdjustmentAction.Add
                ? Number(value.rsbAdjustment)
                : -Number(value.rsbAdjustment);

        formik.setFieldValue(
            `batches.${batchIndex}.rsbAdjustments.${rsbAdjustmentIndex}.rsbAfterAdjustment`,
            rsbAdjustment + value.rsbBeforeAdjustment
        );
    }, [value.rsbAdjustment, value.rsbAdjustmentAction]);

    return null;
};

const RsbAdjustments = ({
    batchIndex,
    rsbAdjustments,
    handleRsbAdjustment,
    loadRsbAdjustments,
    loadingRsbSummaryFlag,
}: RsbAdjustmentProps) => {
    const formik = useFormikContext<ManualEventModel>();
    const batch = formik.values.batches[batchIndex];
    const classes = eventCreationStyles();

    const handleRangeChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        rsbAdjustmentIndex: number
    ) => {
        const rangeId = e.target.value as string;

        const rsbAdjustmentSelected = rsbAdjustments.find((x) => x.rangeId === rangeId)!;

        formik.setFieldValue(`batches.${batchIndex}.rsbAdjustments.${rsbAdjustmentIndex}`, {
            ...rsbAdjustmentSelected,
            rsbAdjustment: rsbAdjustmentSelected.rsbAdjustment ?? 0,
        });
    };

    const handleRangeAdd = async (helpers: FieldArrayRenderProps) => {
        if (rsbAdjustments.length > 0) {
            const addedRange = rsbAdjustments
                .filter((x) => batch.rsbAdjustments.findIndex((y) => y.rangeId === x.rangeId) === -1)
                .at(0);

            if (addedRange) {
                helpers.push({ ...addedRange, rsbAdjustment: addedRange.rsbAdjustment ?? 0 });
            }

            return;
        }

        await handleRsbAdjustment();
    };

    const renderTemperatureRanges = (rsbAdjustmentIndex: number) => {
        if (rsbAdjustments.length > 0) {
            return rsbAdjustments.map((x) => (
                <MenuItem
                    key={x.rangeId}
                    value={x.rangeId}
                    disabled={batch.rsbAdjustments.findIndex((y) => y.rangeId === x.rangeId) !== -1}
                >
                    {formatTemperatureRange(x.rangeDisplayLowerLimit, x.rangeDisplayUpperLimit)}
                </MenuItem>
            ));
        }

        const currentRsbAdjustment = batch.rsbAdjustments[rsbAdjustmentIndex];

        return [
            <MenuItem value={currentRsbAdjustment.rangeId} key={currentRsbAdjustment.rangeId}>
                {formatTemperatureRange(
                    currentRsbAdjustment.rangeDisplayLowerLimit,
                    currentRsbAdjustment.rangeDisplayUpperLimit
                )}
            </MenuItem>,
        ];
    };

    const columns = [
        {
            header: "Temperature Range",
            width: "20%",
            component: (rsbAdjustmentIndex: number) => (
                <LynxSelectForm
                    name={`batches.${batchIndex}.rsbAdjustments.${rsbAdjustmentIndex}.rangeId`}
                    onChange={(
                        e: ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                        }>
                    ) => handleRangeChange(e, rsbAdjustmentIndex)}
                    onOpen={() => loadRsbAdjustments()}
                    loading={loadingRsbSummaryFlag}
                    disabled={loadingRsbSummaryFlag}
                >
                    {renderTemperatureRanges(rsbAdjustmentIndex)}
                </LynxSelectForm>
            ),
        },
        {
            header: "Current Batch Remaining Stability Budget",
            width: "15%",
            component: (rsbAdjustmentIndex: number) => (
                <LynxTypography className={classes.marginTopRsbAdjustment}>
                    {formatDurationToString(batch.rsbAdjustments[rsbAdjustmentIndex].rsbBeforeAdjustment)}
                </LynxTypography>
            ),
        },
        {
            header: "Action",
            width: "15%",
            component: (rsbAdjustmentIndex: number) => (
                <LynxSelectForm name={`batches.${batchIndex}.rsbAdjustments.${rsbAdjustmentIndex}.rsbAdjustmentAction`}>
                    <MenuItem value={RsbAdjustmentAction.Reduce}>Reduce</MenuItem>
                    <MenuItem value={RsbAdjustmentAction.Add}>Add</MenuItem>
                </LynxSelectForm>
            ),
        },
        {
            header: "Adjustment Amount",
            width: "30%",
            component: (rsbAdjustmentIndex: number) => (
                <LynxDurationInputForm
                    name={`batches.${batchIndex}.rsbAdjustments.${rsbAdjustmentIndex}.rsbAdjustment`}
                    zeroIsDefault
                />
            ),
        },
        {
            header: "Updated Batch Remaining Stability Budget",
            width: "15%",
            component: (rsbAdjustmentIndex: number) => (
                <LynxTypography className={classes.marginTopRsbAdjustment}>
                    {formatDurationToString(batch.rsbAdjustments[rsbAdjustmentIndex].rsbAfterAdjustment)}
                </LynxTypography>
            ),
        },
    ];

    return (
        <FieldArray
            name={`batches.${batchIndex}.rsbAdjustments`}
            render={(helpers) => (
                <Observer>
                    {() => (
                        <Grid item container className={classes.marginTopMiddle}>
                            <Grid item xs={12}>
                                <LynxTypography variant="h3" color="neutral400">
                                    Remaining Stability Budget Adjustment
                                </LynxTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((x) => (
                                                <TableCell key={x.header} width={x.width}>
                                                    <LynxTypography variant="body-s" color="neutral400">
                                                        {x.header}
                                                    </LynxTypography>
                                                </TableCell>
                                            ))}
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batch.rsbAdjustments.map((rsbAdjustment, rsbAdjusmentIndex) => (
                                            <TableRow key={rsbAdjustment.rangeId} className={classes.tableContentRow}>
                                                {columns.map((y) => (
                                                    <TableCell key={y.header} className={classes.paddingBottomZero}>
                                                        {y.component(rsbAdjusmentIndex)}
                                                    </TableCell>
                                                ))}
                                                <TableCell className={classes.paddingBottomZero} width="5%">
                                                    <LynxButton
                                                        variant="icon"
                                                        size="medium"
                                                        onClick={() => helpers.remove(rsbAdjusmentIndex)}
                                                    >
                                                        <LynxIcon name="trash" />
                                                    </LynxButton>
                                                </TableCell>
                                                <HandleRsbAdjustmentChangeComponent
                                                    batchIndex={batchIndex}
                                                    rsbAdjustmentIndex={rsbAdjusmentIndex}
                                                />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item className={clsx(classes.marginTopSmall, classes.marginBottomMedium)} xs={12}>
                                <LynxButton
                                    variant="tertiary"
                                    onClick={() => handleRangeAdd(helpers)}
                                    disabled={
                                        loadingRsbSummaryFlag ||
                                        batch.rsbAdjustments.length === rsbAdjustments.length ||
                                        batch.impactsWithStabilityRangeCount === batch.rsbAdjustments.length
                                    }
                                    loading={loadingRsbSummaryFlag}
                                    leftIcon={<LynxIcon name="plusSmall" />}
                                >
                                    Add Temperature Range
                                </LynxButton>
                            </Grid>
                        </Grid>
                    )}
                </Observer>
            )}
        />
    );
};

export default RsbAdjustments;
