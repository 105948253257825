import { observer } from "mobx-react";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { AuditSection } from "components/ReusableComponents/AuditSection/AuditSection";
import { RootEntityType } from "api/models/audit/auditApi";
import { actions } from "models/userManagement/actions";
import { getPermissionKey } from "helpers/permissionHelpers";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useNavigate, useParams } from "react-router-dom";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { useEffect } from "react";

export const CustomerAuditPage = observer(() => {
    const { customerId = "" } = useParams();

    const navigate = useNavigate();
    const customerIdValid = isGuidValid(customerId);

    const { identityStore, customerStore, permissionsStore } = useStore();

    const permissionKey = getPermissionKey(actions.system.customers.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);
    const permissionLoading = permissionStatus === undefined || permissionStatus === MarvelPermissionStatus.Loading;
    const accessDenied = !identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny;

    const customersDataInfoLoadAllowed = customerIdValid && !accessDenied && !permissionLoading;

    useEffect(() => {
        if (!customerIdValid) {
            return navigate(routes.pageNotFound);
        }

        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [permissionStatus, customerId]);

    return (
        <AuditSection
            entityId={customerId}
            entityType={RootEntityType.Customer}
            backButtonTitle="Customers"
            backRedirectRoute="/userManagement/customers"
            fetchDataIsAllowed={customersDataInfoLoadAllowed}
            loading={!customersDataInfoLoadAllowed || customerStore.progressFlags.loadingCustomers}
        />
    );
});
