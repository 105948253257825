import { BaseResponse, ErrorResponseWithPayload } from "api/models/sharedModels/ResponseModels";
import { ValidationProblemDetailsResponse } from "api/models/sharedModels/ValidationProblemDetailsResponse";
import { AxiosResponse } from "axios";

type OmitNever<T extends Record<string, unknown>> = {
    [K in keyof T as T[K] extends never ? never : K]: T[K];
};

export type SharedProperties<A, B> = OmitNever<Pick<A & B, keyof A & keyof B>>;

export const isGenericApiError = (
    error: unknown
): error is AxiosResponse<BaseResponse> & { response: AxiosResponse<BaseResponse> } => {
    return !!(error as AxiosResponse<BaseResponse>).data?.errorMessage;
};

export const isValidationProblemDetailsError = (
    error: unknown
): error is AxiosResponse<ValidationProblemDetailsResponse> & {
    response: AxiosResponse<ValidationProblemDetailsResponse>;
} => {
    return !!(error as AxiosResponse<ValidationProblemDetailsResponse>).data?.traceId;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type JSONObject = string | number | boolean | null | { [key: string]: JSONObject } | JSONObject[];

type ImmutablePrimitive = undefined | null | boolean | string | number | Function;

export type Immutable<T> = {
    readonly [P in keyof T]: T[P] extends ImmutablePrimitive ? T[P] : Immutable<T[P]>;
};

export const isErrorResponse = (response: any): response is BaseResponse =>
    !!response && typeof response === "object" && "isSuccessful" in response && !response.isSuccessful;

export const isErrorResponseWithPayload = (response: any): response is ErrorResponseWithPayload<unknown> =>
    !!response &&
    typeof response === "object" &&
    "isSuccessful" in response &&
    !response.isSuccessful &&
    "payload" in response &&
    !!response.payload;
