import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { PromptTooltipProps } from "./PromptTooltipProps";
import { PromptTooltipStyles } from "./PromptTooltipStyles";

export const PromptTooltip = ({ children, title = "", placement }: PromptTooltipProps) => {
    const classes = PromptTooltipStyles();

    return (
        <Tooltip
            title={title}
            arrow
            placement={placement}
            classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
            }}
        >
            {children}
        </Tooltip>
    );
};
