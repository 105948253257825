import { Grid, Paper } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { deviceStyles } from "./DeviceStyles";
import { MonitorDetailsProps } from "./MonitorDetailsProps";

export default function MonitorDetails({ title, data }: MonitorDetailsProps) {
    const classes = deviceStyles();
    return (
        <Paper className={classes.main}>
            <div style={{ minWidth: "15%" }}>
                <LynxTypography variant="h2">{title}</LynxTypography>
            </div>
            <Grid container spacing={10}>
                {data.map((monitorData) => (
                    <Grid item xs={3} key={monitorData.fieldName}>
                        <LynxTypography variant={"h3"} color="neutral500">
                            {monitorData.fieldName}
                        </LynxTypography>
                        <LynxTypography>{monitorData.fieldValue}</LynxTypography>
                        <LynxTypography paragraph={!!monitorData.fieldSecondRowValue}>
                            {monitorData.fieldSecondRowValue}
                        </LynxTypography>

                        {monitorData.isFieldConditioned ? (
                            monitorData.fieldValue !== monitorData.conditionedData ||
                            monitorData.fieldSecondRowValue !== monitorData.conditionedSecondRowValueData ? (
                                <>
                                    <LynxTypography className={classes.conditioned}>
                                        <LynxIcon name="arrowDown" className={classes.arrowIcon} />
                                        After Annotating
                                    </LynxTypography>
                                    <LynxTypography>{monitorData.conditionedData}</LynxTypography>
                                    <LynxTypography paragraph={!!monitorData.conditionedSecondRowValueData}>
                                        {monitorData.conditionedSecondRowValueData}
                                    </LynxTypography>
                                </>
                            ) : (
                                <LynxTypography className={classes.noChangesText}>
                                    No changes after Annotating
                                </LynxTypography>
                            )
                        ) : null}
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}
