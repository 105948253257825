import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const permissionSectionStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: "13px 0 13px 18px",
            borderRadius: "4px",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            backgroundColor: theme.colors.neutrals.neutral100,
            display: "flex",
            alignItems: "center",
            fill: theme.colors.neutrals.neutral500,
            color: theme.colors.neutrals.neutral500,
        },

        headerExpanded: {
            borderRadius: "4px 4px 0 0",
            backgroundColor: theme.colors.neutrals.white,
        },

        headerTouched: {
            borderColor: theme.colors.primary.blue500,
            fill: theme.colors.primary.blue500,
            color: theme.colors.primary.blue500,
            backgroundColor: theme.colors.neutrals.white,
        },

        headerTouchedExpanded: {
            backgroundColor: theme.colors.primary.blue75,
        },

        headerTouchedDisabled: {
            borderColor: theme.colors.primary.blue300,
            fill: theme.colors.primary.blue300,
            color: theme.colors.primary.blue300,
            backgroundColor: theme.colors.neutrals.white,
        },

        headerLabel: {
            marginLeft: "10px",
        },

        headerButton: {
            marginLeft: "auto",
            padding: "6px 18px 6px 6px",

            "& > svg": {
                width: "1.5rem",
                height: "1.5rem",
                fill: "inherit",
            },
        },

        body: {
            padding: "10px 17px 8px 17px",
            background: theme.colors.neutrals.white,
            borderRadius: "0 0 4px 4px",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            borderTop: "none",
            margin: "0 1px",
        },

        permission: {
            display: "flex",
            alignItems: "center",
            borderBottom: `2px solid ${theme.colors.neutrals.neutral200}`,
            marginTop: "15px",

            "&:last-child": {
                borderBottom: "none",
            },
        },

        permissionName: {
            fontSize: "16px",
            lineHeight: "140%",
            color: theme.colors.neutrals.neutral500,
            marginBottom: "2px",
        },

        permissionDescription: {
            fontSize: "14px",
            lineHeight: "120%",
            color: theme.colors.neutrals.neutral300,
            marginBottom: "6px",
        },

        permissionSwitch: {
            marginLeft: "auto",
            marginRight: "5px",
        },

        overrideDropdown: {
            fontSize: "14px",
            lineHeight: "17px",
            minWidth: "170px",

            "& .MuiSelect-root": {
                padding: "7px 25px 7px 7px",
            },

            "& .MuiSelect-icon": {
                right: "7px",
            },
        },
    })
);
