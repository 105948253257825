import { InputLabel, MenuItem } from "@material-ui/core";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { ErrorList } from "../ErrorList/ErrorList";
import { formStyles } from "./FormStyles";
import { multipleValuesHelper } from "helpers/formHelpers";
import { UserList } from "../UserList/UserList";
import { UserItem } from "../UserList/UserListProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

interface AddCustomerFormProps {
    successCallback: (customerName: string) => void;
}

export const AddCustomerForm = observer((props: AddCustomerFormProps) => {
    const classes = formStyles();
    const { customerStore } = useStore();

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        customerStore.clearErrors();
        customerStore.addCustomerModel[e.target.name as string] = e.target.value;
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        customerStore.clearErrors();
        customerStore.addCustomerModel[e.target.name as string] = e.target.value;
    };

    const handleUserSelectionChange = (newSelection: UserItem[]) => {
        customerStore.clearErrors();
        customerStore.addCustomerModel.systemUsers = newSelection;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        customerStore.addCustomer(props.successCallback);
    };

    useEffect(() => {
        customerStore.loadModulesForSelection();
        customerStore.loadUsersForSelection();
    }, []);

    return (
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formSection}>
                    <LynxTypography className={classes.title}>Create a new customer</LynxTypography>

                    <InputLabel id="create-customer-name-label" className={classes.formInputLabel}>
                        Customer Name
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="name"
                        value={customerStore.addCustomerModel.name}
                        onChange={handleInputChange}
                        placeholder="e.g. Carrier"
                        formControlClassName={classes.input}
                    />

                    <InputLabel id="create-customer-email-label" className={classes.formInputLabel}>
                        Email <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="email"
                        value={customerStore.addCustomerModel.email}
                        onChange={handleInputChange}
                        formControlClassName={classes.input}
                    />

                    <InputLabel id="create-customer-address-label" className={classes.formInputLabel}>
                        Address <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="address"
                        value={customerStore.addCustomerModel.address}
                        onChange={handleInputChange}
                        formControlClassName={classes.input}
                    />
                </div>

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <InputLabel id="create-customer-modules-label" className={classes.formInputLabel}>
                        Modules
                    </InputLabel>
                    <LynxSelect
                        className={classes.fullWidthInput}
                        name="modules"
                        labelId="create-customer-modules-label"
                        multiple
                        value={customerStore.addCustomerModel.modules}
                        onChange={handleSelectChange}
                        displayEmpty
                        renderValue={(x) => multipleValuesHelper(x as string[], customerStore.modulesForSelection)}
                    >
                        {customerStore.modulesForSelection.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelect>
                </div>

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <LynxTypography className={classes.formInputLabel}>Assign System Users</LynxTypography>
                    {customerStore.progressFlags.loadingUsersForSelection ? (
                        <LoadingIndicator className={classes.loadingUsersIndicator} />
                    ) : (
                        <UserList
                            users={customerStore.usersForSelection}
                            onSelectionChange={handleUserSelectionChange}
                        />
                    )}
                </div>

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <ErrorList errors={customerStore.formErrors} style={{ marginBottom: "10px" }} />
                    <LynxButton
                        className={classes.submitButton}
                        disabled={
                            customerStore.progressFlags.loadingModulesForSelection ||
                            customerStore.progressFlags.loadingUsersForSelection ||
                            customerStore.progressFlags.addCustomerRequest
                        }
                        loading={customerStore.progressFlags.addCustomerRequest}
                        type="submit"
                    >
                        Submit
                    </LynxButton>
                </div>
            </form>
        </div>
    );
});
