import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const loginStyles = makeStyles((theme: Theme) =>
    createStyles({
        loginForm: {
            display: "flex",
            flexDirection: "column",
        },

        secondLoginInputMargin: {
            marginBottom: "0.25rem",
        },

        forgotPasswordLink: {
            alignSelf: "end",
            margin: "0.5rem 0 2rem",
        },

        signInButton: {
            marginBottom: "0.75rem",
            width: "100%",
        },

        errorLabel: {
            marginBottom: "0.5rem",
        },

        iconButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },

        title: {
            marginBottom: "2.5rem",
        },

        textMarginBottom: {
            marginBottom: "1.5rem",
        },

        buttonFullWidth: {
            width: "100%",
        },

        successIcon: {
            fill: theme.colors.success.success600,
        },

        errorSectionStyles: {
            margin: "4rem 0 0",
            padding: "1rem 2rem",
        },

        listItem: {
            marginLeft: "0.5rem",
        },

        listIconFill: {
            fill: theme.colors.neutrals.neutral400,
        },

        loadingIndicator: {
            maxWidth: "400px",
        },

        wordBreakAll: {
            wordBreak: "break-all",
        },

        marginVerticalAutoHorizontalZero: {
            margin: "auto 0",
        },

        marginTopVeryLarge: {
            marginTop: "4rem",
        },

        marginTopLarge: {
            marginTop: "3rem",
        },

        marginTopMiddle: {
            marginTop: "2rem",
        },

        marginTopSmall: {
            marginTop: "1rem",
        },

        marginBottomLarge: {
            marginBottom: "3rem",
        },

        marginBottomMedium: {
            marginBottom: "2rem",
        },

        marginBottomVerySmall: {
            marginBottom: "0.5rem",
        },

        paddingTopZero: {
            paddingTop: "0",
        },
    })
);
