import { Dayjs } from "dayjs";
import { dateInLocalTimezone, dateInUTC } from "./timezoneHelper";

export function dateToFormat(
    date: Date | string | number | null | undefined | Dayjs,
    format: string,
    isInUtc?: boolean
): string {
    const dayjsDate = isInUtc ? dateInUTC(date) : dateInLocalTimezone(date);

    return dayjsDate.format(format);
}
