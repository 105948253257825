import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const LynxButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            pointerEvents: "all !important" as any,
            borderRadius: "0.25rem",
            "&:focus-visible": {
                boxShadow: theme.focusShadow,
                border: `0.25rem solid ${theme.colors.dataVisualization.orange}`,
            },

            "&:disabled": {
                cursor: "not-allowed",
            },

            "& path": {
                fill: "currentColor",
            },

            transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },

        primary: {
            backgroundColor: theme.colors.primary.blue500,
            border: `1px solid ${theme.colors.primary.blue500}`,

            "&:hover": {
                backgroundColor: theme.colors.primary.blue600,
                borderColor: theme.colors.primary.blue600,
            },

            "&:disabled": {
                color: theme.colors.neutrals.neutral300,
                backgroundColor: theme.colors.neutrals.neutral200,
                borderColor: theme.colors.neutrals.neutral200,
            },

            "&:active": {
                backgroundColor: theme.colors.primary.blue400,
            },

            "&:disabled, active": {
                backgroundColor: theme.colors.neutrals.neutral200,
            },
        },

        secondary: {
            backgroundColor: "inherit",
            border: `1px solid ${theme.colors.primary.blue300}`,

            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
                border: `1px solid ${theme.colors.primary.blue300}`,
            },

            "&:disabled": {
                color: theme.colors.neutrals.neutral300,
                backgroundColor: "inherit",
                border: `1px solid ${theme.colors.neutrals.neutral300}`,
            },

            "&:active": {
                backgroundColor: theme.colors.primary.blue200,
            },

            "&:disabled, active": {
                backgroundColor: "inherit",
            },
        },

        tertiary: {
            backgroundColor: "inherit",

            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },

            "&:disabled": {
                color: theme.colors.neutrals.neutral300,
                backgroundColor: "inherit",
            },

            "&:active": {
                backgroundColor: theme.colors.primary.blue200,
            },
            "&:disabled, active": {
                backgroundColor: "inherit",
            },
        },
        icon: {
            gap: "0.5rem",
            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },
            "&:active": {
                backgroundColor: theme.colors.primary.blue200,
            },
            "&:disabled": {
                fill: theme.colors.neutrals.neutral300,
                cursor: "not-allowed",
                backgroundColor: "inherit",
            },
            "&:focus-visible": {
                boxShadow: theme.focusShadow,
                border: `0.25rem solid ${theme.colors.dataVisualization.orange}`,
            },

            "&:disabled, active": {
                backgroundColor: "inherit",
            },
        },
        iconSizeSmall: {
            padding: "0.25rem",
        },
        iconSizeMedium: {
            padding: "0.5rem",
        },
        iconSizeLarge: {
            padding: "1rem",
        },

        large: {
            padding: "1rem 1.5rem",
        },

        medium: {
            padding: "0.4375rem 0.9375rem",
        },
        small: {
            padding: "0.1875rem 0.9375rem",
        },

        tertiarySmall: {
            padding: "0.25rem 0.25rem",
        },

        typography: {
            display: "flex",
            alignItems: "center",
        },

        iconBase: {
            display: "flex",
        },

        leftIcon: {
            marginRight: "0.5rem",
        },
        rightIcon: {
            marginLeft: "0.5rem",
        },
        loadingIcon: {
            marginLeft: "0.5rem",
        },

        customTooltip: {
            backgroundColor: `${theme.colors.neutrals.neutral600}`,
            color: theme.palette.common.white,
        },
        customArrow: {
            "&:before": {
                backgroundColor: `${theme.colors.neutrals.neutral600}`,
            },
        },
    })
);
