import { searchParameters } from "./lynxSearchParameters";

type SearchParameterPlaceholder<T extends "event" | "product"> = typeof searchParameters[T][number]["value"];

export const getSearchPlaceholder = <T extends "event" | "product">(
    type: T,
    currentParameter: SearchParameterPlaceholder<T>
) => {
    const index = searchParameters[type].findIndex((x) => x.value === currentParameter);

    const label = searchParameters[type][index].label;

    return "Search by " + label;
};
