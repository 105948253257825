import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { ErrorListProps } from "./ErrorListProps";
import { errorListStyles } from "./ErrorListStyles";

export const ErrorList = ({ errors, className, style }: ErrorListProps) => {
    const classes = errorListStyles();

    if (errors.length === 0) {
        return null;
    }

    return (
        <div className={className} style={style}>
            {errors.map((x) => (
                <LynxTypography key={x} className={classes.error}>
                    {x}
                </LynxTypography>
            ))}
        </div>
    );
};
