import { Point } from "chart.js";
import { SliderOptions } from "./crop-temperature-range-augmentation";
import { Rectangle } from "./interfaces/rectangle";

export class RightElement extends Rectangle {
    getX(): number {
        return this.scaleX.getPixelForValue(this.sliderSlidingBorderValue);
    }

    getY(): number {
        return this.scaleY.top;
    }

    getWidth(): number {
        const x = this.getX();
        const xSlide = this.scaleX.getPixelForValue(this.sliderFixedBorderValue);
        return xSlide - x;
    }

    getHeight(): number {
        return this.scaleY.height;
    }

    // eslint-disable-next-line no-useless-constructor
    constructor(xAxisMinimumValue: number, xAxisMaximumValue: number, options: SliderOptions) {
        super(xAxisMinimumValue, xAxisMaximumValue, options);
    }

    /** @override */
    public isInside(point: Point): boolean {
        const lX = this.getX();
        const left = lX - 4;
        const right = lX + 4;
        return point.x >= left && point.x <= right;
    }
}
