import clsx from "clsx";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxInputProps } from "components/LynxComponents/LynxInput/LynxInputProps";
import { FieldHookConfig, useField } from "formik";
import { ChangeEvent } from "react";
import { reusableFormsStyles } from "./ReusableFormsStyles";

const LynxInputForm = (props: FieldHookConfig<string> & LynxInputProps) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && !!meta.error;

    const {
        size = "medium",
        error = hasError,
        assistiveTextClassName,
        leaveSpaceForContent = true,
        labelTypographyProps,
        className,
        autoComplete = "off",
        ...rest
    } = { ...props };

    const classes = reusableFormsStyles({ size, error });

    const { onChange, onBlur, value, ...fieldRest } = { ...field };

    const assistiveTextClasses = clsx(
        assistiveTextClassName,
        !hasError && (leaveSpaceForContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone)
    );

    const inputBaseClasses = clsx(classes.inputBackground, className);

    const handleChange = (e: ChangeEvent<any>) => {
        onChange(e);

        !!meta.error && helpers.setError(undefined);
    };

    return (
        <LynxInput
            autoComplete={autoComplete}
            assistiveText={meta.error || "Error"}
            assistiveTextClassName={assistiveTextClasses}
            className={inputBaseClasses}
            labelTypographyProps={labelTypographyProps}
            value={props.value ?? value}
            onChange={props.onChange ?? handleChange}
            onBlur={props.onBlur ?? onBlur}
            error={hasError}
            {...rest}
            {...fieldRest}
        />
    );
};

export default LynxInputForm;
