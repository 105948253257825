import * as auditApiModels from "api/models/audit/auditApi";
import { services } from "api/serviceConfig";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import { makeAutoObservable } from "mobx";
import CommonStore from "./CommonStore";

interface ProgressFlags {
    loadingAuditEntries: boolean;
    loadingAuditEntityShortInfo: boolean;
    exportingToCsv: boolean;
    exportingToPdf: boolean;
}

export class AuditStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    progressFlags: ProgressFlags = {
        loadingAuditEntries: false,
        loadingAuditEntityShortInfo: false,
        exportingToPdf: false,
        exportingToCsv: false,
    };

    commonStore: CommonStore;
    auditEntries: auditApiModels.HumanReadableAuditEntryModel[] = [];
    auditEntityShortInfo: auditApiModels.AuditEntityShortInfoResponse = {
        id: "",
        displayId: "",
        customerId: null,
        timezone: null,
    };

    currentPageIsAudit = false;

    setCurrentPageIsAudit = (value: boolean) => {
        this.currentPageIsAudit = value;
    };

    setProgressFlags = (value: Partial<ProgressFlags>) => {
        this.progressFlags = { ...this.progressFlags, ...value };
    };

    setAuditEntries = (value: auditApiModels.HumanReadableAuditEntryModel[]) => {
        this.auditEntries = value;
    };

    resetAuditEntityShortInfo = () => {
        this.auditEntityShortInfo = {
            id: "",
            displayId: "",
            customerId: null,
            timezone: null,
        };
    };

    loadCustomerAuditEntries = async (request: auditApiModels.GetHumanReadableCustomerAuditEntriesRequest) => {
        if (this.progressFlags.loadingAuditEntries) {
            return;
        }

        this.setProgressFlags({ loadingAuditEntries: true });

        try {
            const response = await services.Audit.getHumanReadableCustomerAuditEntries(request);

            if (response.status === 200) {
                this.setAuditEntries(response.data.items);
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
            }
        } finally {
            this.setProgressFlags({ loadingAuditEntries: false });
        }
    };

    loadSystemAuditEntries = async (request: auditApiModels.GetHumanReadableSystemAuditEntriesRequest) => {
        if (this.progressFlags.loadingAuditEntries) {
            return;
        }

        this.setProgressFlags({ loadingAuditEntries: true });

        try {
            const response = await services.Audit.getHumanReadableSystemAuditEntries(request);

            if (response.status === 200) {
                this.setAuditEntries(response.data.items);
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
            }
        } finally {
            this.setProgressFlags({ loadingAuditEntries: false });
        }
    };

    loadCustomerAuditEntityShortInfo = async (request: auditApiModels.GetCustomerAuditEntityShortInfoRequest) => {
        if (this.progressFlags.loadingAuditEntityShortInfo) {
            return;
        }

        this.setProgressFlags({ loadingAuditEntityShortInfo: true });

        try {
            const response = await services.Audit.getCustomerAuditEntityShortInfo(request);

            if (response.status === 200) {
                this.auditEntityShortInfo = response.data;
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
            }
        } finally {
            this.setProgressFlags({ loadingAuditEntityShortInfo: false });
        }
    };

    loadSystemAuditEntityShortInfo = async (request: auditApiModels.GetSystemAuditEntityShortInfoRequest) => {
        if (this.progressFlags.loadingAuditEntityShortInfo) {
            return;
        }

        this.setProgressFlags({ loadingAuditEntityShortInfo: true });

        try {
            const response = await services.Audit.getSystemAuditEntityShortInfo(request);

            if (response.status === 200) {
                this.auditEntityShortInfo = response.data;
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
            }
        } finally {
            this.setProgressFlags({ loadingAuditEntityShortInfo: false });
        }
    };

    exportCustomerAuditEntriesToPdf = async (request: auditApiModels.ExportCustomerAuditEntriesToPdfRequest) => {
        if (this.progressFlags.exportingToPdf) {
            return;
        }

        this.setProgressFlags({ exportingToPdf: true });

        try {
            const response = await services.Audit.exportCustomerAuditEntriesToPdf(request);

            if (response.status === 200) {
                const fileName = extractFileNameFromContentDisHeader(response);

                forceFileDownload(fileName, response.data);
            }
        } finally {
            this.setProgressFlags({ exportingToPdf: false });
        }
    };

    exportSystemAuditEntriesToPdf = async (request: auditApiModels.ExportSystemAuditEntriesToPdfRequest) => {
        if (this.progressFlags.exportingToPdf) {
            return;
        }

        this.setProgressFlags({ exportingToPdf: true });

        try {
            const response = await services.Audit.exportSystemAuditEntriesToPdf(request);

            if (response.status === 200) {
                const fileName = extractFileNameFromContentDisHeader(response);

                forceFileDownload(fileName, response.data);
            }
        } finally {
            this.setProgressFlags({ exportingToPdf: false });
        }
    };

    exportCustomerAuditEntriesToCsv = async (request: auditApiModels.ExportCustomerAuditEntriesToCsvRequest) => {
        if (this.progressFlags.exportingToCsv) {
            return;
        }

        this.setProgressFlags({ exportingToCsv: true });

        try {
            const response = await services.Audit.exportCustomerAuditEntriesToCsv(request);

            if (response.status === 200) {
                const fileName = extractFileNameFromContentDisHeader(response);

                forceFileDownload(fileName, response.data);
            }
        } finally {
            this.setProgressFlags({ exportingToCsv: false });
        }
    };

    exportSystemAuditEntriesToCsv = async (request: auditApiModels.ExportSystemAuditEntriesToCsvRequest) => {
        if (this.progressFlags.exportingToCsv) {
            return;
        }

        this.setProgressFlags({ exportingToCsv: true });

        try {
            const response = await services.Audit.exportSystemAuditEntriesToCsv(request);

            if (response.status === 200) {
                const fileName = extractFileNameFromContentDisHeader(response);

                forceFileDownload(fileName, response.data);
            }
        } finally {
            this.setProgressFlags({ exportingToCsv: false });
        }
    };
}
