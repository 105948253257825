import { object, string, ref } from "yup";

export enum ResetPasswordErrorType {
    MinLength = "min",
    AtLeastOneLowercase = "atLeastOneLowercase",
    AtLeastOneUppercase = "atLeastOneUppercase",
    AtLeastOneNumeric = "atLeastOneNumeric",
}

export const resetPasswordValidationSchema = () =>
    object({
        password: string()
            .required()
            .min(8)
            .max(100)
            .matches(/(?=.*[a-z])/, {
                message: "Must contain at least one lowercase letter",
                name: ResetPasswordErrorType.AtLeastOneLowercase,
            })
            .matches(/(?=.*[A-Z])/, {
                message: "Must contain at least one uppercase letter",
                name: ResetPasswordErrorType.AtLeastOneUppercase,
            })
            .matches(/(?=.*?[0-9])/, {
                message: "Must contain at least one numeric",
                name: ResetPasswordErrorType.AtLeastOneNumeric,
            }),
        confirmPassword: string()
            .required()
            .max(100)
            .oneOf([ref("password")], "Passwords must match"),
    });
