import { Grid, MenuItem } from "@material-ui/core";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxMultipleInputForm from "components/ReusableForms/LynxMultipleInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { FormikProps, useFormikContext } from "formik";
import {
    RangeOperatorFrom,
    RangeOperatorTo,
    StabilityFormModel,
} from "models/productAndStabilityForm/productAndStabilityFormModels";

import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { observer } from "mobx-react";
import { ChangeEvent } from "react";
import { useStore } from "store/StoreConfigs";
import { validationHelpers } from "validation/helper-functions";
import {
    defineRangeOperatorFrom,
    defineRangeOperatorTo,
    handleChangeRangeOperatorFrom,
    handleChangeRangeOperatorTo,
} from "../helper-functions";
import { stabilityFormStyles } from "../StabilityFormStyles";
import RangeFieldsArea from "./RangeFieldsArea";
import RepresentationFieldsArea from "./RepresentationFieldsArea";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

const ProductFieldsArea = observer(() => {
    const formik = useFormikContext<StabilityFormModel>();
    const { customerDataStore } = useStore();
    const classes = stabilityFormStyles();

    const handleChangeProductType = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        formik: FormikProps<StabilityFormModel>
    ) => {
        const storageTypeId = e.target.value as string;

        formik.setFieldValue("product.storageTypeId", storageTypeId);
        !!formik.errors.product?.storageTypeId && formik.setFieldError("product.storageTypeId", undefined);

        const storageType = customerDataStore.productSupportInfo.storageTypes.find((v) => v.id === storageTypeId);

        const lowValue = storageType?.lowerLimit?.toString() ?? "";
        const highValue = storageType?.upperLimit?.toString() ?? "";

        formik.setFieldValue("product.storageConditionFrom", lowValue);
        !!formik.errors.product?.storageConditionFrom &&
            formik.setFieldError("product.storageConditionFrom", undefined);

        formik.setFieldValue("product.storageConditionTo", highValue);
        !!formik.errors.product?.storageConditionTo && formik.setFieldError("product.storageConditionTo", undefined);

        formik.setFieldValue("product.rangeOperatorFrom", defineRangeOperatorFrom(lowValue));
        formik.setFieldValue("product.rangeOperatorTo", defineRangeOperatorTo(highValue));
    };

    return (
        <SectionCard>
            <LynxTypography variant={"h2"} className={classes.marginBottomLarge}>
                Product Information
            </LynxTypography>
            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.displayId"
                        label={<LabelWithRequiredSymbol label="Product ID (GMID)" />}
                        placeholder="Enter Product ID"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.name"
                        label={<LabelWithRequiredSymbol label="Product Name" />}
                        placeholder="Enter Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.genericName"
                        label="Generic Product Name"
                        placeholder="Enter Generic Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxSelectForm
                        name="product.categoryId"
                        label="Product Category"
                        placeholder="Select Product Category"
                        showGeneratedDefaultOption
                    >
                        {customerDataStore.productSupportInfo.categories.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>
            </Grid>

            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxSelectForm
                        name="product.presentationId"
                        label="Presentation"
                        placeholder="Select Presentation"
                        showGeneratedDefaultOption
                    >
                        {customerDataStore.productSupportInfo.presentations.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>

                <Grid item xs={3}>
                    <LynxSelectForm
                        name="product.storageTypeId"
                        label={<LabelWithRequiredSymbol label="Product Type" />}
                        placeholder="Select Product Type"
                        onChange={(
                            e: ChangeEvent<{
                                name?: string | undefined;
                                value: unknown;
                            }>
                        ) => handleChangeProductType(e, formik)}
                    >
                        {customerDataStore.productSupportInfo.storageTypes.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>

                <Grid item xs={6}>
                    <LynxTypography variant="body-s" color="neutral400" className={classes.label}>
                        {<LabelWithRequiredSymbol label="Product Storage Conditions" />}
                    </LynxTypography>
                    <RangeFieldsArea
                        operatorFrom={{
                            selectProps: {
                                name: "product.rangeOperatorFrom",
                                disabled: formik.values.product.storageTypeId.length <= 0,
                                onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                    handleChangeRangeOperatorFrom(
                                        e,
                                        formik,
                                        "product.rangeOperatorFrom",
                                        "product.storageConditionFrom"
                                    ),
                                className: classes.minWidthOperator,
                            },
                            infinityDisabled: formik.values.product.rangeOperatorTo === RangeOperatorTo.INFINITY,
                            assistiveText: {
                                content: formik.errors.product?.rangeOperatorFrom || "Min Temp",
                            },
                            errorText: formik.errors.product?.rangeOperatorFrom,
                        }}
                        from={{
                            inputProps: {
                                name: "product.storageConditionFrom",
                                placeholder: "Low",
                                disabled:
                                    formik.values.product.storageTypeId.length <= 0 ||
                                    formik.values.product.rangeOperatorFrom === RangeOperatorFrom.INFINITY,
                            },
                            errorText: {
                                content: formik.errors.product?.storageConditionFrom,
                            },
                        }}
                        operatorTo={{
                            selectProps: {
                                name: "product.rangeOperatorTo",
                                disabled: formik.values.product.storageTypeId.length <= 0,
                                onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                    handleChangeRangeOperatorTo(
                                        e,
                                        formik,
                                        "product.rangeOperatorTo",
                                        "product.storageConditionTo"
                                    ),
                                className: classes.minWidthOperator,
                            },
                            infinityDisabled: formik.values.product.rangeOperatorFrom === RangeOperatorFrom.INFINITY,
                            assistiveText: {
                                content: "Max Temp",
                            },
                            errorText: formik.errors.product?.rangeOperatorTo,
                        }}
                        to={{
                            inputProps: {
                                name: "product.storageConditionTo",
                                placeholder: "High",
                                disabled:
                                    formik.values.product.storageTypeId.length <= 0 ||
                                    formik.values.product.rangeOperatorTo === RangeOperatorTo.INFINITY,
                            },
                            errorText: {
                                content: formik.errors.product?.storageConditionTo,
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.expiryPeriodInHours"
                        label="Expiry Period (hours)"
                        placeholder="Enter Expiry Period"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.freezeCycleLimit"
                        label="Freeze Cycle Count Allowed"
                        placeholder="Enter Freeze Cycle Count Allowed"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.productSpec"
                        label="Product Spec"
                        placeholder="Enter Product Spec"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxMultipleInputForm
                        name="product.SKUs"
                        label="SKUs"
                        placeholder="Enter SKUs"
                        validation={{
                            field: "product.SKUs",
                            isValid: validationHelpers.isValidSKUs,
                            setFieldError: formik.setFieldError,
                            errorMessage: "Must be a positive integer",
                        }}
                    />
                </Grid>

                {/* <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.heatCycleLimit"
                        label="Heat Cycle Count Allowed"
                        placeholder="Enter Heat Cycle Count Allowed"
                    />
                </Grid> */}
            </Grid>

            {/* <Grid container item spacing={4}>
                
            </Grid> */}

            <RepresentationFieldsArea />
        </SectionCard>
    );
});

export default ProductFieldsArea;
