import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const loginLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
        },

        sideImage: {
            backgroundImage: "url(/images/LandingImage.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
        },

        rightPartOfScreenContainer: {
            margin: "0 auto 0.75rem",
            maxWidth: "400px",
            minWidth: "150px",
        },

        logo: {
            width: "9rem",
            height: "4.5rem",
        },

        mainContentContainer: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
        },

        marginTopLarge: {
            marginTop: "4rem",
        },

        marginTopSmall: {
            marginTop: "1rem",
        },
    })
);
