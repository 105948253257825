import { apiRoutes } from "api/apiRoutes";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";

export class CustomersService {
    listCustomers = async (request: userManagementApi.ListCustomersRequest) => {
        const url = apiRoutes.customers.listCustomers;

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
            },
        };

        const response = await torAxios.get<Page<userManagementApi.CustomerListItemResponse>>(url, requestConfig);
        return response;
    };

    getCustomerDetails = async (request: userManagementApi.GetCustomerDetailsRequest) => {
        const url = apiRoutes.customers.getCustomerDetails(request.customerId);
        const response = await torAxios.get<userManagementApi.CustomerDetailsResponse>(url);
        return response;
    };

    createCustomer = async (request: userManagementApi.CreateCustomerRequest) => {
        const url = apiRoutes.customers.createCustomer;

        const body = {
            name: request.name,
            email: request.email,
            address: request.address,
            modules: request.modules,
            systemUsers: request.systemUsers,
        };

        const response = await torAxiosIgnoreErrors.post<userManagementApi.CustomerDetailsResponse>(url, body);
        return response;
    };

    editCustomer = async (request: userManagementApi.EditCustomerRequest) => {
        const url = apiRoutes.customers.editCustomer(request.customerId);

        const body = {
            fieldsToEdit: request.fieldsToEdit,
            name: request.name,
            email: request.email,
            address: request.address,
            status: request.status,
            addedModules: request.addedModules,
            removedModules: request.removedModules,
            addedSystemUsers: request.addedSystemUsers,
            removedSystemUsers: request.removedSystemUsers,
        };

        const response = await torAxiosIgnoreErrors.patch<userManagementApi.CustomerDetailsResponse>(url, body);
        return response;
    };

    listModules = async (request?: userManagementApi.ListModulesRequest) => {
        const url = apiRoutes.customers.listModules;
        const response = await torAxios.get<userManagementApi.ModuleListItemResponse[]>(url);
        return response;
    };

    listCustomerModules = async (request: userManagementApi.ListCustomerModulesRequest) => {
        const url = apiRoutes.customers.listCustomerModules(request.customerId);
        const response = await torAxios.get<userManagementApi.ModuleListItemResponse[]>(url);
        return response;
    };
}
