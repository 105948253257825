import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import { notFoundPageStyles } from "./NotFoundPageStyles";

export const NotFoundPage = observer(() => {
    const classes = notFoundPageStyles();
    const { identityStore } = useStore();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <LynxTypography variant="h1">404</LynxTypography>
            <LynxTypography variant="body-medium">Ooops! Page not found</LynxTypography>
            <LynxButton
                className={classes.button}
                variant="primary"
                size="medium"
                rightIcon={<LynxIcon name="angleSmallRight" />}
                onClick={() => navigate(identityStore.startPageAvailable)}
            >
                Go to the Home page
            </LynxButton>
        </div>
    );
});
