import { lowerHeaderPortalId } from "layout/LynxHeader";
import { ScrollWatcher } from "./ScrollWatcher";
import { createPortal } from "react-dom";
import { ReactNode } from "react";

export interface HeaderPortalRenderingProps {
    children: ReactNode;
    stickyEnabled: boolean;
    callback: (isOutsideViewport: boolean) => void;
}

export const HeaderPortal = (props: HeaderPortalRenderingProps) => {
    const portalNode = document.getElementById(lowerHeaderPortalId);

    return (
        <>
            <ScrollWatcher callback={props.callback} />
            {props.stickyEnabled && portalNode ? createPortal(props.children, portalNode!) : props.children}
        </>
    );
};
