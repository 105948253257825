import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxSwitchStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            borderRadius: "220px",
            width: "28px",
            height: "16px",

            "& .MuiSwitch-switchBase": {
                padding: 2,
                pointerEvents: "all",
            },

            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(12px)",
            },

            "& .MuiSwitch-thumb": {
                width: "12px",
                height: "12px",
                boxShadow: "none",
            },

            "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                background: "white",
            },

            "& .MuiSwitch-track": {
                opacity: "initial",
                backgroundColor: theme.colors.neutrals.neutral300,
            },

            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: theme.colors.primary.blue400,
                opacity: "initial",
            },

            "& .MuiSwitch-switchBase.Mui-disabled": {
                cursor: "not-allowed",
            },

            "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
                backgroundColor: theme.colors.neutrals.white,
                border: `1px solid ${theme.colors.neutrals.neutral300}`,
            },

            "& .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb": {
                background: theme.colors.neutrals.neutral200,
            },

            "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track": {
                backgroundColor: theme.colors.primary.blue200,
                border: `1px solid ${theme.colors.primary.blue200}`,
            },

            "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked .MuiSwitch-thumb": {
                background: theme.colors.neutrals.white,
            },
        },
    })
);
