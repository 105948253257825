import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventAttachmentsStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridMarginBottomMedium: {
            marginBottom: "1rem",
        },

        buttonWithIcon: {
            marginTop: "0.5rem",
        },
    })
);
