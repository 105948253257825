import { FormControlLabel, Radio } from "@material-ui/core";
import clsx from "clsx";
import { LynxRadioProps } from "./LynxRadioProps";
import { lynxRadioStyles } from "./LynxRadioStyles";
import LynxTypography from "../LynxTypography/LynxTypography";

export const LynxRadio = (props: LynxRadioProps) => {
    const { className, label, ...otherProps } = props;

    const classes = lynxRadioStyles();

    return (
        <FormControlLabel
            className={classes.root}
            control={
                <Radio
                    className={clsx(classes.radio, className)}
                    disableRipple
                    icon={<span className={classes.icon} />}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    tabIndex={0}
                    {...otherProps}
                />
            }
            label={!!label && <LynxTypography>{label}</LynxTypography>}
        />
    );
};
