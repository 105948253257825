import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const mainLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },

        rightSide: {
            flexGrow: 1,
        },
    })
);
