import { useEffect } from "react";
import { Outlet } from "react-router";
import { useStore } from "store/StoreConfigs";

const GroupSectionWrapper = () => {
    const { groupStore } = useStore();

    const clearGroupsPagination = () => {
        groupStore.clearPagination();
    };

    useEffect(() => {
        return () => {
            clearGroupsPagination();
        };
    }, []);

    return <Outlet />;
};

export default GroupSectionWrapper;
