import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxSidebarStyles = makeStyles((theme: Theme) => {
    return createStyles({
        sidebar: {
            width: "4.5625rem",
        },

        drawerPaper: {
            backgroundColor: theme.colors.neutrals.neutral500,
            // required to show orange focus outlines outside parent container
            overflow: "visible",
            // bottom padding should be 1 rem, 0.25rem are specified on footer so orange outline on button is not cut off by footer borders
            padding: "0.5rem 0 0.75rem 0",
            borderRight: `1px solid ${theme.colors.neutrals.neutral400}`,
        },

        divider: {
            backgroundColor: theme.colors.neutrals.neutral400,
            // set this because otherwise it displays over orange outline
            zIndex: -1,
        },

        dividerFirst: {
            margin: "0.4375rem 0 0.25rem 0",
        },

        dividerSecond: {
            margin: "0.1875rem 0 0.25rem 0",
        },

        icon: {
            flexShrink: 0,
            fill: theme.colors.neutrals.neutral500,
            width: "1.5rem",
            height: "1.5rem",
        },

        lynx2LetterLogoIcon: {
            flexShrink: 0,
        },

        logo: {
            display: "flex",
            padding: "0 0.75rem",
            alignItems: "center",
            flexWrap: "nowrap",
            textDecoration: "none",
            color: theme.colors.neutrals.neutral200,
            overflow: "hidden",

            "&:focus-visible": {
                outline: theme.outline,
                borderRadius: `0 ${theme.borderRadius} ${theme.borderRadius} 0`,
                // so orange outline is not covered by background color of adjacent nav item
                zIndex: 2,
            },
        },

        navItem: {
            display: "flex",
            padding: "1rem 1.5rem",
            alignItems: "center",
            flexWrap: "nowrap",
            textDecoration: "none",
            color: theme.colors.neutrals.white,
            overflow: "hidden",

            "& > svg": {
                fill: theme.colors.neutrals.neutral300,
            },

            "&:hover": {
                backgroundColor: theme.colors.neutrals.neutral400,

                "& > svg": {
                    fill: theme.colors.primary.blue200,
                },
            },

            "&:focus-visible": {
                outline: theme.outline,
                borderRadius: `0 ${theme.borderRadius} ${theme.borderRadius} 0`,
                // so orange outline is not covered by background color of adjacent nav items
                zIndex: 1,
            },
        },

        navItemActive: {
            boxShadow: `0.25rem 0 0 0 ${theme.colors.primary.blue200} inset`,

            "& > svg": {
                fill: theme.colors.primary.blue200,
            },
        },

        navItemDisabled: {
            backgroundColor: theme.colors.neutrals.neutral500,
            color: theme.colors.neutrals.neutral400,

            "& > svg": {
                fill: theme.colors.neutrals.neutral400,
            },
        },

        navItemText: {
            marginLeft: "1rem",
            whiteSpace: "nowrap",
        },

        interactionsDisabled: {
            pointerEvents: "none",

            "&:hover, &:focus": {
                backgroundColor: "currentColor",
                fill: "currentColor",
            },
        },

        arrowCollapsed: {
            position: "absolute",
            right: "0.5rem",
        },

        arrowRotated: {
            rotate: "180deg",
        },

        zeroPadding: {
            padding: "0",
        },
    });
});
