import { CircularProgress, CircularProgressProps, Grid, InputAdornment, MenuItem, Select } from "@material-ui/core";
import clsx from "clsx";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import useDebounce from "hooks/useDebounce";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "store/StoreConfigs";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxDropdownProps } from "./LynxDropdownProps";
import { lynxDropdownStyles } from "./LynxDropdownStyles";

export default observer(function LynxDropdown(props: LynxDropdownProps) {
    const classes = lynxDropdownStyles();
    const { userStore, identityStore } = useStore();
    const { className, loading, onChange, ...otherProps } = props;
    const [searchValue, setSearchValue] = useState("");
    const debouncedValue = useDebounce<string>(searchValue, 800);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const circularProgress = (props: CircularProgressProps) => <CircularProgress size="1rem" {...props} />;

    const handleChecked = (value: string) => {
        if ((props.value as string[]).length === 0) {
            return false;
        } else {
            return (props.value as string[]).some((x) => x === value);
        }
    };

    const getUsers = async () => {
        if (!loading) {
            const request = {
                pageNumber: 1,
                pageSize: 5,
                customerId: identityStore.currentCustomer.id,
                searchValue: searchValue.trim(),
                searchParameter: userStore.searchParameter,
            };

            await userStore.loadCustomerUsers(request);
        }
    };

    const handleUsersDropdown = async () => {
        try {
            await getUsers();
        } finally {
            if (inputRef.current) {
                const inputElement = inputRef.current.querySelector("input");
                if (inputElement) {
                    inputElement.focus();
                    inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
                }
            }
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = e.target.value.trimLeft().replace(/\s{2,}/g, " ") as string;

        setSearchValue(inputValue);
    };

    useEffect(() => {
        if (props.isUsersDropdown) {
            userStore.setSearchParameter(["FullName"]);

            handleUsersDropdown();
        }
    }, [debouncedValue]);

    return (
        <>
            <Select
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    getContentAnchorEl: null,
                    className: classes.menu,
                }}
                disableUnderline
                IconComponent={(props) => {
                    const { className, ...rest } = props;

                    return loading ? (
                        circularProgress(props)
                    ) : (
                        <LynxIcon name="angleSmallDown" className={className} {...rest} />
                    );
                }}
                className={clsx(classes.root, className)}
                multiple
                renderValue={(x: any) => <LynxTypography variant="body-medium">{props.label}</LynxTypography>}
                onChange={(e, c) => {
                    // event is still propagated for some reason so need to check explicitly
                    if (!e.isPropagationStopped() && onChange) {
                        onChange(e, c);
                    }
                }}
                {...otherProps}
            >
                {props.isUsersDropdown && (
                    <div
                        className={classes.inputContainer}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <LynxInput
                            ref={inputRef}
                            disabled={loading}
                            endAdornment={
                                <InputAdornment position="end">
                                    <LynxIcon name="search" className={classes.searchIcon} onClick={getUsers} />
                                    &nbsp;
                                </InputAdornment>
                            }
                            value={searchValue}
                            placeholder="Search"
                            size="small"
                            className={classes.search}
                            onChange={handleSearch}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    getUsers();
                                }
                            }}
                        />
                        {props.options.length === 0 && !loading && (
                            <div className={classes.loadingIndicatorContainer}>
                                <LynxTypography variant="body-xs">No results found</LynxTypography>
                            </div>
                        )}
                    </div>
                )}

                {loading ? (
                    <div className={classes.loadingIndicatorContainer} onClick={(e) => e.stopPropagation()}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    props.options.map((x) => (
                        <MenuItem
                            value={JSON.stringify({
                                itemDisplayName: x.itemDisplayName,
                                itemValue: x.itemValue,
                            })}
                            key={x.itemValue}
                            className={classes.menuItem}
                        >
                            <Grid container>
                                <Grid item>
                                    {props.isUsersDropdown && <LynxAvatar firstName={`${x.itemDisplayName}`} />}
                                </Grid>
                                <Grid item>
                                    <LynxTypography
                                        variant={
                                            handleChecked(
                                                JSON.stringify({
                                                    itemDisplayName: x.itemDisplayName,
                                                    itemValue: x.itemValue,
                                                })
                                            )
                                                ? "body-medium"
                                                : "body"
                                        }
                                    >
                                        {x.itemDisplayName}
                                        {props.isUsersDropdown && (
                                            <LynxTypography variant="body-s" color="neutral400">
                                                {x.itemValue}
                                            </LynxTypography>
                                        )}
                                    </LynxTypography>
                                </Grid>
                            </Grid>
                            <LynxCheckBox
                                labelClassName={classes.checkboxLabel}
                                checked={handleChecked(
                                    JSON.stringify({
                                        itemDisplayName: x.itemDisplayName,
                                        itemValue: x.itemValue,
                                    })
                                )}
                            />
                        </MenuItem>
                    ))
                )}
            </Select>
        </>
    );
});
