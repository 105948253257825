import { createStyles, Theme, makeStyles } from "@material-ui/core";

interface LynxInputStylesProps {
    size: "small" | "medium" | "large";
    error?: boolean;
}

export const lynxInputStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },

        inputBase: {
            padding: ({ size }: LynxInputStylesProps) => {
                if (size === "small") {
                    return theme.spacing("5px", 2, "3px", 2);
                } else if (size === "medium") {
                    return theme.spacing("8px", 2, "8px", 2);
                } else {
                    return theme.spacing("11px", 2, "10px", 2);
                }
            },
            borderRadius: theme.shape.borderRadius,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.neutrals.neutral300,
        },

        readOnly: {
            border: "none !important",
            paddingLeft: "0px !important",
        },

        input: {
            padding: 0,
            ...theme.typography.body1,
            height: "initial",

            "&::placeholder": {
                color: theme.colors.neutrals.neutral300,
                opacity: 1,
            },
        },

        label: {
            marginBottom: "0.25rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral400,
            },
        },

        focused: {
            borderColor: `${theme.colors.primary.blue400} !important`,
        },

        error: {
            borderColor: theme.colors.critical.critical500,
        },

        disabled: {
            backgroundColor: theme.colors.neutrals.neutral200,
            borderColor: `${theme.colors.neutrals.neutral300} !important`,
            color: theme.colors.neutrals.neutral400,
        },

        eyeIconButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
    })
);
