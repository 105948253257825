import { createTheme } from "@material-ui/core/styles";
import { CustomTheme } from "./CustomThemeInterface";
import { customValues } from "./CustomThemeValues";

// TODO: figure out a better way of adding custom values to the theme
declare module "@material-ui/core/styles/createTheme" {
    interface Theme extends CustomTheme {}
    // allow configuration using `createTheme`
    interface ThemeOptions extends CustomTheme {}
}

export const theme = createTheme({
    ...customValues,
    palette: {
        background: {
            default: customValues.colors.neutrals.white,
        },
        text: {
            primary: customValues.colors.neutrals.neutral600,
        },
    },
    overrides: {
        MuiPopover: {
            root: {
                // marginRight: "calc(-1 * (100vw - 100%))",
                // overflowX: "hidden",
            },
        },

        MuiFormControlLabel: {
            root: {
                "&.Mui-disabled": {
                    cursor: "not-allowed",
                },
            },
        },
    },
    typography: {
        fontFamily: "Gibson",
        fontSize: 16,
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
    },

    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiSwitch: {
            disableRipple: true,
        },
    },
});
