import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventCreationStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.resourcePagePadding,
        },

        backButton: {
            marginBottom: "10px",
        },

        header: {
            display: "flex",
            padding: "0.5rem 0",
            alignItems: "center",
            justifyContent: "space-between",

            "&.sticky": {
                borderBottom: "none",
                marginBottom: "0",
                padding: "0.5rem 0",
            },
        },

        headerText: {
            "&.sticky": {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },

        eventTypeSection: {
            padding: "2rem 0 0 0",
        },

        divider: {
            marginTop: "8px",
            marginBottom: "40px",
            backgroundColor: theme.colors.primary.blue100,
        },

        select: {
            maxWidth: "300px",
        },

        titleMargin: {
            marginBottom: "2rem",
        },

        autocompleteMargin: {
            marginBottom: "24px",
        },

        subtitle: {
            marginBottom: "24px",
        },

        rowPadding: {
            paddingBottom: "0.5rem",
        },

        locationBorder: {
            borderRight: `2px solid ${theme.colors.primary.blue100}`,
        },

        asterisk: {
            color: theme.colors.critical.critical500,
        },

        deviceSectionButton: {
            "&.MuiButton-root": {
                width: "100%",
                height: "100%",
                padding: "0.5rem 0rem",
                border: `1px solid ${theme.colors.primary.blue300}`,
                textTransform: "capitalize",

                "&:hover": {
                    backgroundColor: theme.colors.primary.blue100,
                },
            },
        },

        selectedButton: {
            backgroundColor: `${theme.colors.primary.blue200} !important`,
            border: `1px solid ${theme.colors.primary.blue300} !important`,
            "&:hover": {
                backgroundColor: `${theme.colors.primary.blue200} !important`,
                cursor: "default !important",
            },
        },

        leftButton: {
            borderRadius: "4px 0px 0px 4px !important",
        },

        rightButton: {
            borderRadius: "0px 4px 4px 0px !important",
        },

        triangleIcon: {
            fill: theme.colors.critical.critical600,
            marginRight: "0.25rem",
        },

        selectedTrd: {
            display: "flex",
            backgroundColor: theme.colors.primary.blue75,
            border: `1px solid ${theme.colors.primary.blue200}`,
            borderRadius: "4px",
            marginRight: "0.5rem",
            marginTop: "0.25rem",
        },

        selectedTrdIcon: {
            fill: theme.colors.neutrals.neutral500,
            marginLeft: "0.5rem",
            cursor: "pointer",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },
        },

        selectedTrdParagraph: {
            display: "flex",
            alignItems: "center",
        },

        trdSearch: {
            maxWidth: "25%",
            marginBottom: "1.25rem",
        },

        temperatureDataRow: {
            backgroundColor: theme.colors.primary.blue75,
            borderTop: `1px solid ${theme.colors.primary.blue100}`,
        },

        temperateDataGrid: {
            padding: "1rem 1rem 0.5rem 1rem",
        },

        smallPaddingTop: {
            paddingTop: "0.5rem",
        },

        smallPaddingLeft: {
            paddingLeft: "0.5rem",
        },

        smallPaddingRight: {
            paddingRight: "0.5rem",
        },

        temperatureDataSubtitle: {
            marginTop: "2.5rem",
            marginBottom: "1rem",
        },

        subTitleMargin: {
            marginBottom: "2rem",
        },

        commentsGrid: {
            maxWidth: "800px",
        },

        labelMargin: {
            marginBottom: "0.25rem",
        },

        transportationConditionsContainer: {
            display: "flex",
            gap: "0.625rem",

            "& > div": {
                display: "flex",
                gap: "0.25rem",

                "& > .MuiFormControl-root": {
                    width: "5rem",
                },

                "& > span": {
                    paddingTop: "0.5rem",
                },
            },
        },

        batchCard: {
            borderRadius: "4px",
            padding: "1rem 1.5rem 0.5rem",
        },

        batchOption: {
            "& #add-new-batch-option": {
                margin: "auto",
            },
        },

        batchNumberErrorColor: {
            color: theme.colors.critical.critical600,
        },

        batchNumberDefaultColor: {
            color: theme.colors.neutrals.neutral400,
        },

        fieldSetStyles: {
            border: "none",
            padding: "0",
        },

        displayFlex: {
            display: "flex",
        },

        flexGapSmall: {
            gap: "0.5rem",
        },

        flexGapMedium: {
            gap: "1.5rem",
        },

        textAlignEnd: {
            textAlign: "end",
        },

        border: {
            border: `1px solid ${theme.colors.primary.blue100}`,
        },

        borderBottom: {
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
        },

        marginBottomMedium: {
            marginBottom: "1rem",
        },

        marginTopMiddle: {
            marginTop: "1rem",
        },

        marginTopSmall: {
            marginTop: "0.5rem",
        },

        marginLeftSmall: {
            marginLeft: "0.5rem",
        },

        paddingBottomZero: {
            paddingBottom: "0",
        },

        stabilityFormDropdownContainer: {
            position: "relative",

            "& > svg": {
                position: "absolute",
                top: "2.8rem",
                left: "-0.5rem",
                width: "1.5rem",
                height: "1.5rem",
                fill: theme.colors.warning.warning600,
            },
        },

        icon: {
            fill: theme.colors.neutrals.neutral400,
            marginLeft: "0.5rem",
            width: "0.875rem",
            height: "0.875rem",
        },

        tableContentRow: {
            "&.MuiTableRow-root": {
                verticalAlign: "top",

                "&:nth-child(odd)": {
                    backgroundColor: theme.colors.primary.blue75,
                },
            },
        },

        verticalAlignmentBaseLineForTableRow: {
            "&.MuiTableRow-root": {
                verticalAlign: "baseline",
            },
        },

        marginTopRsbAdjustment: {
            marginTop: "8px",
        },
    })
);
