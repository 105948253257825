import { Theme, createStyles, makeStyles } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { ReactNode } from "react";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            gap: "1.5rem",
            flexDirection: "column",
        },

        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
        },
    })
);

export interface AssessmentDetailsSectionProps {
    title: string;
    children: ReactNode;
    titleRightSide?: ReactNode;
}

export const AssessmentDetailsSection = (props: AssessmentDetailsSectionProps) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <LynxTypography variant="h3" color="neutral400" component="h3">
                    {props.title}
                </LynxTypography>
                {props.titleRightSide}
            </div>
            <div>{props.children}</div>
        </div>
    );
};
