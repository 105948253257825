import LynxTypography, { LynxTypographyProps } from "./LynxTypography";

export type StrongProps = Omit<LynxTypographyProps, "component">;

const Strong = (props: StrongProps) => {
    const { variant = "body-sbold", ...rest } = { ...props };

    return <LynxTypography component="strong" variant={variant} {...rest} />;
};

export default Strong;
