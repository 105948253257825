import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const LynxAssessmentCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.colors.primary.blue100}`,
            padding: "0.5rem 1rem 1rem 1rem",
            borderRadius: theme.borderRadius,
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
        },

        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0.5rem",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
        },

        userInfoSection: {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
        },

        tableHeaderCell: {
            padding: "0rem 2rem 0.25rem 2rem",
            borderBottom: "none",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },

        tableRowCell: {
            padding: "0.5rem 2rem",
            borderBottom: "none",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },

        justificationContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
            paddingBottom: "0.5rem",
        },

        notSafeToUse: {
            color: theme.colors.critical.critical600,
        },

        safeToUse: {
            color: theme.colors.success.success600,
        },
    })
);
