import { RangeOperatorTo, RangeOperatorFrom } from "models/productAndStabilityForm/productAndStabilityFormModels";

export function temperatureOperatorsParser(value: RangeOperatorFrom | RangeOperatorTo) {
    switch (value) {
        case RangeOperatorTo.LESS_THAN:
            return "<";
        case RangeOperatorTo.LESS_THAN_OR_EQUAL:
            return "<=";
        case RangeOperatorTo.INFINITY:
            return "∞";
        case RangeOperatorFrom.GREATER_THAN:
            return ">";
        case RangeOperatorFrom.GREATER_THAN_OR_EQUAL:
            return ">=";
        case RangeOperatorFrom.INFINITY:
            return "∞";
    }
}

export const getRangeDisplay = (lowerLimit: string, upperLimit: string) =>
    `${lowerLimit.trim() === "" ? "-∞" : `${lowerLimit}°C`}    to    ${
        upperLimit.trim() === "" ? "∞" : `${upperLimit}°C`
    }`;
