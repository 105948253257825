import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            "&.MuiMenuItem-root": {
                padding: "0.5rem 1rem",
                minWidth: "8rem",
                "&:first-child": {
                    borderTop: "none",
                },
                "&:last-child": {
                    borderTop: "none",
                    borderBottom: "none",
                },
                borderTop: `1px solid ${theme.colors.primary.blue100}`,
                borderBottom: `1px solid ${theme.colors.primary.blue100}`,
                "&:hover": {
                    backgroundColor: theme.colors.primary.blue75,
                    color: theme.colors.primary.blue500,
                },
            },
        },
        list: {
            "&.MuiMenu-list": {
                border: `1px solid ${theme.colors.primary.blue100}`,
                borderRadius: "0.25rem",
                marginTop: "0.5rem",
            },
            "&.MuiList-padding": {
                paddingTop: "0rem",
                paddingBottom: "0rem",
            },
        },
    })
);
