import { useEffect } from "react";
import { Outlet } from "react-router";
import { useStore } from "store/StoreConfigs";

const CustomerSectionWrapper = () => {
    const { customerStore } = useStore();

    const clearCustomersPagination = () => {
        customerStore.clearPagination();
    };

    useEffect(() => {
        return () => {
            clearCustomersPagination();
        };
    }, []);

    return <Outlet />;
};

export default CustomerSectionWrapper;
