import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import GroupAuditPage from "../AuditTrail/GroupAuditPage";
import { AddGroupForm } from "../forms/AddGroupForm";
import { EditGroupForm } from "../forms/EditGroupForm";
import { GroupList } from "../GroupList/GroupList";

export default observer(function GroupsTab() {
    const { identityStore, permissionsStore, customerDataStore } = useStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const managePermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.manage)
        : permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id);

    const managePermissionRequired = pathname.includes("create");

    const accessDenied = managePermissionRequired && managePermission.status === MarvelPermissionStatus.Deny;

    const getRegions = () => {
        if (!identityStore.isSystemSpace && customerDataStore.regions.length === 0) {
            customerDataStore.loadRegions({ customerId: identityStore.currentCustomer.id });
        }
    };

    useEffect(() => {
        if (accessDenied) {
            navigate("");
            return;
        }
        getRegions();
    }, [managePermission]);

    if (managePermissionRequired && managePermission.status !== MarvelPermissionStatus.Allow) {
        return <FullScreenLoadingIndicator />;
    }

    return (
        <Routes>
            <Route index element={<GroupList />} />
            <Route path="create" element={<AddGroupForm />} />
            <Route path=":groupId" element={<EditGroupForm />} />
            <Route path=":groupId/audit" element={<GroupAuditPage />} />
            <Route path="*" element={<Navigate to="" />} />
        </Routes>
    );
});
