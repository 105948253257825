import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const filtersComponentStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            marginTop: "8px",
            height: "25px",
            backgroundColor: theme.colors.neutrals.neutral200,
        },
        rightButtonMargin: {
            marginLeft: "10px",
        },
        filtersMargin: {
            margin: "1.5rem 0px 0.25rem 0px",
        },
        buttonMargin: {
            marginLeft: "1rem",
        },
    })
);
