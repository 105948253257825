import { Grid } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { FieldArray, useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import {
    RangeOperatorFrom,
    RangeOperatorTo,
    StabilityFormModel,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import uuid from "react-uuid";
import { stabilityFormStyles } from "../StabilityFormStyles";
import TemperatureRangeFieldsArea from "./TemperatureRangeFieldsArea";

const TemperatureRangesArea = () => {
    const formik = useFormikContext<StabilityFormModel>();
    const classes = stabilityFormStyles();

    return (
        <SectionCard>
            <LynxTypography variant={"h2"} className={classes.marginBottomLarge}>
                Stability Temperature Ranges {`(${formik.values.temperatureRanges.length})`}
            </LynxTypography>
            <FieldArray
                name="temperatureRanges"
                render={(helpers) => (
                    <Grid container direction="column">
                        {formik.values.temperatureRanges.map((range, index) => (
                            <TemperatureRangeFieldsArea
                                key={range.id ? range.id : range.key}
                                rangeHelpers={helpers}
                                rangeIndex={index}
                            />
                        ))}

                        <Grid item className={classes.marginTopSmall}>
                            <LynxButton
                                variant="secondary"
                                onClick={() =>
                                    helpers.push({
                                        id: "",
                                        key: uuid(),
                                        lowerLimit: "",
                                        rangeOperatorFrom: RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                                        upperLimit: "",
                                        rangeOperatorTo: RangeOperatorTo.LESS_THAN_OR_EQUAL,
                                        references: "",
                                        totalStabilityBudget: "",
                                        remainingStabilityBudget: "",
                                        medicalInfo: "",
                                        rangeRegions: [],
                                    })
                                }
                                leftIcon={<LynxIcon name="plusSmall" />}
                            >
                                Add Temperature Range
                            </LynxButton>
                        </Grid>
                    </Grid>
                )}
            />
        </SectionCard>
    );
};

export default TemperatureRangesArea;
