export function getProductFullName(
    productName: string | null,
    productDosage?: string | number | null,
    productDoseForm?: string | null,
    productUnitOfMeasure?: string | null
) {
    return (
        (productName ?? "") +
        (productDosage ? ` ${productDosage}` : "") +
        (productUnitOfMeasure ? ` ${productUnitOfMeasure}` : "") +
        (productDoseForm ? ` (${productDoseForm})` : "")
    ).trim();
}
