import { Theme, createStyles, makeStyles } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "inline-flex",
            alignItems: "center",
            color: theme.colors.neutrals.neutral400,
            "& svg": {
                fill: "currentColor",
                marginRight: "8px",
                marginTop: "-2px",
            },
        },
    })
);

export interface InfoSubheaderProps {
    text: string;
    icon?: JSX.Element;
}

const InfoSubheader = (props: InfoSubheaderProps) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            {props.icon}
            <LynxTypography color="neutral400" variant="h3">
                {props.text}
            </LynxTypography>
        </div>
    );
};

export default InfoSubheader;
