import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const LynxInputWithTagsStyles = makeStyles((theme: Theme) =>
    createStyles({
        multipleInputRoot: {
            display: "flex",
            flexWrap: "wrap",
        },

        inputBackground: {
            backgroundColor: theme.colors.neutrals.white,

            "&.MuiInputBase-root.Mui-disabled": {
                backgroundColor: theme.colors.neutrals.neutral200,
            },
        },

        assistiveTextHidden: {
            opacity: "0",
        },

        chips: {
            display: "flex",
            backgroundColor: theme.colors.primary.blue75,
            border: `1px solid ${theme.colors.primary.blue200}`,
            borderRadius: theme.borderRadius,
            marginRight: "0.5rem",
            marginBottom: "0.25rem",
            padding: "0.25rem 0.25rem 0.25rem 0.5rem",
        },

        removeIcon: {
            marginLeft: "1rem",
        },

        chipsDisplayName: {
            display: "flex",
            alignItems: "center",
        },
    })
);
