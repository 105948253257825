import { isGenericApiError, isValidationProblemDetailsError } from "./typeHelpers";

export const unknownErrorText = "Something went wrong, try reloading this page or contact your admin.";

export function handleErrors(response: any, setErrors: (errors: string[]) => void) {
    if (isValidationProblemDetailsError(response)) {
        setErrors(Object.entries(response.data.errors).flatMap((x) => x[1]));
    } else if (isGenericApiError(response)) {
        setErrors([response.data.errorMessage]);
    } else {
        setErrors([unknownErrorText]);
    }
}
