import { lynxAvatarStyles } from "./LynxAvatarStyles";
import { customValues } from "styling/CustomThemeValues";
import Avatar from "react-avatar";

export interface LynxAvatarProps {
    firstName: string | null;
    lastName?: string | null;
    // in rem
    size?: number;
    // font size is hardcoded in rem, text scaling of react-avatar (textSizeRatio / textMarginRatio) will not work
    // text scaling is bugged inside MaterialUI popups and tooltips
    fontSize?: number;
}

export const LynxAvatar = ({ firstName, lastName, size = 2, fontSize = 0.75 }: LynxAvatarProps) => {
    const classes = lynxAvatarStyles({ fontSize });
    const sizeWithoutBorders = size - 0.25;

    // take only 2 first non-empty words to avoid text overflow since text scaling is disabled
    const name = [firstName, lastName]
        .join(" ")
        .split(" ")
        .filter((x) => x)
        .filter((x, i) => i < 2)
        .join(" ");

    return (
        // border specified on the container because you can't setup border correctly on the avatar itself
        <div className={classes.container}>
            {/* available background colors are overriden through ConfigProvider in index.tsx */}
            <Avatar
                round={customValues.borderRadiusHalf}
                size={sizeWithoutBorders + "rem"}
                className={classes.avatar}
                name={name}
            />
        </div>
    );
};
