import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { DuplicateDevicesWarningModal } from "components/LynxComponents/DuplicateDevicesWarningModal/DuplicateDevicesWarningModal";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import LynxComments from "components/ReusableComponents/LynxComments/LynxComments";
import PreventLeavePageUnsavedData from "components/ReusableForms/helper-components/PreventLeavePageUnsavedData";
import { Form, Formik } from "formik";
import { getPermissionKey } from "helpers/permissionHelpers";
import { trimLeadingZeros } from "helpers/trimLeadingZeros";
import { isGuidValid } from "helpers/typeValidationHelpers";
import _ from "lodash";
import { observer } from "mobx-react";
import { EventType, ExcursionSource, ManualEventModel } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import { useStore } from "store/StoreConfigs";
import { ScrollToFirstFormFieldError } from "validation/HelperComponents/ScrollToFirstFormFieldError";
import SetApiValidationErrors from "validation/HelperComponents/SetApiValidationsErrors";
import { createEventValidationSchema } from "validation/ValidationSchemas/EventDetailsSchemas/createEventValidationSchema";
import AttachmentsSection from "./AttachmentsSection";
import { BatchesSection } from "./BatchesSection";
import { CreateEventAuthModal } from "./CreateEventAuthModal";
import { CreateEventSummaryModal } from "./CreateEventSummaryModal";
import { DeliveryInformationSection } from "./DeliveryInformationSection";
import DevicesSection from "./DevicesSection";
import { eventCreationStyles } from "./EventCreationStyles";
import { HeaderSection } from "./HeaderSection";
import TransportationInformationSection from "./TransportationInformationSection";
import { Grid } from "@material-ui/core";
import { DuplicatedDeviceEventsResponse } from "api/models/events/eventsApi";
import routes from "routes";
import { InnerEventDescriptionProps } from "../EventDescription/InnerEventDescriptionProps";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";

export const EventCreation = observer(() => {
    const classes = eventCreationStyles();
    const navigate = useNavigate();
    const { eventId } = useParams();

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [duplicateDeviceEvents, setDuplicateDeviceEvents] = useState<DuplicatedDeviceEventsResponse[]>([]);
    const [createEventModelState, setCreateEventModelState] = useState<ManualEventModel>({} as ManualEventModel);
    const [summaryEventCreationInfo, setSummaryEventCreationInfo] = useState<InnerEventDescriptionProps>();

    const { customerDataStore, identityStore, permissionsStore, thorEventViewStore, commonStore } = useStore();
    const validationSchema = createEventValidationSchema(thorEventViewStore.manualEventModelReadOnly.timezone);

    const createEventInitialValue = _.cloneDeep(thorEventViewStore.manualEventModelReadOnly);

    const isEventEditing = !!eventId;
    const isEventIdValid = isGuidValid(eventId);

    const createEventKey = getPermissionKey(actions.customer.tor.events.create, identityStore.currentCustomer.id);
    const editEventKey = getPermissionKey(`${actions.customer.tor.events.editManualEvent}::customer`, eventId);

    const createEventPermissionStatus = permissionsStore.getPermissionStatus(createEventKey);
    const editEventPermissionStatus = permissionsStore.getPermissionStatus(editEventKey);

    const waitingForEditPermissionCheck =
        editEventPermissionStatus === undefined || editEventPermissionStatus === MarvelPermissionStatus.Loading;

    const waitingForCreatePermissionCheck =
        createEventPermissionStatus === undefined || createEventPermissionStatus === MarvelPermissionStatus.Loading;

    useEffect(() => {
        if (!isEventEditing) {
            if (waitingForCreatePermissionCheck) {
                return;
            }

            if (createEventPermissionStatus === MarvelPermissionStatus.Deny) {
                navigate(identityStore.startPageAvailable);
            }
        }
    }, [createEventPermissionStatus]);

    useEffect(() => {
        if (isEventEditing) {
            if (!isEventIdValid) {
                return navigate(routes.pageNotFound);
            }

            if (waitingForEditPermissionCheck) {
                return;
            }

            if (editEventPermissionStatus === MarvelPermissionStatus.Deny) {
                navigate(identityStore.startPageAvailable);
            }
        }
    }, [editEventPermissionStatus]);

    useEffect(() => {
        customerDataStore.loadTransportationModes({ customerId: identityStore.currentCustomer.id });

        if (isEventIdValid && isEventEditing) {
            thorEventViewStore.getManualEventInformation(identityStore.currentCustomer.id, eventId);
        }

        return () => {
            commonStore.setShowGeneralErrorPageToFalse();
            thorEventViewStore.resetManualEventData();
            customerDataStore.resetBatches();
            customerDataStore.resetProducts();
        };
    }, []);

    useEffect(() => {
        customerDataStore.loadCustomerProductUnitOfMeasure({ customerId: identityStore.currentCustomer.id });
    }, []);

    const setSummaryModalData = (data: ManualEventModel) => {
        const excursion = thorEventViewStore.calculatedExcursion;
        const delivery = data.deliveryInformation;

        const summaryData: InnerEventDescriptionProps = {
            minTemperature: excursion.minTemperature,
            maxTemperature: excursion.maxTemperature,
            orderNumbers: delivery.orderNumbers,
            deliveryNumber: delivery.deliveryNumber ? trimLeadingZeros(delivery.deliveryNumber.trim()) : null,
            assessments: data.batches.map((x) => ({
                ...x,
                productDosage: x.dosage,
                productDoseFormName: x.doseFormName,
                productDosageUomName: x.unitOfMeasureName,
            })),
            totalExcursionDuration: excursion.totalDuration,
            totalLowExcursionDuration: excursion.totalLowDuration,
            totalHighExcursionDuration: excursion.totalHighDuration,
            isSiteEvent: data.type === EventType.Site,
            handlingUnits: [],
            manualExcursionDeviceIds: data.manualExcursionDeviceIds,
        };

        setSummaryEventCreationInfo(summaryData);
    };

    const submitHandler = async (values: ManualEventModel) => {
        const castValues = validationSchema.cast(values);
        setCreateEventModelState(castValues as unknown as ManualEventModel);

        if (castValues.excursionSource === ExcursionSource.Manual) {
            setSummaryModalData(values);
            setIsSummaryModalOpen(true);
            return;
        }

        const duplicateDeviceEvents = await thorEventViewStore.getDuplicatesDeviceEvents(
            identityStore.currentCustomer.id,
            eventId,
            values.devices.map((x) => x.id)
        );

        if (duplicateDeviceEvents?.length) {
            setDuplicateDeviceEvents(duplicateDeviceEvents);
            setIsWarningModalOpen(true);
        } else {
            setSummaryModalData(values);
            setIsSummaryModalOpen(true);
        }
    };

    return (
        <main className={classes.root}>
            <Helmet>
                <title>
                    {isEventEditing
                        ? `Edit Event #${thorEventViewStore.eventDetails.displayId}`
                        : "Create Manual Event"}
                </title>
            </Helmet>

            <LynxBackButton
                path={isEventEditing ? routes.eventDetails.to(eventId!) : routes.events}
                title={isEventEditing ? "Event Details" : "Events List"}
            />

            {isEventEditing && thorEventViewStore.progressFlags.loadManualEventInformation ? (
                <FullScreenLoadingIndicator />
            ) : (
                <>
                    {commonStore.showGeneralErrorPage ? (
                        <GeneralErrorPage />
                    ) : (
                        <Formik
                            initialValues={createEventInitialValue}
                            validationSchema={validationSchema}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            // formik sets isSubmitting to false if submit function returns a Promise
                            // so it's important to NOT use arrow function here
                            onSubmit={(values) => {
                                submitHandler(values);
                            }}
                        >
                            <Form id="createEvent">
                                {thorEventViewStore.progressFlags.checkingForDuplicatedDevices && (
                                    <FullScreenLoadingIndicator />
                                )}
                                <PreventLeavePageUnsavedData />
                                <ScrollToFirstFormFieldError<ManualEventModel> />
                                <SetApiValidationErrors storeErrors={thorEventViewStore.createEventErrors} />

                                <div
                                    onKeyDown={(e: any) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    <HeaderSection isEventEditing={isEventEditing} />

                                    <DeliveryInformationSection />

                                    <TransportationInformationSection />

                                    <BatchesSection />

                                    <DevicesSection isEventEditing={isEventEditing} />

                                    <SectionCard id="event_comments_and_attachments_section">
                                        <LynxTypography variant="h2" className={classes.subTitleMargin}>
                                            Comments & Attachments
                                        </LynxTypography>
                                        <Grid container justifyContent="space-between">
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                xs={6}
                                                className={classes.commentsGrid}
                                            >
                                                <LynxComments
                                                    rootEntityId={eventId}
                                                    notShowEmptyStateIfNoComments={true}
                                                    rootEntityEditModeEnabled={true}
                                                    timezone={thorEventViewStore.manualEventModelReadOnly.timezone}
                                                />
                                            </Grid>
                                            <Grid item container direction="column" xs={4}>
                                                <AttachmentsSection />
                                            </Grid>
                                        </Grid>
                                    </SectionCard>
                                </div>

                                {isSummaryModalOpen && summaryEventCreationInfo && (
                                    <CreateEventSummaryModal
                                        open={isSummaryModalOpen}
                                        onClose={() => {
                                            setIsAuthModalOpen(false);
                                            setIsSummaryModalOpen(false);
                                        }}
                                        onConfirm={() => {
                                            setIsAuthModalOpen(true);
                                            setIsSummaryModalOpen(false);
                                        }}
                                        eventSummaryData={summaryEventCreationInfo}
                                        isEventEditing={isEventEditing}
                                    />
                                )}

                                <CreateEventAuthModal
                                    isModalOpen={isAuthModalOpen}
                                    setIsModalOpen={setIsAuthModalOpen}
                                    manualEventModel={createEventModelState}
                                />

                                <DuplicateDevicesWarningModal
                                    open={isWarningModalOpen}
                                    isEventEditing={isEventEditing}
                                    onClose={() => {
                                        setIsWarningModalOpen(false);
                                        setIsAuthModalOpen(false);
                                    }}
                                    onConfirm={() => {
                                        setIsWarningModalOpen(false);
                                        setSummaryModalData(createEventModelState);
                                        setIsSummaryModalOpen(true);
                                    }}
                                    duplicateDeviceEvents={duplicateDeviceEvents}
                                />
                            </Form>
                        </Formik>
                    )}
                </>
            )}
        </main>
    );
});
