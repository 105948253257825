const eventSearchParameters = [
    { label: "Event ID", value: "DisplayId" },
    { label: "Delivery ID", value: "DeliveryId" },
    { label: "Product Name", value: "ProductName" },
    { label: "Batch ID", value: "BatchId" },
    { label: "Site ID", value: "SiteId" },
    { label: "Site Name", value: "SiteName" },
] as const;

const productSearchParameters = [
    { label: "Product Name", value: "ProductName" },
    { label: "Form #", value: "FormNumber" },
] as const;

const userSearchParameters = [
    { value: "Id" },
    { value: "FullName" },
    { value: "Status" },
    { value: "GroupName" },
] as const;

const groupSearchParameters = [{ value: "GroupName" }, { value: "Role" }, { value: "Status" }] as const;

export const searchParameters = {
    event: eventSearchParameters,
    product: productSearchParameters,
    user: userSearchParameters,
    group: groupSearchParameters,
} as const;
