import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { LynxIcon } from "icons/LynxIcon";
import { errorStyles } from "./ErrorStyles";

const GeneralErrorPage = ({ sectionStyles }: { sectionStyles?: string }) => {
    const classes = errorStyles();

    return (
        <SectionCard className={clsx(classes.sectionCard, sectionStyles)}>
            <LynxIcon name="error" />
            <Typography className={classes.marginTop}>Ooops! Something went wrong.</Typography>
            <Typography>Try reloading this page or contact your admin.</Typography>
        </SectionCard>
    );
};

export default GeneralErrorPage;
