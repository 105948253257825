import { apiRoutes } from "api/apiRoutes";
import * as stabilityFormApi from "api/models/stabilityForms/stabilityFormsApi";
import { torAxios } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";
import {
    StabilityFormListItem,
    StabilityFormChangesHistory,
} from "models/productAndStabilityForm/productAndStabilityFormModels";

export class StabilityFormsService {
    getFormDownloadPresignedUrl = async (request: stabilityFormApi.BaseStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.getFormDownloadPresignedUrl(request.customerId, request.stabilityFormId);
        const result = await torAxios.get<string>(url);
        return result;
    };

    listStabilityForms = async (request: stabilityFormApi.ListStabilityFormsRequest) => {
        const url = apiRoutes.stabilityForms.listStabilityForms(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                formOwners: request.formOwners,
                searchValue: request.searchValue,
                searchParameter: request.searchParameter,
            },
        };

        const result = await torAxios.get<Page<StabilityFormListItem>>(url, requestConfig);
        return result;
    };

    createStabilityForm = async (customerId: string, request: stabilityFormApi.CreateStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.createStabilityForm(customerId);

        return await torAxios.post<stabilityFormApi.StabilityFormDetailsResponse>(url, request);
    };

    getStabilityFormDetails = async (customerId: string, stabilityFormId: string) => {
        const url = apiRoutes.stabilityForms.getStabilityFormDetails(customerId, stabilityFormId);

        return await torAxios.get<stabilityFormApi.StabilityFormResponse>(url);
    };

    getStabilityFormForEditing = async (customerId: string, stabilityFormId: string) => {
        const url = apiRoutes.stabilityForms.getStabilityFormForEditing(customerId, stabilityFormId);

        return await torAxios.get<stabilityFormApi.StabilityFormResponse>(url);
    };

    editStabilityForm = async (
        customerId: string,
        stabilityFormId: string,
        request: stabilityFormApi.EditStabilityFormRequest
    ) => {
        const url = apiRoutes.stabilityForms.editStabilityForm(customerId, stabilityFormId);

        return await torAxios.patch<stabilityFormApi.StabilityFormDetailsResponse>(url, request);
    };

    getStabilityFormChangesHistory = async (request: stabilityFormApi.GetStabilityFormChangesHistoryRequest) => {
        const url = apiRoutes.stabilityForms.getStabilityFormChangesHistory(
            request.customerId,
            request.stabilityFormId
        );

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
            },
        };

        const result = await torAxios.get<Page<StabilityFormChangesHistory>>(url, requestConfig);
        return result;
    };

    generatePdf = async (request: stabilityFormApi.BaseStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.generatePdf(request.customerId, request.stabilityFormId);

        const requestConfig: AxiosRequestConfig = {
            params: { currentTimezone: request.currentTimezone },
            responseType: "blob",
        };

        const result = await torAxios.get(url, requestConfig);

        return result;
    };
}
