import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import React from "react";
import { toast } from "react-toastify";
import { useStore } from "store/StoreConfigs";
import { dismissPopupsButtonStyles } from "./DismissPopupsButtonStyles";

export function DismissPopupsButton() {
    const classes = dismissPopupsButtonStyles();
    const { commonStore } = useStore();

    const dismissAll = () => {
        toast.clearWaitingQueue();
        toast.dismiss();
        commonStore.clearPopupsCount();
    };

    return (
        <div className={classes.container}>
            <LynxButton onClick={dismissAll} variant="secondary" className={classes.dismissBtnBoxShadow}>
                Dismiss all
            </LynxButton>
        </div>
    );
}
