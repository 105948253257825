import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const copyrightStyles = makeStyles((theme: Theme) =>
    createStyles({
        firstLine: {
            marginBottom: "0.75rem",
        },

        paper: {
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "52rem",
            marginTop: "3rem",
            padding: "2.5rem",
            borderRadius: "0",
            boxShadow: theme.mainShadow,
        },

        divider: { backgroundColor: theme.colors.primary.blue100, marginTop: "0.5rem", marginBottom: "2rem" },
    })
);
