import { List, ListItem } from "@material-ui/core";
import clsx from "clsx";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { observer } from "mobx-react";
import { useState, useEffect } from "react";
import { UserItem, UserListProps } from "./UserListProps";
import { userListStyles } from "./UserListStyles";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

export const UserList = observer((props: UserListProps) => {
    const classes = userListStyles();
    const [selectedUsers, setSelectedUsers] = useState<UserItem[]>(props.selectedUsers ? props.selectedUsers : []);

    useEffect(() => {
        setSelectedUsers(props.selectedUsers ? props.selectedUsers : []);
    }, [props.selectedUsers]);

    const handleToggle = (user: UserItem) => () => {
        const currentIndex = selectedUsers.findIndex((x) => x.id === user.id);
        const newSelectedUsers = [...selectedUsers];

        if (currentIndex === -1) {
            newSelectedUsers.push(user);
        } else {
            newSelectedUsers.splice(currentIndex, 1);
        }

        setSelectedUsers(newSelectedUsers);
        props.onSelectionChange(newSelectedUsers);
    };

    return (
        <div className={clsx(classes.container, props.className)}>
            <List className={classes.list}>
                {props.users.map((x) => {
                    return (
                        <ListItem
                            key={x.id}
                            button
                            disabled={props.disabled || x.disabled}
                            onClick={handleToggle(x)}
                            className={classes.listItem}
                        >
                            <div className={classes.userInfo}>
                                <LynxAvatar firstName={x.firstName} lastName={x.lastName} />
                                <LynxTypography variant="body-s">
                                    {[x.title, x.firstName, x.lastName].filter(Boolean).join(" ")}
                                    <br />
                                    {x.id}
                                </LynxTypography>
                            </div>
                            <LynxCheckBox
                                className={classes.userCheckBox}
                                checked={!!selectedUsers.find((user) => user.id === x.id)}
                                disabled={props.disabled || x.disabled}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
});
