import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { services } from "api/serviceConfig";
import { getPermissionKey } from "helpers/permissionHelpers";
import _ from "lodash";
import { makeAutoObservable } from "mobx";
import {
    MarvelPermissionStatus,
    PermissionRequest,
    PermissionResult,
} from "models/userManagement/userManagementModels";

export class PermissionsStore {
    private evaluatedPermissions: Map<string, MarvelPermissionStatus> = new Map<string, MarvelPermissionStatus>();

    constructor() {
        makeAutoObservable(this);
    }

    hasPermission = (...keys: string[]): boolean => {
        const result = keys.some((x) => {
            const status = this.evaluatedPermissions.get(x);
            return status === MarvelPermissionStatus.Allow;
        });

        return result;
    };

    permissionLoading = (...keys: string[]) => {
        const someAllowed = this.hasPermission(...keys);

        const someLoading = keys.some((x) => {
            const status = this.evaluatedPermissions.get(x);
            return status === MarvelPermissionStatus.Loading;
        });

        return someLoading && !someAllowed;
    };

    getPermissionStatus = (key: string) => {
        const status = this.evaluatedPermissions.get(key);
        return status;
    };

    getPermissionResult = (action: string, prefix?: string): PermissionResult => {
        const key = getPermissionKey(action, prefix);
        const status = this.evaluatedPermissions.get(key);
        return { key, status };
    };

    private shouldLoad = (
        request: PermissionRequest,
        status: MarvelPermissionStatus | undefined
    ): status is undefined => {
        return (request.invalidate || status === undefined) && status !== MarvelPermissionStatus.Loading;
    };

    evaluate = async (request: PermissionRequest): Promise<PermissionResult> => {
        let status = this.evaluatedPermissions.get(request.key);

        if (this.shouldLoad(request, status)) {
            this.evaluatedPermissions.set(request.key, MarvelPermissionStatus.Loading);
            const apiRequest: userManagementApi.EvaluatePermissionsRequest = {
                permissions: [request.body],
            };

            const response = await services.Permissions.evaluatePermissions(apiRequest);

            if (!_.inRange(response.status, 200, 300)) return { key: request.key, status: undefined };

            status = response.data[0].allow ? MarvelPermissionStatus.Allow : MarvelPermissionStatus.Deny;
            this.evaluatedPermissions.set(request.key, status);
        }

        return { key: request.key, status };
    };

    bulkEvaluate = async (requests: PermissionRequest[]): Promise<PermissionResult[]> => {
        const withStatus = requests.map((x) => ({ request: x, status: this.evaluatedPermissions.get(x.key) }));
        const toLoad = withStatus.filter((x) => this.shouldLoad(x.request, x.status));

        if (toLoad.length !== 0) {
            toLoad.forEach((x) => this.evaluatedPermissions.set(x.request.key, MarvelPermissionStatus.Loading));

            const apiRequest: userManagementApi.EvaluatePermissionsRequest = {
                permissions: toLoad.map((x) => x.request.body),
            };

            const response = await services.Permissions.evaluatePermissions(apiRequest);

            if (!_.inRange(response.status, 200, 300)) return requests.map((x) => ({ key: x.key, status: undefined }));

            // Assume order of permissions in response is the same as in request
            response.data.forEach((x, index) => {
                this.evaluatedPermissions.set(
                    toLoad[index].request.key,
                    x.allow ? MarvelPermissionStatus.Allow : MarvelPermissionStatus.Deny
                );
            });
        }

        return requests.map((x) => {
            const status = this.evaluatedPermissions.get(x.key);

            if (status === undefined) {
                throw new Error("bulkEvaluate: bug, status should not be undefined.");
            }

            return { key: x.key, status };
        });
    };
}
