import { PopupNotification } from "components/PopupNotification/PopupNotification";
import { NotificationType } from "models/shared/NotificationType";
import { ReactNode } from "react";
import { Id, toast } from "react-toastify";

export const displayPopupNotification = (
    type: NotificationType,
    textContent: ReactNode,
    title?: string,
    // Use customId to prevent duplicate popups, popup will be updated instead
    customId?: Id
) => {
    let id: Id = "";

    const handlePopupClose = () => {
        toast.dismiss(id);
    };

    const notification = (
        <PopupNotification type={type} title={title} textContent={textContent} closeCallback={handlePopupClose} />
    );

    if (customId && toast.isActive(customId)) {
        toast.update(customId, { render: notification });
    } else {
        id = toast(notification, { toastId: customId });
    }
};
