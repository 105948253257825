import { LynxRecommendationIcon } from "components/LynxRecommendationIcon";
import { getAssessmentDecisionText } from "helpers/getRecommendationText";
import { LynxIcon } from "icons/LynxIcon";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { AutomatedAssessmentInfoCard } from "./AutomatedAssessmentInfoCard";
import { AutomatedAssessmentProps } from "./AutomatedAssessmentProps";

export const AutomatedAssessment = ({ decision, messages }: AutomatedAssessmentProps) => {
    const classes = assessmentDetailsStyles();

    const primaryCardMessages: string[] = [];
    messages?.expirationDate && primaryCardMessages.push(messages.expirationDate);
    messages?.freezeHeatCycles && primaryCardMessages.push(messages.freezeHeatCycles);
    messages?.transportationModeScenario && primaryCardMessages.push(messages.transportationModeScenario);
    messages?.excursion && primaryCardMessages.push(messages.excursion);

    if (decision === null) {
        return null;
    }

    return (
        <AssessmentDetailsSection title="Automated Assessment">
            <div className={classes.automatedAssessmentCardsContainer}>
                <AutomatedAssessmentInfoCard
                    icon={<LynxRecommendationIcon recomendation={decision} />}
                    title={getAssessmentDecisionText(decision)}
                    messages={primaryCardMessages}
                />
                {!!messages?.transportationModeAllowanceRules && (
                    <AutomatedAssessmentInfoCard
                        icon={<LynxIcon name="infoS" className={classes.greyIcon} />}
                        messages={[messages.transportationModeAllowanceRules]}
                    />
                )}
            </div>
        </AssessmentDetailsSection>
    );
};
