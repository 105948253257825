import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const loadingIndicatorStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullScreenContainer: {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        fullScreenIndicator: {
            width: "15%",
        },
        searchIndicatorContainer: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        searchIndicator: {
            position: "relative",
            height: "16rem",
            width: "16rem",
        },
        centeredIndicatorContainer: {
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        centeredIndicator: {
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            height: "16rem",
            width: "16rem",
        },
    })
);
