import { FormControl, FormControlClassKey, FormLabel, MenuItem } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import { FieldHookConfig, useField } from "formik";
import { ChangeEvent } from "react";
import { LynxSelectFormProps } from "./Props/LynxSelectFormProps";
import { reusableFormsStyles } from "./ReusableFormsStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";

const LynxSelectForm = (props: FieldHookConfig<string> & LynxSelectFormProps) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && !!meta.error;

    const { onChange, onBlur, value, ...fieldRest } = { ...field };
    const {
        size = "medium",
        error = hasError,
        label,
        placeholder,
        MenuProps,
        showGeneratedDefaultOption = false,
        automaticallyGenerateDefaultOption = true,
        assistiveTextClassName,
        className,
        LabelProps,
        FormControlProps,
        ...rest
    } = { ...props };

    const { classes: formControlClasses, ...formControlRest } = { ...FormControlProps };
    const { root: rootClassFormControl, ...formControlClassesRest } = { ...formControlClasses };

    const { className: labelClassName, ...labelRest } = { ...LabelProps };
    const { className: menuClassName, ...menuRest } = { ...MenuProps };

    const classes = reusableFormsStyles({ size, error });

    const labelClasses = clsx(classes.label, labelClassName);

    const formClasses: Partial<ClassNameMap<FormControlClassKey>> = {
        root: clsx(classes.selectFormControlRoot, rootClassFormControl),
        ...formControlClassesRest,
    };

    const assistiveTextClasses = clsx(assistiveTextClassName, !hasError && classes.assistiveTextHidden);

    const selectClasses = clsx(classes.selectRoot, className);
    const selectMenuClasses = clsx(classes.selectMenu, menuClassName);

    const handleChange = (e: ChangeEvent<any>) => {
        onChange(e);

        !!meta.error && helpers.setError(undefined);
    };

    const placeholderValue = props.placeholderValue || "";

    const renderMenuItems = () => {
        const array = props.children as [];

        if (!automaticallyGenerateDefaultOption) {
            return array;
        }

        return [
            showGeneratedDefaultOption ? (
                <MenuItem value={placeholderValue} key={placeholderValue} selected>
                    {placeholder}
                </MenuItem>
            ) : (
                <MenuItem value={placeholderValue} key={placeholderValue} selected disabled style={{ display: "None" }}>
                    {placeholder}
                </MenuItem>
            ),
            ...array,
        ];
    };

    return (
        <FormControl variant="outlined" classes={formClasses} {...formControlRest}>
            {label && (
                <FormLabel htmlFor={field.name} className={labelClasses} {...labelRest}>
                    <Span variant="body-s" color="neutral400">
                        {label}
                    </Span>
                </FormLabel>
            )}

            <LynxSelect
                className={selectClasses}
                displayEmpty
                onChange={props.onChange ?? handleChange}
                onBlur={props.onBlur ?? onBlur}
                error={hasError}
                MenuProps={{
                    className: selectMenuClasses,
                    ...menuRest,
                }}
                value={props.value ?? value}
                {...rest}
                {...fieldRest}
                placeholderValue={automaticallyGenerateDefaultOption ? placeholderValue : undefined}
            >
                {renderMenuItems()}
            </LynxSelect>
            <LynxTypography
                variant="body-s"
                className={assistiveTextClasses}
                color={error ? "critical500" : "neutral400"}
            >
                {props.assistiveText !== undefined ? props.assistiveText : meta.error || "Error"}
            </LynxTypography>
        </FormControl>
    );
};

export default LynxSelectForm;
