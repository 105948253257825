import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const thorTemperatureChartStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: "400px",
            maxHeight: "400px",
            marginBottom: "20px",
        },

        loadingIndicator: {
            width: 200,
            paddingTop: 30,
        },
        resetBtn: {
            float: "right",
            border: `1px solid  ${theme.colors.primary.blue500}`,
        },
        button: {
            marginTop: "0.8rem",
        },
        eyeIconsContainer: {
            position: "relative",
        },
        eyeIcon: {
            cursor: "pointer",
        },
        triangleWarningIcon: {
            width: "24px",
            height: "24px",
            fill: theme.colors.critical.critical600,
        },
        selectedMonitor: {
            backgroundColor: theme.colors.primary.blue75,
        },
        monitorLeftBorder: {
            position: "absolute",
            width: "1px",
            backgroundColor: theme.colors.primary.blue100,
            height: "60px",
            left: "0%",
        },
        selectedMonitorLeftBorder: {
            width: "3px",
            backgroundColor: theme.colors.primary.blue500,
        },
        devicesInfoRow: {
            borderRadius: "4px",
            paddingTop: "24px",
            paddingBottom: "24px",
            paddingRight: "24px",
            paddingLeft: "20px",
            marginTop: "20px",
            marginLeft: "30px",
            position: "relative",
        },
        displayFlex: {
            display: "flex",
            position: "relative",
            minWidth: "170px",
        },
        formLoadingIndicator: {
            height: "40px",
            width: "40px",
        },
        gridContainer: {
            margin: "10px",
        },
        marginRight: {
            marginRight: "1rem",
        },
        hide: {
            visibility: "hidden",
        },
        rotate: {
            rotate: "180deg",
        },

        infoContainer: {
            marginTop: "4rem",
        },

        monitorDetailsContainer: {
            display: "flex",
            alignItems: "center",
            gap: "0.625rem",
        },
    })
);
