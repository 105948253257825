import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { EventStatus } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import { useStore } from "store/StoreConfigs";

import { LynxIcon } from "icons/LynxIcon";
import EventRowStatus from "../utilityComponents/EventRowStatus";
import { EventInfoProps } from "./EventInfoProps";
import { eventInfoStyles } from "./EventInfoStyles";

export const EventInfo = observer((props: EventInfoProps) => {
    const classes = eventInfoStyles();
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();
    const navigate = useNavigate();

    const details = thorEventViewStore.eventDetails;

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const key = getPermissionKey(`${actions.customer.tor.events.editManualEvent}::event`, details.id);

    const editManualEventPermissionStatus = permissionsStore.getPermissionStatus(key);

    const isDisabledEditEventButton =
        editManualEventPermissionStatus !== MarvelPermissionStatus.Allow || !props.allAssessmentsArePendingReview;

    const getTooltipMessage = () => {
        const isCurrentUserIsReviever = details.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = details.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case !props.allAssessmentsArePendingReview:
                return promptTooltipMessages.event.reviewCompleted;
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;
            case editManualEventPermissionStatus !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    return (
        <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
            <Grid container className={withSticky(classes.root)}>
                <Grid item container xs={8} direction="row">
                    <Grid item container direction="column">
                        <LynxTypography
                            variant="body-s"
                            color="neutral400"
                            className={withSticky(classes.eventIdCaption)}
                        >
                            Event ID
                        </LynxTypography>
                    </Grid>
                    <Grid item>
                        <LynxTypography variant="h1" className={withSticky(classes.eventId)}>
                            {details.displayId}
                        </LynxTypography>
                    </Grid>
                    <Grid item className={withSticky(classes.idAndStatus)}>
                        <EventRowStatus status={details.status} />
                    </Grid>
                </Grid>
                <Grid item container xs={4} direction="column" className={classes.secondSection}>
                    <Grid item className={classes.buttons}>
                        {thorEventViewStore.eventDetails.handlingUnits.length === 0 && (
                            <PromptTooltip placement="top" title={getTooltipMessage()}>
                                <LynxButton
                                    variant="secondary"
                                    size="medium"
                                    disabled={isDisabledEditEventButton}
                                    loading={editManualEventPermissionStatus === MarvelPermissionStatus.Loading}
                                    onClick={() => {
                                        navigate(routes.eventsEdit.to(details.id));
                                    }}
                                    leftIcon={<LynxIcon name="edit" />}
                                >
                                    Edit this Event
                                </LynxButton>
                            </PromptTooltip>
                        )}
                    </Grid>
                </Grid>
                <Grid item container className={withSticky(classes.dates)}>
                    <Span variant="body-s" color="neutral400">
                        Event Created:&nbsp;
                        {convertDateToSelectedTimezone(
                            thorEventViewStore.eventDetails.createdAt,
                            thorEventViewStore.eventDetails.timezone
                        ).format(commonConstants.fullDateTimeFormat)}
                        &nbsp;
                    </Span>
                    {thorEventViewStore.eventDetails.createdBy &&
                        thorEventViewStore.eventDetails.createdBy !== commonConstants.system && (
                            <Span variant="body-s" color="neutral400">
                                Created By: {thorEventViewStore.eventDetails.createdBy}&nbsp;
                            </Span>
                        )}
                    {details.status === EventStatus.Closed && (
                        <Span variant="body-s" color="neutral400">
                            Event Completed:&nbsp;
                            {convertDateToSelectedTimezone(
                                thorEventViewStore.eventDetails.closedAt,
                                thorEventViewStore.eventDetails.timezone
                            ).format(commonConstants.fullDateTimeFormat)}
                        </Span>
                    )}
                </Grid>
            </Grid>
        </HeaderPortal>
    );
});
