import { commonConstants } from "lynxConstants";
import { convertDateToSelectedTimezone, dateInLocalTimezone } from "helpers/timezoneHelper";
import { ValidationConstants } from "validation/constants";
import { date, number, object } from "yup";

export const manualExcursionValidationSchema = (timezone: string) =>
    object({
        lowTemperature: number()
            .required()
            .min(ValidationConstants.minAllowedTemperature)
            .max(ValidationConstants.maxAllowedTemperature)
            .typeError("Must be a number")
            .test((value, ctx) => {
                const lower = Number(value);
                const upper = Number(ctx.parent.highTemperature);

                return isNaN(lower) || isNaN(upper) || lower < upper
                    ? true
                    : ctx.createError({ message: `Must be less than ${upper}` });
            })
            .label(""),

        highTemperature: number()
            .required()
            .min(ValidationConstants.minAllowedTemperature)
            .max(ValidationConstants.maxAllowedTemperature)
            .typeError("Must be a number"),

        startDateTime: date()
            .required()
            .typeError("Must be a valid date and time")
            .test((value, ctx) => {
                if (value && ctx.parent.endDateTime) {
                    const start = value;
                    const end = ctx.parent.endDateTime;

                    return start.valueOf() < end.valueOf()
                        ? true
                        : ctx.createError({
                              message: `Must be earlier than ${(timezone
                                  ? convertDateToSelectedTimezone(end, timezone)
                                  : dateInLocalTimezone(end)
                              ).format(commonConstants.dateTimeFormat)}`,
                          });
                }

                return true;
            }),

        endDateTime: date().required().typeError("Must be a valid date and time"),
    });
