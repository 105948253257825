import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventLevelInformationStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            marginBottom: "60px",
            marginTop: "40px",
        },

        picker: {
            width: "100%",

            "& input": {
                fontSize: "16px",
                lineHeight: "22.4px",
                color: theme.colors.neutrals.neutral600,
            },
            "& input:disabled, & input::placeholder": {
                color: theme.colors.neutrals.neutral500,
            },
        },

        bottomSpacing: {
            marginBottom: "10px",
        },

        textWithIcon: {
            marginLeft: "-0.25rem",
            gap: "0.5rem",
        },

        selectTypographyWithIcon: {
            display: "inline-flex",
            alignItems: "center",
            gap: "0.5rem",
        },

        gridItemMarginBottom: {
            marginBottom: "2rem",
        },

        inputPadding: {
            padding: "7px 12px 7px 12px",
        },

        selectSmallPaddingAndWidth: {
            width: "100%",

            "& .MuiSelect-root": {
                padding: "5px 35px 5px 12px",
            },
        },

        selectMediumPaddingAndWidth: {
            width: "100%",

            "& .MuiSelect-root": {
                height: "1.5rem",
                padding: "7px 35px 7px 12px",
            },
        },

        assignedUsersInfo: {
            display: "flex",
            gap: "0.5rem",
            // marginBottom: "1rem",
            flexDirection: "column",
        },

        userInfo: {
            padding: "0.3125rem 0rem",
        },

        eventCategoryChip: {
            padding: "0.5rem",
            border: `1px solid ${theme.colors.primary.blue200}`,
            backgroundColor: theme.colors.primary.blue75,
            borderRadius: "0.25rem",
            fontFamily: "Gibson",
            fontSize: "0.875rem",
            lineHeight: "0.875rem",
            letterSpacing: "0.06125rem",
            color: theme.colors.neutrals.neutral600,
            margin: "0.5rem 0rem 0rem 0.5rem",
        },

        checkboxLabel: {
            marginRight: "0rem",
        },

        timezoneContainer: {
            marginTop: "0.5rem",
        },

        timezoneSelectFullWidth: {
            width: "100%",
        },
    })
);
