export const envConstants = {
    REACT_APP_TOR_AXIOS_BASE_URL: process.env.REACT_APP_TOR_AXIOS_BASE_URL,

    REACT_APP_OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    REACT_APP_OKTA_REDIRECT_URL: process.env.REACT_APP_OKTA_REDIRECT_URL,

    REACT_APP_RESET_EVENT_FEATURE_FLAG: process.env.REACT_APP_RESET_EVENT_FEATURE_FLAG
        ? JSON.parse(process.env.REACT_APP_RESET_EVENT_FEATURE_FLAG)
        : false,
};
