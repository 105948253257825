import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const assignEventMenuModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteContainer: {
            padding: "0rem 1.25rem 0 1.25rem",
        },

        commentContainer: {
            padding: "1rem 1.25rem 0 1.25rem",
        },

        textArea: {
            minHeight: "9.5rem",
            maxHeight: "37.5rem",
            overflowY: "auto",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            borderRadius: `${theme.borderRadius}`,
            margin: "0.125rem 0rem 0.625rem 0rem",
        },
    })
);
