import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import Strong from "components/LynxComponents/LynxTypography/Strong";
import { formatDurationToString } from "helpers/formatDurationToString";

export interface EventTotalExcursionDurationDescriptionProps {
    totalDuration: number | null;
    totalLowDuration: number | null;
    totalHighDuration: number | null;
}

export const EventTotalExcursionDurationDescription = (props: EventTotalExcursionDurationDescriptionProps) => {
    const { totalDuration, totalHighDuration, totalLowDuration } = { ...props };

    const noTemperatureExcursion = <>There were no temperature excursions.</>;

    const totalExcursionDescription = (
        <>
            Total duration of excursion was <Strong>{formatDurationToString(totalDuration)}</Strong>.
        </>
    );

    const highExcursionText = "There was a high excursion with a duration of ";
    const lowExcursionText = "There was a low excursion with a duration of ";
    const lowExcursionTextAfter = "and a low excursion with a duration of ";

    const totalHighExcursionDescription = (
        <>
            {highExcursionText}
            <Strong>{formatDurationToString(totalHighDuration)}</Strong>.
        </>
    );

    const totalLowExcursionDescription = (
        <>
            {lowExcursionText}
            <Strong>{formatDurationToString(totalLowDuration)}</Strong>.
        </>
    );

    const totalHighAndLowExcursionDescription = (
        <>
            {highExcursionText}
            <Strong>{formatDurationToString(totalHighDuration)}</Strong> {lowExcursionTextAfter}
            <Strong>{formatDurationToString(totalLowDuration)}</Strong>.
        </>
    );

    let content = null;

    switch (true) {
        case !totalDuration || totalDuration <= 0:
            content = noTemperatureExcursion;
            break;
        case !!totalHighDuration && !!totalLowDuration:
            content = totalHighAndLowExcursionDescription;
            break;
        case !!totalHighDuration && !totalLowDuration:
            content = totalHighExcursionDescription;
            break;
        case !totalHighDuration && !!totalLowDuration:
            content = totalLowExcursionDescription;
            break;
        default:
            content = totalExcursionDescription;
            break;
    }

    return <LynxTypography>{content}</LynxTypography>;
};
