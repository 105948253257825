import { LynxAssessmentCard } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCard";
import { observer } from "mobx-react";
import React from "react";
import { ExcursionReviewProps } from "./ExcursionReviewProps";
import { AssessmentDecision, AssessmentStatus } from "models/thorEvents/eventModels";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { BatchHoursReduced } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCardProps";
import { areBatchHoursReduced } from "helpers/areBatchHoursReduced";

export const ExcursionReview = observer(({ assessment }: ExcursionReviewProps) => {
    const isAssessmentReviewCompleted =
        assessment.status === AssessmentStatus.PendingDecision ||
        assessment.status === AssessmentStatus.EvaluationCompleted;

    if (!isAssessmentReviewCompleted || assessment.recommendedDecision === null) {
        return null;
    }

    const decision = assessment.decision ?? assessment.recommendedDecision;
    const hoursReducedStatus = areBatchHoursReduced(decision, assessment.isWithFirstEconomicCustomer);

    const getInfoBadgeText = () => {
        const hoursReduced = hoursReducedStatus === BatchHoursReduced.Yes;

        switch (true) {
            case !hoursReduced && decision === AssessmentDecision.ExistingImpactOnProductQuality:
                return "Batch remaining hours will NOT be reduced based on this excursion, because the product has to be discarded.";
            case !hoursReduced && assessment.isWithFirstEconomicCustomer:
                return "Batch remaining hours WILL NOT be reduced based on this excursion, because the product is with the customer.";
            case hoursReduced:
                return "Batch remaining hours WILL be reduced based on this excursion, because the product is NOT with the customer.";
            default:
                return "";
        }
    };

    return (
        <AssessmentDetailsSection title="Excursion Review">
            <LynxAssessmentCard
                email={assessment.reviewerUserId}
                firstName={assessment.reviewerFirstName}
                lastName={assessment.reviewerLastName}
                action={"has completed the review"}
                actionDate={assessment.reviewedDate}
                decision={decision}
                isWithFirstEconomicCustomer={assessment.isWithFirstEconomicCustomer}
                batchHoursReduced={hoursReducedStatus}
                infoBadgeText={getInfoBadgeText()}
            />
        </AssessmentDetailsSection>
    );
});
