export const isGuidValid = (guid: string | undefined) => {
    if (!guid) {
        return false;
    }

    const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

    return regex.test(guid);
};

export const isNumber = (value: any) => {
    return !isNaN(parseFloat(value)) && !isNaN(value - 0);
};
