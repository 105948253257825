import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { store, StoreContext } from "store/StoreConfigs";
import { App } from "./App";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styling/index.css";
import "chartjs-adapter-luxon";
import { theme } from "./styling/theme";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import { setDefaultMessagesForValidation } from "validation/defaultErrorMessages";
import { ConfigProvider } from "react-avatar";
import { customAvatarColors } from "styling/CustomThemeValues";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en";

// use plugins
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en");

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

setDefaultMessagesForValidation();

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={store}>
            <ConfigProvider colors={customAvatarColors}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </ConfigProvider>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
