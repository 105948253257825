import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxScrollToTopButtonStyles } from "./LynxScrollToTopButtonStyles";
import { LynxIcon } from "icons/LynxIcon";
import { useScrollTrigger, Zoom } from "@material-ui/core";

export const LynxScrollToTopButton = () => {
    const classes = LynxScrollToTopButtonStyles();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 200,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Zoom in={trigger}>
            <div className={classes.buttonWithIcon}>
                <LynxButton size="medium" variant="icon" onClick={handleClick}>
                    <LynxIcon name="arrowUp" />
                </LynxButton>
            </div>
        </Zoom>
    );
};
