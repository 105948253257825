import { Chart, ChartArea, ChartEvent, Scale } from "chart.js";

export interface IPluginElement {
    containerChanged: ContainerChanged;
    getPolygon: GetPolygon;
    isInside: IsInside;
    drawElement: FillElement;
    uReChosenOneSet: UReChosenOneSet;
    uReChosenOneGet: UReChosenOneGet;
    getLeftBorder: GetBorder;
    getRightBorder: GetBorder;
    getElementCenter: GetElementCenter;
    getXValueByCoorinate: GetXValueByCoorinate;
    getSliderX: GetSliderValue;
    getSliderY: GetSliderValue;
    getWidth: GetSliderValue;
    getHeight: GetSliderValue;
    getX: GetSliderValue;
    getY: GetSliderValue;
    updateXPosition: UpdatePosition;
    updateYPosition: UpdatePosition;
    getSliderState: GetSliderState;
    restoreSliderState: RestoreSliderState;
    drawBorderImage(chart: Chart): void;
}

export type SelectCallback = (startPoint: any, endPoint: any) => void;
export type GetPolygon = () => Array<Array<number>>;
export type IsInside = (point: Point) => boolean;
export type FillElement = (chart: Chart) => void;
export type FillElementWithGrab = (chart: Chart, img: any) => void;
export type ContainerChanged = (chartArea: ChartArea, scaleX: Scale, scaleY: Scale) => void;
export type UReChosenOneSet = (val: boolean) => void;
export type UReChosenOneGet = () => boolean;
export type GetBorder = () => number;
export type GetElementCenter = () => Point;
export type GetXValueByCoorinate = (
    chartXMinValue: number,
    chartXMaxValue: number,
    selectedXCoord: number,
    chartXMaxCoord: number
) => number;
export type UpdatePosition = (value: number) => void;
export type GetSliderValue = () => number;
export type GetSliderState = () => SliderState;
export type RestoreSliderState = (sliderState: SliderState) => void;

export type SliderConditionChanged = (
    position: ConditionPosition,
    value: number,
    eventType?: ChartEvent["type"]
) => void;

export enum ConditionPosition {
    left = "left",
    right = "right",
}
export interface Point {
    x: number;
    y: number;
}

export interface SelectionInput {
    mouseup: number;
    mousedown: number;
}

export interface SelectInput {
    events: [string, string];
    selectCallback: SelectCallback;
}

export interface SliderState {
    sliderFixedBorderValue: number;
    sliderSlidingBorderValue: number;
}

export interface SlidersContext {
    sliders: Map<ConditionPosition, SliderState>;
}
