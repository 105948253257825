import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const userListStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: `1px solid ${theme.colors.neutrals.neutral200}`,
            borderRadius: "3px",
            padding: "8px 9px",
        },

        list: {
            maxHeight: "200px",
            overflowY: "auto",
        },

        listItem: {
            padding: "8px 15px",
            borderBottom: `2px solid ${theme.colors.neutrals.neutral200}`,
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",

            "&:first-child": {
                paddingTop: "0",
            },

            "&:last-child": {
                borderBottom: "none",
                paddingBottom: "0",
            },

            "&:hover": {
                backgroundColor: theme.colors.primary.blue75,
                color: theme.colors.primary.blue500,
            },
        },

        userCheckBox: {
            marginLeft: "auto",
        },

        userInfo: {
            display: "flex",
            gap: "0.5rem",
            fontSize: "14px",
            lineHeight: "18px",
        },
    })
);
