import { Chart, ChartEvent, ChartOptions, Plugin } from "chart.js";
import { ConditionPosition } from "./interfaces/pluginDeclarations";
import { CropTemperatureRangeContext } from "./crop-temperature-range-context";
import DataConditioning, {
    ConditioningPluginOptions,
} from "chartjs-plugins/crop-temperature-range/crop-temperature-range-augmentation";
import { AfterEventArgs, BeforeEventArgs } from "./interfaces/pluginEventArgs";
import { AnyObject } from "chart.js/dist/types/basic";

// https://www.chartjs.org/docs/2.9.4/developers/plugins.html

export class CropTemperatureRange implements DataConditioning {
    public id = "conditioning";

    private isModeEnabled: boolean = false;
    private pluginState = new WeakMap();
    private disabledPlugins = new Map<string, Plugin>();

    private context!: CropTemperatureRangeContext;

    // TODO: check options Proxy object, why it does not have information.

    public start(chart: Chart): void {
        this.setState(chart);
        const conditioning = chart.options.plugins?.conditioning as ConditioningPluginOptions;
        this.context = new CropTemperatureRangeContext(conditioning);
        this.context.onSliderValueChanged = this.onSliderValueChanged;
        chart.enableConditioning = this.enableConditioning;
        chart.disableConditioning = this.disableConditioning;
        chart.applyConditioningChanges = this.applyConditioningChanges;
        chart.setConditioningValue = this.setConditioningValue;
        chart.isUndoDisabled = this.isUndoDisabled;
        chart.isRedoDisabled = this.isRedoDisabled;
        chart.isApplyChangesDisabled = this.isApplyChangesDisabled;
        chart.undo = this.undo;
        chart.redo = this.redo;
        chart.isConditioningEnabled = this.isConditioningEnabled;
    }

    public afterInit(chart: Chart, args: any, options: any): void {}

    public beforeInit(chart: Chart, options: any): void {
        this.context.initiate();
    }

    public beforeDraw(chart: Chart, args: any, options: ChartOptions): void {
        this.context.drawElements(chart);
    }

    public afterDraw(chart: Chart, args: any, options: ChartOptions): void {
        this.context.drawElementsBorder(chart);
    }

    public afterLayout(chart: Chart, args: any, options: any): void {
        const chartArea = chart.chartArea;
        const chartOptions = chart.options.plugins?.conditioning as ConditioningPluginOptions;
        this.context.updateLayout(chart, chartArea, chartOptions);
    }

    public beforeEvent(chart: Chart, args: BeforeEventArgs, options: any) {
        if (!this.isModeEnabled) {
            return;
        }
        if (args.event.type === "mousedown") {
            this.context.mouseDown(chart, args);
        }
        if (args.event.type === "mouseup") {
            this.context.mouseUp(chart, args);
            chart.render();
        }
    }

    public afterEvent(chart: Chart, args: AfterEventArgs, options: any): void {
        if (!this.isModeEnabled) {
            return;
        }
        if (args.event.type === "mousemove") {
            this.context.mouseMove(chart, args);
        }
    }

    public stop(chart: Chart) {
        this.enablePlugins(chart);
    }

    private disablePlugins(chart: Chart): void {
        chart.resetZoom();
        const plugins = chart.options.plugins?.conditioning?.disablePluginsWhenModeOn as Array<string>;
        if (plugins && plugins.length > 0) {
            plugins.forEach((pluginId) => {
                const plugin = Chart.registry.getPlugin(pluginId);
                if (plugin) {
                    if (plugin.start && plugin.stop) {
                        const pluginsOptions = chart.options.plugins as any;
                        const pluginOptions = pluginsOptions[pluginId];
                        plugin.stop(chart, {}, pluginOptions as AnyObject);
                    } else {
                        Chart.unregister(plugin);
                    }
                    this.disabledPlugins.set(pluginId, plugin);
                }
            });
        }
    }

    private enablePlugins(chart: Chart): void {
        this.disabledPlugins.forEach((plugin) => {
            if (plugin?.start) {
                const pluginsOptions = chart.options.plugins as any;
                const pluginOptions = pluginsOptions[plugin.id];
                plugin.start(chart, {}, pluginOptions);
            } else {
                Chart.register(plugin);
            }
        });
        this.disabledPlugins.clear();
    }

    private setState(chart: Chart): void {
        this.pluginState.set(this, chart);
    }

    private getChartState(): Chart {
        return this.pluginState.get(this);
    }

    private setConditioningValue = (elementPosition: ConditionPosition, value: number): void => {
        if (!this.isModeEnabled) {
            return;
        }

        const chart = this.getChartState();
        this.context.setConditioningValue(chart, elementPosition, value);
        chart.render();
    };

    private onSliderValueChanged = (
        position: ConditionPosition,
        sliderValue: number,
        eventType?: ChartEvent["type"]
    ) => {
        const chart = this.getChartState();

        if (chart.options.plugins?.conditioning?.onSliderConditionChanged) {
            chart.options.plugins?.conditioning?.onSliderConditionChanged(position, sliderValue, eventType);
        }
    };

    private enableConditioning = (): void => {
        this.isModeEnabled = true;
        this.context.turnOnConditioningMode();
        const currentChart = this.getChartState();
        this.disablePlugins(currentChart);
        currentChart.render();
    };

    private disableConditioning = (): void => {
        this.isModeEnabled = false;
        this.context.turnOffConditioningMode();
        const currentChart = this.getChartState();
        this.enablePlugins(currentChart);
        currentChart.update();
        currentChart.render();
    };

    private applyConditioningChanges = (): void => {
        this.context.applyChanges();
    };

    private isUndoDisabled = (): boolean => {
        return this.context.isUndoDisabled();
    };

    private isRedoDisabled = (): boolean => {
        return this.context.isRedoDisabled();
    };

    private isApplyChangesDisabled = (): boolean => {
        return this.context.isApplyChangesDisabled();
    };

    public isConditioningEnabled = (): boolean => {
        return this.isModeEnabled;
    };

    private undo = () => {
        this.context.undo();
        const currentChart = this.getChartState();
        currentChart.render();
    };

    private redo = () => {
        this.context.redo();
        const currentChart = this.getChartState();
        currentChart.render();
    };
}
