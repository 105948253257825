import { Paper, PaperProps } from "@material-ui/core";
import { cardStyles } from "./CardStyles";

export const SectionCard = (props: PaperProps) => {
    const classes = cardStyles();

    return (
        <Paper elevation={1} className={classes.sectionCard} {...props}>
            {props.children}
        </Paper>
    );
};
