import { Chip } from "@material-ui/core";
import { moduleChipStyles } from "./ModuleChipStyles";

export interface ModuleChipProps {
    text?: string;
    moduleId?: string | null;
}

export const ModuleChip = ({ text, moduleId }: ModuleChipProps) => {
    const classes = moduleChipStyles({ moduleId });

    if (!text) {
        return null;
    }

    return <Chip className={classes.root} label={text} />;
};
