import React, { useEffect, useRef } from "react";
import { LoadingIndicatorProps } from "./LoadingIndicatorProps";
import lottie from "lottie-web";
import animationData from "./LoadingIndicator.json";

export default function LoadingIndicator({ isDimmed = false, className, style }: LoadingIndicatorProps) {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (containerRef.current) {
            const animation = lottie.loadAnimation({
                container: containerRef.current,
                renderer: "svg",
                loop: true,
                autoplay: true,

                animationData,
            });

            animation.setSpeed(1.2);

            return () => animation.destroy();
        }
    });

    return (
        <div
            ref={containerRef}
            className={className}
            style={{
                background: isDimmed ? "rgba(0, 0, 0, 0.2)" : "transparent",
                ...style,
            }}
        ></div>
    );
}
