import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventDetailsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.resourcePagePadding,
        },

        eventInfoPadding: {
            padding: "2rem 0",
        },

        margin: {
            marginBottom: "60px",
            marginTop: "40px",
        },
        gridContainer: {
            marginTop: "25px",
            marginLeft: "50px",
        },

        subTitleMargin: {
            marginBottom: "2rem",
        },

        commentsGrid: {
            maxWidth: "800px",
        },

        loadingIndicatorForComments: {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
        },

        buttonBelowDescription: {
            margin: "16px 0px 38px 0px",
            "& .MuiTypography-root": {
                marginLeft: "8px",
            },
            "& path": {
                fill: "currentColor",
            },
        },

        buttonsAboveHeaderContainer: {
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",

            "& > div": {
                display: "flex",
                gap: "1rem",
            },
        },
    })
);
