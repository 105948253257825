import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const popupNotificationStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "42.375rem",
            borderLeft: "0.25rem solid",
            backgroundColor: theme.colors.neutrals.white,
            borderRadius: "0.25rem",
            boxShadow: "0 0 0.625rem 0rem rgba(51, 51, 51, 0.5)",
        },

        contentContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            flexGrow: 1,
            paddingTop: "1rem",
            paddingBottom: "1rem",
        },
        textContainer: {
            paddingTop: "0.5rem",
            paddingBottom: "0.25rem",
            maxWidth: "35rem",
        },

        titleStyle: {
            display: "flex",
            justifyContent: "left",
        },

        iconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "2rem",
            height: "2rem",
        },

        iconContainerMargin: {
            margin: "1rem 1rem 1rem 0.75rem",
        },

        closeIconContainer: {
            padding: "0.5rem !important",
            margin: "0.5rem !important",
        },

        detailsButton: {
            paddingBottom: "0.25rem",
        },

        successBorder: {
            borderColor: theme.colors.success.success600,
        },

        successIconBackgroundColor: {
            backgroundColor: theme.colors.success.success100,
        },

        wargningBorder: {
            borderColor: theme.colors.warning.warning600,
        },

        warningIconBackgroundColor: {
            backgroundColor: theme.colors.warning.warning100,
        },

        errorBorder: {
            borderColor: theme.colors.critical.critical600,
        },

        errorIconBackgroundColor: {
            backgroundColor: theme.colors.critical.critical100,
        },

        infoBorder: {
            borderColor: theme.colors.info.info600,
        },

        infoIconBackgroundColor: {
            backgroundColor: theme.colors.info.info100,
        },

        successIcon: {
            fill: theme.colors.success.success600,
        },

        warningIcon: {
            fill: theme.colors.warning.warning600,
        },

        errorIcon: {
            fill: theme.colors.critical.critical600,
        },

        infoIcon: {
            fill: theme.colors.info.info600,
        },

        icon: {
            width: "1rem",
            height: "1rem",
            fill: theme.colors.neutrals.neutral500,
        },
    })
);
