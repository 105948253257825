import { createStyles, makeStyles } from "@material-ui/core";

interface Props {
    fontSize?: number;
}

export const lynxAvatarStyles = makeStyles((theme) =>
    createStyles({
        container: {
            border: `2px solid ${theme.colors.neutrals.white}`,
            borderRadius: theme.borderRadius,
            display: "inline-block",
        },

        avatar: ({ fontSize }: Props) => ({
            fontFamily: "Gibson",

            "& span": {
                fontSize: fontSize === undefined ? undefined : `${fontSize}rem !important`,
            },
        }),
    })
);
