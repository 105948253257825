import { Grid } from "@material-ui/core";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import { LynxSelectWithSearchProps } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearchProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { commonConstants } from "lynxConstants";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { EventType, LocationInfo, ManualEventModel } from "models/thorEvents/eventModels";
import { useStore } from "store/StoreConfigs";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";

export default observer(function TransportationInformationSection() {
    const { customerDataStore, identityStore } = useStore();
    const formik = useFormikContext<ManualEventModel>();
    const classes = eventCreationStyles();

    const getLocations = (value: string | undefined) => {
        const request: CustomersDataWithSearch = {
            searchValue: value,
            customerId: identityStore.currentCustomer.id,
            pageNumber: 1,
            pageSize: 5,
        };

        customerDataStore.loadCustomerLocations(request);
    };

    const handleOriginChange: LynxSelectWithSearchProps<LocationInfo, false, false, false>["onChange"] = (
        _event,
        value,
        _reason,
        _details
    ) => {
        formik.setFieldValue("deliveryInformation.origin", value ?? { id: "", code: "", name: "" });
        formik.setFieldError("deliveryInformation.origin.id", undefined);
    };

    const handleDestinationChange: LynxSelectWithSearchProps<LocationInfo, false, false, false>["onChange"] = (
        _event,
        value,
        _reason,
        _details
    ) => {
        formik.setFieldValue("deliveryInformation.destination", value ?? { id: "", code: "", name: "" });
        formik.setFieldError("deliveryInformation.destination.id", undefined);
    };

    const originValue = formik.values.deliveryInformation.origin.id ? formik.values.deliveryInformation.origin : null;
    const destinationValue = formik.values.deliveryInformation.destination.id
        ? formik.values.deliveryInformation.destination
        : null;

    return (
        <>
            {formik.values.type === EventType.Transportation && (
                <SectionCard>
                    <LynxTypography variant="h2" className={classes.titleMargin}>
                        Transportation Information
                    </LynxTypography>
                    <Grid container justifyContent="space-between" spacing={8}>
                        <Grid container item xs={6} className={classes.locationBorder}>
                            <Grid container className={classes.autocompleteMargin}>
                                <Grid item xs={12}>
                                    <LynxTypography variant="h3" color="neutral400" className={classes.subtitle}>
                                        <LabelWithRequiredSymbol label="Origin" />
                                    </LynxTypography>

                                    <LynxSelectWithSearch
                                        value={originValue}
                                        disablePortal
                                        forcePopupIcon
                                        isSearchWithAutocomplete
                                        search={getLocations}
                                        placeholder="Search by site ID or Name of Location"
                                        options={customerDataStore.locations}
                                        loading={customerDataStore.progressFlags.loadLocations}
                                        freeSolo={false}
                                        multiple={false}
                                        disableClearable={false}
                                        onChange={handleOriginChange}
                                        inputProps={{
                                            name: "deliveryInformation.origin.id",
                                            error: !!formik.errors?.deliveryInformation?.origin?.id,
                                            assistiveText: formik.errors?.deliveryInformation?.origin?.id,
                                        }}
                                        disablePopupIconInteractions
                                        getOptionLabel={(x) => [x.code, x.name].filter((x) => x).join(" ")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} className={classes.rowPadding}>
                                <Grid item xs={4}>
                                    <LynxInputForm
                                        readOnly
                                        name="deliveryInformation.origin.code"
                                        label="Site ID"
                                        placeholder={commonConstants.emptyValue}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <LynxInputForm
                                        readOnly
                                        name="deliveryInformation.origin.name"
                                        label="Name of Location"
                                        placeholder={commonConstants.emptyValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={6}>
                            <Grid container className={classes.autocompleteMargin}>
                                <Grid item xs={12}>
                                    <LynxTypography variant="h3" color="neutral400" className={classes.subtitle}>
                                        <LabelWithRequiredSymbol label="Destination" />
                                    </LynxTypography>
                                    <LynxSelectWithSearch
                                        value={destinationValue}
                                        disablePortal
                                        forcePopupIcon
                                        isSearchWithAutocomplete
                                        search={getLocations}
                                        placeholder="Search by site ID or Name of Location"
                                        options={customerDataStore.locations}
                                        // clearFunc={handleDestinationClear}
                                        loading={customerDataStore.progressFlags.loadLocations}
                                        freeSolo={false}
                                        multiple={false}
                                        disableClearable={false}
                                        onChange={handleDestinationChange}
                                        inputProps={{
                                            name: "deliveryInformation.destination.id",
                                            error: !!formik.errors?.deliveryInformation?.destination?.id,
                                            assistiveText: formik.errors?.deliveryInformation?.destination?.id,
                                        }}
                                        disablePopupIconInteractions
                                        getOptionLabel={(x) => [x.code, x.name].filter((x) => x).join(" ")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} className={classes.rowPadding}>
                                <Grid item xs={4}>
                                    <LynxInputForm
                                        readOnly
                                        name="deliveryInformation.destination.code"
                                        label="Site ID"
                                        placeholder={commonConstants.emptyValue}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <LynxInputForm
                                        readOnly
                                        name="deliveryInformation.destination.name"
                                        label="Name of Location"
                                        placeholder={commonConstants.emptyValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SectionCard>
            )}
        </>
    );
});
