import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxInputProps } from "components/LynxComponents/LynxInput/LynxInputProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FieldHookConfig, useField } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { reusableFormsStyles } from "./ReusableFormsStyles";

const LynxDurationInputForm = (
    props: FieldHookConfig<string | number> & LynxInputProps & { zeroIsDefault?: boolean }
) => {
    const [field, meta, helpers] = useField<string | number>(props.name);
    const [hoursValue, setHoursValue] = useState<string>("");
    const [minutesValue, setMinutesValue] = useState<string>("");
    const [activeInput, setActiveInput] = useState(false);

    const hasError = meta.touched && !!meta.error;

    const {
        size = "medium",
        error = hasError,
        assistiveTextClassName,
        leaveSpaceForContent = true,
        labelTypographyProps,
        placeholder,
        label,
        inputRef,
        onFocus,
        onBlur,
        zeroIsDefault = false,
        ...rest
    } = { ...props };

    const { onChange, value, onBlur: onBlurField, ...fieldRest } = { ...field };

    const classes = reusableFormsStyles({ size, error });

    const assistiveTextClasses = clsx(
        assistiveTextClassName,
        !hasError && (leaveSpaceForContent ? classes.assistiveTextHidden : classes.assistiveTextDisplayNone)
    );

    const labelClasses = clsx(classes.label, labelTypographyProps?.className);

    const inputBaseClasses = clsx(classes.inputBackground, classes.durationInput, props.className);
    const durationContainerClasses = clsx(
        classes.durationBox,
        classes.inputBackground,
        hasError && classes.durationBoxBorderColorError,
        activeInput && classes.durationBoxBorderColorFocus
    );

    const handleHoursChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const targetValue = e.target.value;

        if (targetValue.match(/^[0-9]*$/)) {
            const hours = Number(targetValue);

            setHoursValue(targetValue);

            const value = Number(minutesValue) + hours * 60;

            const result = targetValue || minutesValue ? value.toString() : targetValue;

            helpers.setValue(result);

            hasError && helpers.setError(undefined);
        }
    };

    const handleMinutesChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const targetValue = e.target.value;

        if (targetValue.match(/^[0-9]*$/)) {
            const minutes = Number(targetValue);

            setMinutesValue(targetValue);

            const value = minutes + Number(hoursValue) * 60;

            const result = targetValue || hoursValue ? value.toString() : targetValue;

            helpers.setValue(result);

            hasError && helpers.setError(undefined);
        }
    };

    const handleFocus = () => setActiveInput(true);
    const handleBlur = () => setActiveInput(false);

    useEffect(() => {
        const numericValue = Number(value);

        if ((typeof value !== "string" || value.trim()) && !isNaN(numericValue)) {
            if (numericValue === 0 && !props.zeroIsDefault) {
                setHoursValue(numericValue.toString());
                setMinutesValue(numericValue.toString());
                return;
            }

            const hours = Math.floor(numericValue / 60);
            const minutes = numericValue - hours * 60;

            setHoursValue(hours === 0 ? "" : hours.toString());
            setMinutesValue(minutes === 0 ? "" : minutes.toString());
        }
    }, [meta.initialValue]);

    return (
        <Box>
            {!!label && (
                <LynxTypography variant="body-s" color="neutral400" className={labelClasses}>
                    {label}
                </LynxTypography>
            )}
            <Grid container item className={durationContainerClasses} direction="row" justifyContent="space-between">
                <Grid container item direction="row" xs={10}>
                    <Grid item xs={5}>
                        <LynxInput
                            autoComplete="off"
                            className={inputBaseClasses}
                            placeholder="000"
                            value={hoursValue}
                            onChange={handleHoursChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            endAdornment={<LynxTypography>h</LynxTypography>}
                            {...rest}
                            {...fieldRest}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <LynxInput
                            autoComplete="off"
                            className={inputBaseClasses}
                            placeholder="00"
                            value={minutesValue}
                            onChange={handleMinutesChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            endAdornment={<LynxTypography>m</LynxTypography>}
                            {...rest}
                            {...fieldRest}
                        />
                    </Grid>
                </Grid>

                <Grid item className={classes.durationIcon} xs={2}>
                    <LynxIcon name="duration" />
                </Grid>
            </Grid>
            <LynxTypography
                variant="body-s"
                color={error ? "critical500" : "neutral400"}
                className={assistiveTextClasses}
            >
                {meta.error || "Error"}
            </LynxTypography>
        </Box>
    );
};

export default LynxDurationInputForm;
