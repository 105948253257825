import { AuditReasonsModel } from "api/models/audit/auditApi";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { auditEntryPayloadRendererStyles } from "./AuditEntryPayloadRendererStyles";

interface AuditReasonsProps {
    reasons: AuditReasonsModel[];
}

export const AuditReasons = (props: AuditReasonsProps) => {
    const classes = auditEntryPayloadRendererStyles();

    const getReasonString = (x: AuditReasonsModel) => {
        const reasons = x.reasons.join(", ");

        return x.triggeringEntityDisplayName
            ? `${x.triggeringEntityType} ${x.triggeringEntityDisplayName}: ${reasons}`
            : reasons;
    };

    return (
        <div className={classes.reasonsContainer}>
            <LynxTypography variant="h3" color="neutral400">
                reason(s):
            </LynxTypography>
            {props.reasons.map((x, i) => (
                <LynxTypography key={i}>{getReasonString(x)}</LynxTypography>
            ))}
        </div>
    );
};
