import { Grid, Paper } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { commonConstants } from "lynxConstants";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { PaginationArea } from "models/shared/Page";

export default observer(function HistorySection() {
    const classes = stabilityFormStyles();
    const { stabilityFormStore } = useStore();

    const getContent = (value: string) => {
        if (value) {
            return value;
        } else {
            return "No comments/justifications were added";
        }
    };

    return (
        <div className={classes.historyContent} id="stabilityFormChangesHistory">
            {stabilityFormStore.progressFlags.loadingStabilityFormHistoryChanges ? (
                <div>
                    <CenteredLoadingIndicator />
                </div>
            ) : (
                <>
                    <LynxTypography variant="h2" paragraph>
                        Updates History
                    </LynxTypography>
                    <Grid container className={classes.historyTitlesGrid}>
                        <Grid item xs={2}>
                            <LynxTypography paragraph variant="body-medium">
                                Date & Time of Change
                            </LynxTypography>
                        </Grid>
                        <Grid item xs={10}>
                            <LynxTypography paragraph variant="body-medium">
                                Editor
                            </LynxTypography>
                        </Grid>
                    </Grid>
                    {stabilityFormStore.stabilityFormChangesHistory.map((x) => (
                        <Grid container key={x.id} className={classes.historyContentGrid}>
                            <Grid item xs={2}>
                                <LynxTypography variant="body-s" color="neutral400">
                                    {dateToFormat(x.editedDateTime, commonConstants.dateTimeFormat)}
                                </LynxTypography>
                            </Grid>
                            <Grid item xs={10}>
                                <Paper className={classes.historyActionSection}>
                                    <LynxTypography variant="body-medium" paragraph>
                                        <Grid container alignItems="baseline">
                                            <Grid item>
                                                <LynxAvatarWithTooltip
                                                    email={x.editorEmail}
                                                    firstName={x.editorFirstName}
                                                    lastName={x.editorLastName}
                                                />
                                            </Grid>
                                            <Grid item> {`${x.editorFirstName} ${x.editorLastName}`}</Grid>
                                        </Grid>
                                    </LynxTypography>
                                    <LynxTypography>{getContent(x.content)}</LynxTypography>
                                </Paper>
                            </Grid>
                        </Grid>
                    ))}

                    {stabilityFormStore.stabilityFormChangesHistory.length > 0 && (
                        <ThorPagination
                            page={stabilityFormStore.currentHistoryPage}
                            pages={stabilityFormStore.totalHistoryPages}
                            onPageChange={stabilityFormStore.moveToHistoryPage}
                            isLastPage={stabilityFormStore.isLastHistoryPage}
                            setPage={stabilityFormStore.setHistoryPage}
                            area={PaginationArea.StabilityFormHistory}
                            setPageSize={stabilityFormStore.setHistoryPageSize}
                        />
                    )}
                </>
            )}
        </div>
    );
});
