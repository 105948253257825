import { useOktaAuth } from "@okta/okta-react";
import { services } from "api/serviceConfig";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { useState } from "react";
import { termsAndConditions } from "lynxConstants";
import { termsAndConditionsStyles } from "./TermsAndConditionsStyles";

export const TermsAndConditionsComponent = () => {
    const classes = termsAndConditionsStyles();
    const oktaAuth = useOktaAuth();
    const [loading, setLoading] = useState(false);

    const onAcceptTerms = async () => {
        if (loading) {
            return;
        }

        setLoading(true);

        try {
            const response = await services.Users.approveUserTerms();

            response.status === 204 || response.status === 200
                ? (window.location.href = "/events")
                : await oktaAuth.oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <LynxTypography variant="h2" className={classes.titleText}>
                Terms and Conditions
            </LynxTypography>
            <LynxTypography variant="body" className={classes.descriptionText}>
                Before proceeding please read and agree with our terms and conditions
            </LynxTypography>
            <div className={classes.textWrapper}>
                <LynxTypography variant="body">{termsAndConditions}</LynxTypography>
            </div>
            <LynxButton
                size="large"
                className={classes.button}
                onClick={onAcceptTerms}
                loading={loading}
                disabled={loading}
            >
                I’ve read and agree to Terms and Conditions
            </LynxButton>
        </div>
    );
};
