import { apiRoutes } from "api/apiRoutes";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";
import { torAxios } from "api/serviceConfig";
import * as commonApi from "api/models/commonApi";
import * as commonModels from "models/commonModels";

export class CommonService {
    getComments = async (request: commonApi.GetCommentsRequest) => {
        // temporarily leaving only one option for fetching event comments. In future we need to make it generic
        const url = apiRoutes.events.getEventComments(request.customerId, request.entityId);
        const config: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
            },
        };

        const result = await torAxios.get<Page<commonModels.CommentModel>>(url, config);
        return result;
    };

    createComment = async (request: commonApi.CreateCommentRequest) => {
        // temporarily leaving only one option for creating event comments. In future we need to make it generic
        const url = apiRoutes.events.createEventComment(request.customerId, request.entityId);
        const body = {
            comment: request.comment,
        };

        const result = await torAxios.post<commonModels.CommentModel>(url, body);
        return result;
    };
}
