import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStore } from "store/StoreConfigs";

export interface ScrollWatcherProps {
    callback: (isOutsideViewport: boolean) => void;
}

export const ScrollWatcher = observer(({ callback }: ScrollWatcherProps) => {
    const { commonStore } = useStore();

    useEffect(() => {
        if (commonStore.scrollWatcherTarget) {
            const observerCallback = (entries: IntersectionObserverEntry[]) => {
                // When div is outside the viewport isIntersecting = false
                callback(!entries[0].isIntersecting);
            };

            const observer = new IntersectionObserver(observerCallback, {
                rootMargin: `50px`,
                threshold: 0.5,
            });

            observer.observe(commonStore.scrollWatcherTarget);

            return () => observer.disconnect();
        }
    }, [commonStore.scrollWatcherTarget]);

    return null;
});
