import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const termsAndConditionsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "25rem",
        },
        textWrapper: {
            overflowY: "auto",
            height: "29.15rem",
            border: `1px solid ${theme.colors.neutrals.neutral200}`,
            borderRadius: theme.borderRadius,
            margin: "1rem 0rem",
            padding: "1rem",
        },
        button: {
            width: "100%",
            margin: "1rem 0rem",
        },
        titleText: {
            padding: "3rem 0rem 1rem 0rem",
        },
        descriptionText: {
            padding: "1rem 0rem",
        },
    })
);
