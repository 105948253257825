import { Link } from "@material-ui/core";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { EventIdModel } from "models/thorEvents/eventModels";
import { Link as RouterLink } from "react-router-dom";
import routes from "routes";

interface PreviousEventsWarningProps {
    events: EventIdModel[];
}

export const PreviousEventsWarning = (props: PreviousEventsWarningProps) => {
    return (
        <>
            There have been previous events that have not been evaluated. Not evaluating these first will impact the
            accuracy of the remaining stability hours in this event.
            <br />
            <br />
            Event&nbsp;
            {props.events.map((x, i) => [
                i > 0 && ", ",
                <Link component={RouterLink} to={routes.eventDetails.to(x.id)} key={x.id} target="_blank">
                    <Span variant="body-l" color="blue500">
                        {x.displayId}
                    </Span>
                </Link>,
            ])}
        </>
    );
};
