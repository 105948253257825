import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxTextareaStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            fontFamily: "Gibson",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            width: "100%",
            border: "unset",
            backgroundColor: "inherit",
            resize: "none",
            "&:focus-visible": {
                outline: "unset",
            },
        },
        label: {
            paddingBottom: "8px",
        },
    })
);
