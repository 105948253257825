export const multipleValuesHelper = (selected: string[], values: { id: string; name: string }[]) => {
    if (selected.length === 0) {
        return <i>None</i>;
    }

    const names = values
        .filter((x) => selected.includes(x.id))
        .map((x) => x.name)
        .sort();

    return names.join(", ");
};
