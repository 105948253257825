import { createStyles, makeStyles } from "@material-ui/core";

export const userTooltipStyles = makeStyles((theme) =>
    createStyles({
        // default tooltip styles cannot be overriden for some reason, adding important to fix them
        root: {
            backgroundColor: `${theme.colors.neutrals.white} !important`,
            display: "flex",
            padding: "1rem !important",
            gap: "1rem",
            border: `1px solid ${theme.colors.primary.blue100}`,
            boxShadow: "0px 0px 10px 0px rgba(51, 51, 51, 0.50)",
            maxWidth: "unset !important",
        },
    })
);
