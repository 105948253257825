import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { DismissPopupsButton } from "components/DismissPopupsButton/DismissPopupsButton";
import { oktaAuth } from "oktaAuth";
import { bypassErrorInterceptor } from "./apiRoutes";
import { envConstants } from "../env-constants";
import { EventsService } from "./services/EventsService";
import { StabilityFormsService } from "./services/StabilityFormsService";
import { UsersService } from "./services/user-management/UsersService";
import { CustomersService } from "./services/user-management/CustomersService";
import { GroupsService } from "./services/user-management/GroupsService";
import { PermissionsService } from "./services/user-management/PermissionsService";
import { CustomersDataService } from "./services/CustomersDataService";
import { BaseResponse } from "./models/sharedModels/ResponseModels";
import { NotificationType } from "models/shared/NotificationType";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { AuditService } from "./services/AuditService";
import { CommonService } from "./services/CommonService";
import { unknownErrorText } from "helpers/handleErrors";
import { TokenService } from "./services/TokenService";

export const services = {
    Events: new EventsService(),
    StabilityForms: new StabilityFormsService(),
    Users: new UsersService(),
    Customers: new CustomersService(),
    Groups: new GroupsService(),
    Permissions: new PermissionsService(),
    CustomersData: new CustomersDataService(),
    Audit: new AuditService(),
    Common: new CommonService(),
    Token: new TokenService(),
};

export const torAxios = axios.create({
    baseURL: envConstants.REACT_APP_TOR_AXIOS_BASE_URL,
    withCredentials: true,
});

export const torAxiosIgnoreErrors = axios.create({
    baseURL: envConstants.REACT_APP_TOR_AXIOS_BASE_URL,
    withCredentials: true,
});

let csrfToken = "";

const getCsrfToken = async () => {
    if (csrfToken) {
        return csrfToken;
    }
    const response = await services.Token.getCsrfToken();
    csrfToken = response.data;

    return csrfToken;
};

torAxios.interceptors.request.use(
    async (config) => {
        if (config.headers.Authorization !== "") {
            config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
        } else {
            // if Authorization header was set manually then remove  header completely. Required for AWS presignedurls to avoid exception;
            delete config.headers.Authorization;
        }

        if (!!config.method && ["post", "put", "delete", "patch"].includes(config.method)) {
            config.headers["X-CSRF-TOKEN"] = await getCsrfToken();
        }

        return config;
    },
    (error) => {
        console.log("Request error: " + error);
    }
);

// TODO: need to refactor error handling, we should be able to show validation errors as popup
torAxios.interceptors.response.use(
    (response) => response,
    ({ response, config }: AxiosError<BaseResponse>) => {
        if (response?.data?.isValidationError || bypassErrorInterceptor(response, config)) {
            return response;
        }

        if (response?.status === 401) {
            return response;
        }

        if (response?.data?.notDisplayPopup) {
            return response;
        }

        if (response?.status === 404) {
            window.location.replace("/pageNotFound");
        }

        if (!toast.isActive("dismissAll")) {
            toast(<DismissPopupsButton />, {
                toastId: "dismissAll",
            });
        }

        displayPopupNotification(NotificationType.ERROR, unknownErrorText);

        return response;
    }
);

torAxiosIgnoreErrors.interceptors.request.use(async (config) => {
    if (config.headers.Authorization !== "") {
        config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
    } else {
        // if Authorization header was set manually then remove  header completely. Required for AWS presignedurls to avoid exception;
        delete config.headers.Authorization;
    }

    if (!!config.method && ["post", "put", "delete", "patch"].includes(config.method)) {
        config.headers["X-CSRF-TOKEN"] = await getCsrfToken();
    }

    return config;
});

torAxiosIgnoreErrors.interceptors.response.use(
    (response) => response,
    ({ response }: AxiosError<BaseResponse>) => {
        if (response?.status === 404) {
            window.location.replace("/pageNotFound");
        }

        return response;
    }
);
