import { GroupApiPermissionInfo, PermissionApiRequestItem } from "api/models/userManagement/userManagementApi";

export interface AddUserModel {
    [key: string]: any;

    email: string;
    repeatEmail: string;
    firstName: string;
    lastName: string;
    title: string;
    withPassword: boolean;
    password: string;
    groups: string[];
    customers: string[];
}
export enum AssigneeType {
    Default = 0,
    Reviewer = 1,
    QA = 2,
}
export interface EditUserModel {
    [key: string]: any;

    firstName: string;
    lastName: string;
    title: string;
    groups: string[];
    customers: string[];
}

interface MarvelUserCustomerInfo {
    id: string;
    name: string;
}

interface MarvelUserGroupInfo {
    id: string;
    name: string;
    moduleId: string | null;
}

export interface MarvelUser {
    id: string;
    oktaId: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
    title: string | null;
    groups: MarvelUserGroupInfo[];
    customers?: MarvelUserCustomerInfo[];
    isSystemUser?: boolean;
}
export interface UserListItemGroupInfo {
    id: string;
    name: string;
    moduleId: string | null;
}

export interface UserListItem {
    id: string;
    firstName: string;
    lastName: string;
    title: string | null;
    status: UserStatus;
    groups: UserListItemGroupInfo[];
    isSystemUser?: boolean;
}
export enum UserStatus {
    Active = "Active",
    Deactivated = "Deactivated",
    Pending = "Pending",
}
export enum UserType {
    SystemUser = "SystemUser",
    CustomerUser = "CustomerUser",
}
export enum OktaStatus {
    STAGED = "STAGED",
    PROVISIONED = "PROVISIONED",
    ACTIVE = "ACTIVE",
    LOCKED_OUT = "LOCKED_OUT",
    PASSWORD_EXPIRED = "PASSWORD_EXPIRED",
    RECOVERY = "RECOVERY",
    SUSPENDED = "SUSPENDED",
    DEPROVISIONED = "DEPROVISIONED",
}

export interface AddCustomerModel {
    [key: string]: any;

    name: string;
    email: string;
    address: string;
    modules: string[];
    systemUsers: CustomerSystemUserInfo[];
}

export interface AvailableCustomer {
    id: string;
    name: string;
    status: CustomerStatus;
}
export interface Customer {
    id: string;
    name: string;
    email: string | null;
    address: string | null;
    status: CustomerStatus;
    modules: MarvelModule[];
    systemUsers: CustomerSystemUserInfo[];
}

export interface CustomerListItem {
    id: string;
    name: string;
    email: string | null;
    address: string | null;
    status: CustomerStatus;
    modules: MarvelModule[];
}
export enum CustomerStatus {
    Active = "Active",
    Deactivated = "Deactivated",
}

export interface CustomerSystemUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    title?: string | null;
}

export interface EditCustomerModel {
    [key: string]: any;

    name: string;
    email: string;
    address: string;
    modules: string[];
    systemUsers: CustomerSystemUserInfo[];
}
export interface MarvelModule {
    id: string;
    name: string;
}

export interface AddGroupModel {
    [key: string]: any;

    name: string;
    roleId: string;
    moduleId: string;
    users: GroupUserInfo[];
    permissions: GroupPermissionInfo[];
}
export interface EditGroupModel {
    [key: string]: any;

    name: string;
    moduleId: string;
    roleId: string;
    users: GroupUserInfo[];
    permissions: GroupPermissionInfo[];
}
export interface GroupListItemRoleInfo {
    id: string;
    name: string;
}

export interface GroupListItemModuleInfo {
    id: string;
    name: string;
}

export interface GroupListItem {
    id: string;
    name: string;
    status: GroupStatus;
    role: GroupListItemRoleInfo | null;
    module?: GroupListItemModuleInfo | null;
    readonlyStatus: boolean;
}
export interface GroupPermissionInfo {
    id: string;
    name: string;
    description: string;
    section: string;
    includedInRole: boolean;
    overrideType: PermissionOverrideType;
}
export enum GroupStatus {
    Active = "Active",
    Deactivated = "Deactivated",
}
export interface GroupUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    title?: string | null;
}
export enum PermissionOverrideType {
    CheckAndIgnoreRole = "CheckAndIgnoreRole",
    UncheckAndIgnoreRole = "UncheckAndIgnoreRole",
    None = "None",
}
interface GroupModule {
    id: string;
    name: string;
}

interface GroupRole {
    id: string;
    name: string;
}

export interface UserGroup {
    id: string;
    name: string;
    status: GroupStatus;
    role: GroupRole | null;
    module?: GroupModule | null;
    isDeleted?: boolean;
    readonlyStatus?: boolean;
    readonlyName?: boolean;
    readonlyPermissions?: boolean;
    users: GroupUserInfo[];
    permissions: GroupApiPermissionInfo[];
    regions: string[];
    isNotify: boolean;
}

export enum MarvelPermissionStatus {
    Deny = "DENY",
    Allow = "ALLOW",
    Loading = "LOADING",
}
export interface PermissionRequest {
    key: string;
    invalidate?: boolean;
    body: PermissionApiRequestItem;
}
export interface PermissionResult {
    key: string;
    status: MarvelPermissionStatus | undefined;
}
export enum ResourceType {
    None = "NONE",
    Customer = "CUSTOMER",
    Event = "EVENT",
}
export enum TokenVerificationStatus {
    Unverified = 0,
    Valid = 1,
    Invalid = 2,
    Expired = 3,
    CannotValidate = 4,
}
