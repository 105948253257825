import { apiRoutes } from "api/apiRoutes";
import { torAxios } from "api/serviceConfig";

export class TokenService {
    getCsrfToken = async () => {
        const url = apiRoutes.token.getCsrfToken();
        const result = await torAxios.get<string>(url);

        return result;
    };
}
