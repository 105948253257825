import { useEffect } from "react";
import { Outlet } from "react-router";
import { useStore } from "store/StoreConfigs";

const StabilityFormSectionWrapper = () => {
    const { stabilityFormStore } = useStore();

    const resetStabilityFormSearchFiltersPagination = () => {
        stabilityFormStore.setSearchValue("");
        stabilityFormStore.setSearchParameter("ProductName");
        stabilityFormStore.setFormOwnerFilter([]);
        stabilityFormStore.clearPagination();
    };

    useEffect(() => {
        return () => {
            resetStabilityFormSearchFiltersPagination();
        };
    }, []);

    return <Outlet />;
};

export default StabilityFormSectionWrapper;
