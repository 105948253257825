import { AxiosResponse } from "axios";
import uuid from "react-uuid";

const mimicUserClick = (link: HTMLAnchorElement) => {
    document.body.appendChild(link);

    link.click();
    link.remove();
};

export const forceFileDownload = (fileName: string, file: Blob) => {
    const downloadLink = document.createElement("a");
    const fileUrl = URL.createObjectURL(file);

    downloadLink.href = fileUrl;
    downloadLink.download = decodeURIComponent(fileName);
    mimicUserClick(downloadLink);
};

export const forceFileOpen = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    mimicUserClick(link);
};

export const extractFileNameFromContentDisHeader = (response: AxiosResponse<Blob>) => {
    const header = response.headers["content-disposition"] as string;

    // header format: attachment; filename=Specific File Name; filename*=UTF-8''Encoded Specific File Name
    const split = header?.split("; ");
    const encoded = split?.find((x) => x.startsWith("filename*="));
    const normal = split?.find((x) => x.startsWith("filename="));

    const encodedFileName = encoded?.replace("filename*=UTF-8''", "");
    const normalFileName = normal?.replace("filename=", "");

    return encodedFileName || normalFileName || `${uuid()}.pdf`;
};
