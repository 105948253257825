import { FormikErrors, useFormikContext } from "formik";
import { useEffect } from "react";

interface SetApiValidationErrorsProps<T> {
    storeErrors: FormikErrors<T> | null;
}

export default function SetApiValidationErrors<T>(props: SetApiValidationErrorsProps<T>): null {
    const formik = useFormikContext<T>();

    useEffect(() => {
        if (props.storeErrors) {
            formik.setErrors(props.storeErrors);
        }
    }, [props.storeErrors]);

    return null;
}
