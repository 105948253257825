import { RootEntityType } from "api/models/audit/auditApi";
import { AuditSection } from "components/ReusableComponents/AuditSection/AuditSection";
import { getPermissionKey } from "helpers/permissionHelpers";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";

export const StabilityFormAuditPage = observer(() => {
    const { stabilityFormId = "" } = useParams();
    const stabilityFormIdValid = isGuidValid(stabilityFormId);
    const { identityStore, permissionsStore } = useStore();
    const navigate = useNavigate();

    const permissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        identityStore.currentCustomer.id
    );
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);
    const permissionLoading = permissionStatus === undefined || permissionStatus === MarvelPermissionStatus.Loading;
    const accessDenied = identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny;

    const stabilityDataInfoLoadAllowed = stabilityFormIdValid && !accessDenied && !permissionLoading;

    useEffect(() => {
        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied]);

    useEffect(() => {
        if (!stabilityFormId) {
            return navigate(routes.pageNotFound);
        }
    }, [stabilityFormId]);

    return (
        <AuditSection
            entityId={stabilityFormId}
            entityType={RootEntityType.StabilityForm}
            backRedirectRoute={routes.stabilityFormDetails.to(stabilityFormId)}
            backButtonTitle="Stability Form Details"
            fetchDataIsAllowed={stabilityDataInfoLoadAllowed}
            loading={!stabilityDataInfoLoadAllowed}
        />
    );
});
