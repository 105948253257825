import { observer } from "mobx-react";
import React, { useRef } from "react";
import clsx from "clsx";
import { lynxJustificationModalStyles } from "./LynxJustificationModalStyles";
import { LynxJustificationModalProps } from "./LynxJustificationModalProps";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { JustificationFormModal } from "models/commonModels";
import { justificationValidationSchema } from "validation/ValidationSchemas/justificationValidationSchema";

export const LynxJustificationModal = observer((props: LynxJustificationModalProps) => {
    const { value, onConfirm, handleValueChange } = props;
    const classes = lynxJustificationModalStyles();
    const formRef = useRef<FormikProps<JustificationFormModal>>(null);
    const justificationInitialValue: JustificationFormModal = { justification: value ?? "" };

    const handleSubmit = (values: JustificationFormModal, formikHelpers: FormikHelpers<JustificationFormModal>) => {
        if (handleValueChange !== undefined) {
            handleValueChange(values.justification);
            formikHelpers.setFieldError("justification", undefined);
        }

        props.onConfirm();
    };

    return (
        <Formik
            initialValues={justificationInitialValue}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={justificationValidationSchema()}
            onSubmit={handleSubmit}
            innerRef={formRef}
        >
            <Form id={"lynxjustification"}>
                <LynxModal
                    {...props}
                    onConfirm={() => formRef.current?.submitForm()}
                    header="Justification"
                    primaryButtonTitle="Confirm"
                    secondaryButtonTitle="Cancel"
                >
                    <LynxInputForm
                        className={clsx(classes.textArea)}
                        placeholder="Leave your justification comment here..."
                        multiline
                        minRows={8}
                        name="justification"
                    />
                </LynxModal>
            </Form>
        </Formik>
    );
});

export default LynxJustificationModal;
