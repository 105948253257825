import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { RootEntityType } from "api/models/audit/auditApi";
import clsx from "clsx";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxMenu from "components/LynxComponents/LynxMenu/LynxMenu";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { AuditEntryTableRow } from "components/ReusableComponents/AuditSection/AuditEntryTableRow";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import dayjs from "dayjs";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "store/StoreConfigs";
import { FullScreenLoadingIndicator } from "../LoadingIndicator/FullScreenLoadingIndicator";
import { LynxBackButton } from "../LynxBackButton/LynxBackButton";
import { auditSectionStyles } from "./AuditSectionStyles";

export interface AuditSectionProps {
    entityId: string;
    entityType: RootEntityType;
    titlePrefix?: string;
    backButtonTitle: string;
    backRedirectRoute: string;
    fetchDataIsAllowed?: boolean;
    loading?: boolean;
}

export const AuditSection = observer((props: AuditSectionProps) => {
    const currentTimezone = dayjs.tz.guess();
    const {
        entityId,
        entityType,
        titlePrefix,
        backButtonTitle,
        backRedirectRoute,
        fetchDataIsAllowed = true,
        loading = false,
    } = { ...props };
    const commonRequestData = {
        entityId: entityId,
        entityType: entityType,
        pageNumber: 1,
        pageSize: 10000,
    };

    const { auditStore, commonStore, identityStore } = useStore();
    const classes = auditSectionStyles();

    const auditActionsCount = auditStore.auditEntries.filter((x) => x.displayAction).length;
    const titlePref = titlePrefix ? `${titlePrefix} ` : "";
    const displayTitle = `${titlePref}${auditStore.auditEntityShortInfo.displayId}: Audit Trail (${auditActionsCount})`;

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const [allDetailsExpanded, setallDetailsExpanded] = useState(false);

    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const tableColumnHeader = (name?: string) => (
        <TableCell className={clsx(classes.tableCell, classes.tableHeaderCell)}>
            <Span variant="h3" color="neutral400">
                {name}
            </Span>
        </TableCell>
    );

    const handleExportToPdf = () => {
        identityStore.isSystemSpace
            ? auditStore.exportSystemAuditEntriesToPdf({ ...commonRequestData, currentTimezone })
            : auditStore.exportCustomerAuditEntriesToPdf({
                  ...commonRequestData,
                  customerId: identityStore.currentCustomer.id,
                  currentTimezone,
              });
    };

    const handleExportToCsv = () => {
        identityStore.isSystemSpace
            ? auditStore.exportSystemAuditEntriesToCsv({ ...commonRequestData })
            : auditStore.exportCustomerAuditEntriesToCsv({
                  ...commonRequestData,
                  customerId: identityStore.currentCustomer.id,
              });
    };

    useEffect(() => {
        if (fetchDataIsAllowed && entityId) {
            identityStore.isSystemSpace
                ? auditStore.loadSystemAuditEntries({ ...commonRequestData })
                : auditStore.loadCustomerAuditEntries({
                      ...commonRequestData,
                      customerId: identityStore.currentCustomer.id,
                  });

            return () => {
                commonStore.setShowGeneralErrorPageToFalse();
                auditStore.setAuditEntries([]);
            };
        }
    }, [fetchDataIsAllowed, entityId]);

    useEffect(() => {
        if (fetchDataIsAllowed && entityId) {
            identityStore.isSystemSpace
                ? auditStore.loadSystemAuditEntityShortInfo({
                      entityId,
                      entityType,
                  })
                : auditStore.loadCustomerAuditEntityShortInfo({
                      entityId,
                      entityType,
                      customerId: identityStore.currentCustomer.id,
                  });

            return () => {
                commonStore.setShowGeneralErrorPageToFalse();
                auditStore.resetAuditEntityShortInfo();
            };
        }
    }, [fetchDataIsAllowed, entityId]);

    useEffect(() => {
        auditStore.setCurrentPageIsAudit(true);

        return () => {
            auditStore.setCurrentPageIsAudit(false);
        };
    }, []);

    if (commonStore.showGeneralErrorPage) {
        return <GeneralErrorPage />;
    }

    if (
        auditStore.progressFlags.loadingAuditEntries ||
        loading ||
        auditStore.progressFlags.loadingAuditEntityShortInfo
    ) {
        return <FullScreenLoadingIndicator />;
    }

    return (
        <>
            <Helmet>{displayTitle}</Helmet>
            <main className={classes.root}>
                <LynxBackButton path={backRedirectRoute} title={backButtonTitle} />
                <Paper elevation={1} className={classes.paper}>
                    <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                        <Grid container className={withSticky(classes.header)}>
                            <Grid item>
                                <LynxTypography component="h1" variant="h2">
                                    {displayTitle}
                                </LynxTypography>
                            </Grid>
                            <Grid item container xs={4} spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <LynxMenu
                                        buttonProps={{
                                            children: "Export",
                                            leftIcon: <LynxIcon name="export" />,
                                            variant: "secondary",
                                            size: "medium",
                                            loading:
                                                auditStore.progressFlags.exportingToCsv ||
                                                auditStore.progressFlags.exportingToPdf,
                                        }}
                                        menuItems={[
                                            {
                                                title: "PDF",
                                                onClick: handleExportToPdf,
                                                disabled: auditStore.progressFlags.exportingToPdf,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item>
                                    <LynxButton
                                        variant="secondary"
                                        size="medium"
                                        onClick={() => {
                                            setallDetailsExpanded(!allDetailsExpanded);
                                        }}
                                        leftIcon={<LynxIcon name={allDetailsExpanded ? "minusSmall" : "plusSmall"} />}
                                    >
                                        {allDetailsExpanded ? "Collapse" : "Expand"} all details
                                    </LynxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </HeaderPortal>
                    {auditStore.auditEntries.length === 0 ? (
                        <LynxTypography color="neutral400" className={classes.noResultsText}>
                            No results found
                        </LynxTypography>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableColumnHeader("actions/categories")}
                                    {tableColumnHeader("date & time")}
                                    {tableColumnHeader("performed by")}
                                    {tableColumnHeader()}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {auditStore.auditEntries
                                    .filter((x) => x.displayAction)
                                    .map((x) => (
                                        <AuditEntryTableRow
                                            key={x.id}
                                            entry={x}
                                            forceExpand={allDetailsExpanded}
                                            className={classes.tableCell}
                                            timezone={
                                                auditStore.auditEntityShortInfo.timezone === null
                                                    ? undefined
                                                    : auditStore.auditEntityShortInfo.timezone
                                            }
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    )}
                </Paper>
            </main>
        </>
    );
});
