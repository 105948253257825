import { Grid } from "@material-ui/core";
import LynxDropdown from "components/LynxComponents/LynxDropdown/LynxDropdown";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import FilterBubbles from "../FilterBubbles/FilterBubbles";
import { FiltersComponentProps } from "./FiltersComponentProps";
import { filtersComponentStyles } from "./FiltersComponentStyles";
import { getSearchPlaceholder, LynxSearch, LynxSearchParametersSelect } from "components/LynxComponents/LynxSearch";

export const FiltersComponent = (props: FiltersComponentProps) => {
    const { title, filters, request, resetFilters, search } = props;
    const classes = filtersComponentStyles();

    const cleanAllFilters = () => {
        resetFilters();
        request();
    };

    return (
        <>
            <Grid container alignItems="center">
                <Grid item>
                    <LynxTypography variant="h1">{title}</LynxTypography>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <LynxSearchParametersSelect {...search.searchParameterProps} />
                        </Grid>
                        <Grid item>
                            <LynxSearch
                                searchValue={search.searchValue}
                                setSearchValue={search.setSearchValue}
                                request={request}
                                placeholder={getSearchPlaceholder(
                                    search.searchParameterProps.type,
                                    search.searchParameterProps.parameter
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.filtersMargin}>
                <Grid item>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <LynxTypography color="neutral400">Filter By:</LynxTypography>
                        {filters.map((filter) => (
                            <Grid key={filter.label}>
                                <LynxDropdown
                                    isUsersDropdown={filter.isUsersDropdown}
                                    options={filter.options}
                                    label={filter.label}
                                    loading={filter.isOptionsDownloaded}
                                    value={filter.selectedOptions.length === 0 ? [""] : filter.selectedOptions}
                                    onChange={(e) => {
                                        filter.changeFunction(e.target.value as string[]);
                                        request();
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
                <Grid item>
                    <FilterBubbles
                        resetFiltersFunc={cleanAllFilters}
                        items={filters.map((filter) => ({
                            selectedValues: filter.selectedOptions,
                            filterName: filter.label,
                            changeFunction: (value: string[]) => {
                                filter.changeFunction(value);
                                request();
                            },
                        }))}
                    />
                </Grid>
            </Grid>
        </>
    );
};
