import { services } from "api/serviceConfig";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { isErrorResponseWithPayload } from "helpers/typeHelpers";
import { toastsText } from "lynxConstants";
import { NotificationType } from "models/shared/NotificationType";
import * as usersModels from "models/userManagement/userManagementModels";
import { store } from "store/StoreConfigs";

const reactivateUser = async (userId: string) => {
    try {
        store.userStore.setProgressFlags({ reactivatingUser: true });

        const response = store.identityStore.isSystemSpace
            ? await services.Users.reactivateSystemUser(userId)
            : await services.Users.reactivateCustomerUser(userId, store.identityStore.currentCustomer.id);

        if (isErrorResponseWithPayload(response.data) && response.status === 400) {
            const payload = response.data.payload;

            if (payload.oktaStatus === usersModels.OktaStatus.ACTIVE) {
                await store.userStore.changeUserStatus(userId, usersModels.UserStatus.Active);
                displayPopupNotification(NotificationType.INFORMATION, toastsText.userAlreadyActive);
                return;
            }
        }

        if (response.status === 200) {
            displayPopupNotification(NotificationType.SUCCESS, toastsText.activationLinkResent);
        }
    } finally {
        store.userStore.setProgressFlags({ reactivatingUser: false });
    }
};

export const userManagementFuncs = {
    reactivateUser,
} as const;
