import { Grid } from "@material-ui/core";
import {
    DeleteEventAttachmentRequest,
    EventAttachmentResponse,
    GetDownloadPresignedUrlRequest,
} from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import LynxAttachments from "components/ReusableComponents/LynxAttachments/LynxAttachments";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { forceFileDownload } from "helpers/fileHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { promptTooltipMessages, toastsText } from "lynxConstants";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { EventStatus } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useStore } from "store/StoreConfigs";
import { eventAttachmentsStyles } from "./EventAttachmentsStyles";

const EventAttachments = observer(() => {
    const classes = eventAttachmentsStyles();
    const { eventId } = useParams();
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();

    const pageSize = 15;
    const editPermission = permissionsStore.getPermissionResult(
        actions.customer.tor.events.editInformation,
        thorEventViewStore.eventDetails.id
    );

    const handleClick = () => {
        thorEventViewStore.setAttachmentPageNumber(thorEventViewStore.attachmentPageNumber + 1);
    };

    const showLoadAttachmentsCount = () => {
        const unloadedCount = thorEventViewStore.attachmentsTotalCount - thorEventViewStore.attachments.length;

        return unloadedCount > pageSize ? pageSize : unloadedCount;
    };

    const onRemoveFile = async (file: EventAttachmentResponse) => {
        if (
            thorEventViewStore.progressFlags.loadingAttachments ||
            thorEventViewStore.progressFlags.uploadingAttachments
        ) {
            return;
        }

        thorEventViewStore.setLoadingAttachmentsFlag(true);

        const deleteRequest: DeleteEventAttachmentRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: thorEventViewStore.eventDetails.id,
            attachmentId: file.id,
        };

        const response = await services.Events.deleteEventAttachment(deleteRequest);

        if (response.status !== 204) {
            thorEventViewStore.setLoadingAttachmentsFlag(false);
            return;
        }

        thorEventViewStore.setLoadingAttachmentsFlag(false);
        thorEventViewStore.incrementAttachmentsFirstSetCount();
    };

    const downloadFile = async (file: EventAttachmentResponse) => {
        if (
            thorEventViewStore.progressFlags.loadingAttachments ||
            thorEventViewStore.progressFlags.uploadingAttachments
        ) {
            return;
        }

        thorEventViewStore.setLoadingAttachmentsFlag(true);

        const getDownloadUrlRequest: GetDownloadPresignedUrlRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: thorEventViewStore.eventDetails.id,
            attachmentId: file.id,
        };

        const response = await services.Events.getDownloadPresignedUrl(getDownloadUrlRequest);
        const blobResponse = await services.Events.downloadFromS3WithPresignedUrl({ url: response.data.url });

        if (blobResponse.status !== 200) {
            thorEventViewStore.setLoadingAttachmentsFlag(false);
            displayPopupNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.fileName));
            return;
        }

        forceFileDownload(file.fileName, blobResponse.data);

        thorEventViewStore.setLoadingAttachmentsFlag(false);
    };

    const isAddAttachmentButtonDisabled =
        editPermission.status !== MarvelPermissionStatus.Allow ||
        thorEventViewStore.eventDetails.status === EventStatus.Closed ||
        thorEventViewStore.progressFlags.loadingAttachments;

    const getTooltipMessage = () => {
        const isCurrentUserIsReviever = thorEventViewStore.eventDetails.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = thorEventViewStore.eventDetails.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case thorEventViewStore.eventDetails.status === EventStatus.Closed:
                return promptTooltipMessages.event.decisionCompleted;
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;

            case editPermission.status !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    useEffect(() => {
        if (thorEventViewStore.attachmentPageNumber < 2) {
            return;
        }

        thorEventViewStore.loadEventAttachments(
            {
                customerId: identityStore.currentCustomer.id,
                eventId: eventId!,
                pageNumber: thorEventViewStore.attachmentPageNumber,
                pageSize: pageSize,
            },
            false
        );
    }, [thorEventViewStore.attachmentPageNumber]);

    return (
        <>
            <Grid item className={classes.gridMarginBottomMedium}>
                <InfoSubheader
                    text={`attachments (${thorEventViewStore.attachmentsTotalCount})`}
                    icon={<LynxIcon name="attachments" />}
                />
            </Grid>

            <LynxAttachments
                attachments={thorEventViewStore.attachments}
                progressFlag={thorEventViewStore.progressFlags.loadingAttachments}
                downloadFile={downloadFile}
                handleRemoveAttachment={onRemoveFile}
                timezone={thorEventViewStore.eventDetails.timezone}
                isRemoveDisabled={
                    editPermission.status !== MarvelPermissionStatus.Allow ||
                    thorEventViewStore.eventDetails.status === EventStatus.Closed ||
                    thorEventViewStore.progressFlags.loadingAttachments
                }
            />

            {thorEventViewStore.attachmentsTotalCount > thorEventViewStore.attachments.length && (
                <Grid item className={classes.gridMarginBottomMedium}>
                    <LynxButton
                        variant="tertiary"
                        onClick={handleClick}
                        disabled={
                            thorEventViewStore.progressFlags.loadingNextSetOfAttachments ||
                            thorEventViewStore.progressFlags.loadingAttachments
                        }
                        loading={thorEventViewStore.progressFlags.loadingNextSetOfAttachments}
                        leftIcon={<LynxIcon name="angleSmallDown" />}
                    >
                        Show {showLoadAttachmentsCount()} More
                    </LynxButton>
                </Grid>
            )}
            <Grid item>
                <PromptTooltip title={getTooltipMessage()} placement="top">
                    <LynxButton
                        variant="secondary"
                        className={classes.buttonWithIcon}
                        disabled={isAddAttachmentButtonDisabled}
                        onClick={() => thorEventViewStore.setFileAttachModalOpen(true)}
                        loading={thorEventViewStore.progressFlags.loadingAttachments}
                        leftIcon={<LynxIcon name="plusSmall" />}
                    >
                        Add Attachment
                    </LynxButton>
                </PromptTooltip>
            </Grid>
        </>
    );
});

export default EventAttachments;
