import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import { UserTooltip } from "../UserTooltip/UserTooltip";

export interface AvatarWithTooltipProps {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    size?: number;
    fontSize?: number;
}

export const LynxAvatarWithTooltip = (props: AvatarWithTooltipProps) => {
    const { size, fontSize, ...rest } = props;

    return (
        <UserTooltip {...rest}>
            {/* tooltip is not shown without this div */}
            <div>
                <LynxAvatar {...rest} size={size} fontSize={fontSize} />
            </div>
        </UserTooltip>
    );
};
