import { MenuItem } from "@material-ui/core";
import { LynxSelect } from "../LynxSelect/LynxSelect";
import { searchParameters } from "./lynxSearchParameters";
import { lynxSearchStyles } from "./LynxSearchStyles";
import { LynxSearchParameterSelectProps } from "./lynxSearchTypes";

export const LynxSearchParametersSelect = (props: LynxSearchParameterSelectProps) => {
    const { type, parameter, setSearchParameter } = props;
    const classes = lynxSearchStyles();

    const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        // list of possible search parameters is taken from searchParameters[type] obj
        // type for parameter in setSearchParameter is dynamically defined based on searchParameters[type]["value"] union type
        const v = e.target.value as any;
        setSearchParameter(v);
    };

    return (
        <LynxSelect value={parameter} className={classes.select} onChange={onChange}>
            {searchParameters[type].map((x) => (
                <MenuItem key={x.value} value={x.value}>
                    {x.label}
                </MenuItem>
            ))}
        </LynxSelect>
    );
};
