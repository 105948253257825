import { Chart, ChartArea, ChartEvent, ScatterDataPoint } from "chart.js";
import { ConditioningPluginOptions } from "./crop-temperature-range-augmentation";
import {
    ConditionPosition,
    IPluginElement,
    SliderConditionChanged,
    SlidersContext,
    SliderState,
} from "./interfaces/pluginDeclarations";
import { EventArgs } from "./interfaces/pluginEventArgs";
import { LeftElement } from "./leftElement";
import { RightElement } from "./rightElement";

export class CropTemperatureRangeContext {
    private elements: Map<ConditionPosition, IPluginElement> = new Map<ConditionPosition, IPluginElement>();
    private readonly options: ConditioningPluginOptions;
    private currentChartArea!: ChartArea | null;
    private savedSlidersState: SlidersContext[] = [];
    private savedSlidersStatePositions: SlidersContext | null = null;

    public onSliderValueChanged?: SliderConditionChanged;

    public constructor(options: ConditioningPluginOptions) {
        this.options = options;
        if (options.leftSlider) {
            this.elements.set(
                ConditionPosition.left,
                new LeftElement(options.minValue, options.maxValue, options.leftSlider)
            );
        }

        if (options.rightSlider) {
            this.elements.set(
                ConditionPosition.right,
                new RightElement(options.minValue, options.maxValue, options.rightSlider)
            );
        }
    }

    public turnOnConditioningMode(): void {
        const slidersContext: Map<ConditionPosition, SliderState> = new Map<ConditionPosition, SliderState>();
        this.elements.forEach((v, k) => {
            slidersContext.set(k, v.getSliderState());
        });
        const state = { sliders: slidersContext };
        this.savedSlidersStatePositions = state;
        this.savedSlidersState.push(state);
    }

    public turnOffConditioningMode(): void {
        this.elements.forEach((v, k) => {
            const slider = this.elements.get(k);
            const firstState = this.savedSlidersState[0].sliders.get(k);
            if (slider && firstState) {
                slider.restoreSliderState(firstState);
                this.fireSliderChangedEvent(k, firstState.sliderSlidingBorderValue);
            }
        });
        this.resetStates();
    }

    public applyChanges(): void {
        this.resetStates();
    }

    public updateLayout(chart: Chart, chartArea: ChartArea, options: ConditioningPluginOptions): void {
        if (!chartArea) {
            return;
        }
        if (chartArea.height !== this.currentChartArea?.height || chartArea.width !== this.currentChartArea?.width) {
            const left = this.elements.get(ConditionPosition.left);
            const right = this.elements.get(ConditionPosition.right);
            left?.containerChanged(chartArea, chart.scales.x, chart.scales.y);
            right?.containerChanged(chartArea, chart.scales.x, chart.scales.y);
            this.currentChartArea = chartArea;
        }
    }

    public initiate(): void {
        this.elements.forEach((v, k) => {
            this.fireSliderChangedEvent(k, v.getSliderX());
        });
    }

    public mouseDown(chart: Chart, args: EventArgs): void {
        this.elements.forEach((val, key) => {
            if (val.isInside({ x: args.event.x ?? 0, y: args.event.y ?? 0 })) {
                val.uReChosenOneSet(true);
            }
        });
    }

    public mouseUp(chart: Chart, args: EventArgs): void {
        this.elements.forEach((val, key) => {
            const point = args.event.x; // = this.getPoint(chart, key);
            if (point && val.uReChosenOneGet()) {
                this.addNewValueToState();
                switch (key) {
                    case ConditionPosition.left: {
                        const rightSliderCoord = this.elements.get(ConditionPosition.right)?.getLeftBorder() ?? 0;
                        const currentX = args.event.x ?? 0;
                        const xCoord = currentX < rightSliderCoord ? currentX : rightSliderCoord - 5;
                        val.updateXPosition(xCoord);
                        this.snapSlider(chart, key);
                        const xVal = val.getSliderX();
                        this.fireSliderChangedEvent(ConditionPosition.left, xVal);
                        break;
                    }
                    case ConditionPosition.right: {
                        const leftSliderCoord = this.elements.get(ConditionPosition.left)?.getRightBorder() ?? 0;
                        const currentX = args.event.x ?? 0;
                        const xCoord = currentX > leftSliderCoord ? currentX : leftSliderCoord + 5;
                        val.updateXPosition(xCoord);
                        this.snapSlider(chart, key);
                        const xVal = val.getSliderX();
                        this.fireSliderChangedEvent(ConditionPosition.right, xVal);
                        break;
                    }
                    default:
                        break;
                }
            }
            val.uReChosenOneSet(false);
        });
    }

    public mouseMove(chart: Chart, args: EventArgs): void {
        let cursorOnElement: boolean = false;
        this.elements.forEach((val, key) => {
            if (val.isInside({ x: args.event.x ?? 0, y: args.event.y ?? 0 })) {
                cursorOnElement = true;
            }

            let xCoord: number = 0;
            switch (key) {
                case ConditionPosition.right: {
                    const borderCoord = this.elements.get(ConditionPosition.left)?.getRightBorder() ?? 0;
                    const currentX = args.event.x ?? 0;
                    xCoord = currentX > borderCoord ? currentX : borderCoord + 5;
                    break;
                }
                case ConditionPosition.left: {
                    const borderCoord = this.elements.get(ConditionPosition.right)?.getLeftBorder() ?? 0;
                    const currentX = args.event.x ?? 0;
                    xCoord = currentX < borderCoord ? currentX : borderCoord - 5;
                    break;
                }
                default:
                    break;
            }

            if (cursorOnElement) {
                chart.ctx.canvas.style.cursor = "grab";
            } else {
                chart.ctx.canvas.style.cursor = "default";
            }

            if (val.uReChosenOneGet()) {
                val.updateXPosition(xCoord);
            }
        });
    }

    public setConditioningValue(chart: Chart, elementPosition: ConditionPosition, value: number) {
        const element = this.elements.get(elementPosition);

        if (!element) {
            return;
        }

        const xScale = chart.scales.x;

        if (value >= xScale.min && value <= xScale.max) {
            this.addNewValueToState();
            const xCoord = xScale.getPixelForValue(value);
            element?.updateXPosition(xCoord);
            this.snapSlider(chart, elementPosition);
            const xVal = element.getSliderX();

            this.fireSliderChangedEvent(elementPosition, xVal);
        } else {
            this.addNewValueToState();

            if (ConditionPosition.left === elementPosition && value < this.options.minValue && element) {
                const xCoord = xScale.getPixelForValue(this.options.minValue);
                element.updateXPosition(xCoord);
            }
            if (ConditionPosition.right === elementPosition && value > this.options.maxValue && element) {
                const xCoord = xScale.getPixelForValue(this.options.maxValue);
                element.updateXPosition(xCoord);
            }
            const xVal = element.getSliderX();

            this.fireSliderChangedEvent(elementPosition, xVal);
        }
    }

    public drawElements(chart: Chart): void {
        const widthOfSliders: number[] = [];
        this.elements.forEach((val, key) => {
            val.drawElement(chart);
            widthOfSliders.push(val.getWidth());
        });
        this.drawMiddlePart(chart, widthOfSliders);
    }

    public drawElementsBorder(chart: Chart): void {
        this.elements.forEach((val, key) => {
            val.drawBorderImage(chart);
        });
    }

    public isUndoDisabled(): boolean {
        const currentStateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);
        return currentStateIndex === 0;
    }

    public isRedoDisabled(): boolean {
        const currentStateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);
        return currentStateIndex === this.savedSlidersState.length - 1;
    }

    public isApplyChangesDisabled(): boolean {
        const currentStateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);
        return currentStateIndex === 0;
    }

    public undo(): void {
        const stateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);
        if (stateIndex && stateIndex > 0) {
            const slidersStates = this.savedSlidersState[stateIndex - 1];
            this.elements.forEach((val, key) => {
                const elementState = slidersStates.sliders.get(key);
                if (elementState) {
                    val.restoreSliderState(elementState);
                    this.fireSliderChangedEvent(key, elementState.sliderSlidingBorderValue);
                }
            });
            this.savedSlidersStatePositions = slidersStates;
        }
    }

    public redo(): void {
        const stateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);
        if (this.savedSlidersState.length - 1 >= stateIndex + 1) {
            const slidersStates = this.savedSlidersState[stateIndex + 1];
            this.elements.forEach((val, key) => {
                const elementState = slidersStates.sliders.get(key);
                if (elementState) {
                    val.restoreSliderState(elementState);
                    this.fireSliderChangedEvent(key, elementState.sliderSlidingBorderValue);
                }
            });
            this.savedSlidersStatePositions = slidersStates;
        }
    }

    private sliderContextIsNewCheck(slidersContext: Map<ConditionPosition, SliderState>): boolean {
        const lastActionContext = this.savedSlidersState.find((x) => x === this.savedSlidersStatePositions);

        let isNew = false;
        lastActionContext?.sliders.forEach((val, key) => {
            const newSliderChange = slidersContext.get(key);

            if (newSliderChange) {
                isNew = isNew || val.sliderSlidingBorderValue !== newSliderChange.sliderSlidingBorderValue;
            }
        });

        return isNew;
    }

    private resetStates() {
        this.savedSlidersState.length = 0;
        this.savedSlidersStatePositions = null;
    }

    private addNewValueToState(): void {
        const slidersContext: Map<ConditionPosition, SliderState> = new Map<ConditionPosition, SliderState>();
        this.elements.forEach((v, k) => {
            slidersContext.set(k, v.getSliderState());
        });

        if (!this.sliderContextIsNewCheck(slidersContext)) {
            return;
        }
        const stateIndex = this.savedSlidersState.findIndex((x) => x === this.savedSlidersStatePositions);

        if (this.savedSlidersState.length - 1 === stateIndex) {
            const newContext = { sliders: slidersContext };
            this.savedSlidersState.push(newContext);
            this.savedSlidersStatePositions = newContext;
            return;
        }
        if (this.savedSlidersState.length - 1 > stateIndex) {
            const newContext = { sliders: slidersContext };
            const undoWasPressedArray = this.savedSlidersState.splice(0, 1);
            undoWasPressedArray.push(newContext);
            this.savedSlidersState = undoWasPressedArray;
            this.savedSlidersStatePositions = newContext;
        }
    }

    private fireSliderChangedEvent(position: ConditionPosition, value: number, eventType?: ChartEvent["type"]) {
        if (this.onSliderValueChanged) {
            this.onSliderValueChanged(position, value, eventType);
        }
    }

    private drawSegments(chart: Chart, start: number, end: number) {
        const ctx = chart.ctx;

        const xAxis = chart.scales.x;
        const dataset = chart.data.datasets[0];
        const gradientStroke = ctx.createLinearGradient(xAxis.left, 0, xAxis.right, 0);
        const gradientStartEnd = start / dataset.data.length;
        const gradientEndEnd = end / dataset.data.length;
        let currentColor = "grey";
        let currentScale = gradientStartEnd;
        for (let i = 0; i < dataset.data.length; i++) {
            if (i === start) {
                gradientStroke.addColorStop(currentScale, currentColor);
                currentColor = "red";
            }

            if (i === end) {
                currentScale = gradientEndEnd;
                gradientStroke.addColorStop(currentScale, currentColor);
                currentColor = "grey";
            }

            gradientStroke.addColorStop(currentScale, currentColor);
        }
        dataset.backgroundColor = gradientStroke;
        dataset.borderColor = gradientStroke;
    }

    private snapSlider(chart: Chart, elName: ConditionPosition): void {
        const el = this.elements.get(elName);
        const intersectedValues: number[] = [];
        const slidingValue = el?.getSliderState().sliderSlidingBorderValue as number;

        chart.data.datasets.forEach((dataset) => {
            const array = dataset.data;
            for (let i = 0, j = i + 1; i < array.length && j < array.length - 1; i++, j = i + 1) {
                const elementI = array[i] as ScatterDataPoint;
                const elementJ = array[j] as ScatterDataPoint;
                const exact = elementI.x === slidingValue;
                const more = elementI.x < slidingValue;
                const less = elementJ.x > slidingValue;
                const isBetween = more && less;
                if (isBetween) {
                    if (elName === ConditionPosition.left) {
                        intersectedValues.push(elementI.x);
                    }
                    if (elName === ConditionPosition.right) {
                        intersectedValues.push(elementJ.x);
                    }
                    break;
                }
                if (exact) {
                    intersectedValues.push(elementI.x);
                    break;
                }
            }
        });

        if (intersectedValues.length === 0) {
            const closestDataPoint = chart.data.datasets
                .flatMap((x) => x.data)
                .reduce(function (prev, curr) {
                    return Math.abs((curr as ScatterDataPoint).x - slidingValue) <
                        Math.abs((prev as ScatterDataPoint).x - slidingValue)
                        ? curr
                        : prev;
                }) as ScatterDataPoint;

            intersectedValues.push(closestDataPoint.x);
        }

        if (elName === ConditionPosition.left) {
            const leftValue = Math.min(...intersectedValues);
            el?.updateXPosition(leftValue);
        }
        if (elName === ConditionPosition.right) {
            const rightValue = Math.max(...intersectedValues);
            el?.updateXPosition(rightValue);
        }
    }

    private drawMiddlePart(chart: Chart, slidersWidth: number[]): void {
        if (chart.isConditioningEnabled()) {
            const widthOfMiddleRect = chart.scales.x.width - slidersWidth.reduce((a, b) => a + b, 0);
            const leftElement = this.elements.get(ConditionPosition.left) as IPluginElement;
            chart.ctx.fillStyle = chart.options.plugins?.conditioning?.canvasOptions
                ?.modeEnabledBackgroundColor as string;
            const leftX = leftElement.getX();
            const leftH = leftElement.getHeight();
            const leftY = leftElement.getY();
            const leftW = leftElement.getWidth();
            chart.ctx.fillRect(leftX + leftW, leftY, widthOfMiddleRect, leftH);
        }
    }
}
