import { Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const verticalLineStyles = makeStyles((theme: Theme) =>
    createStyles({
        verticalLineStyles: {
            borderLeft: `1px solid ${theme.colors.primary.blue100}`,
            height: "auto",
        },
    })
);

const VerticalLine = ({ className }: { className?: string }) => {
    const classes = verticalLineStyles();
    return <div className={clsx(classes.verticalLineStyles, className)}></div>;
};

export default VerticalLine;
