import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const formStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {
            color: theme.colors.neutrals.black,
        },

        form: {
            display: "flex",
            flexDirection: "column",
        },

        formSection: {
            padding: "0 45px",
            display: "flex",
            flexDirection: "column",
        },

        input: {
            marginBottom: "20px",
        },

        submitButton: {
            padding: "8px 22px",
            alignSelf: "center",
            fontSize: "17px",
            lineHeight: "26px",
        },

        loadingIndicator: {
            padding: "75px",
        },

        loadingUsersIndicator: {
            height: "75px",
        },

        selectChip: {
            margin: "2px",
        },

        dropdown: {
            margin: "0px",
            marginBottom: "15px",
            maxWidth: "initial",
        },

        title: {
            fontSize: "28px",
            lineHeight: "28px",
            fontWeight: 500,
            marginBottom: "30px",
        },

        subText: {
            fontSize: "14px",
            lineHeight: "120%",
            fontWeight: 400,
            marginBottom: "36px",
        },

        flexContainer: {
            display: "flex",
        },

        formInput: {
            marginBottom: "20px",
        },

        firstNameInput: {
            marginRight: "20px",
        },

        inputMargin: {
            marginBottom: "35px",
        },

        fullWidthInput: {
            width: "100%",
        },

        emailInput: {
            width: "70%",
        },

        resendInviteLink: {
            width: "30%",
            marginLeft: "0.5rem",
        },

        formInputLabel: {
            color: theme.colors.neutrals.neutral500,
            fontSize: "14px",
            lineHeight: "120%",
            marginBottom: "0.25rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral500,
            },
        },

        checkBoxLabel: {
            marginLeft: "initial",

            "& > .MuiFormControlLabel-label": {
                paddingLeft: "8px",
                fontSize: "14px",
                lineHeight: "140%",
                color: theme.colors.neutrals.neutral500,
            },
            "&:hover": {
                color: theme.colors.primary.blue500,
            },
        },

        passwordInput: {
            marginTop: "15px",
        },

        radioContainer: {
            flexDirection: "row",
            marginBottom: "20px",
            paddingLeft: "2px",
        },

        radioLabel: {
            lineHeight: "140%",
            color: theme.colors.neutrals.neutral500,
        },

        separator: {
            height: "4px",
            border: "none",
            backgroundColor: theme.colors.neutrals.neutral100,
            margin: "25px 0px",
        },

        leftSide: {
            backgroundColor: theme.colors.neutrals.white,
            padding: "25px",
        },

        buttonsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "& > .MuiButtonBase-root:first-child": {
                marginRight: "50px",
            },
        },

        formButton: {
            padding: "8px 22px",
            fontSize: "17px",
            lineHeight: "26px",
        },

        modal: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        modalContent: {
            backgroundColor: theme.colors.neutrals.white,
            boxShadow: theme.mainShadow,
            padding: "20px",
        },

        permissionsHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "40px",
        },

        resetChangesButton: {
            color: theme.colors.neutrals.neutral400,
            fontSize: "11px",
            lineHeight: "18px",
        },

        permissionSection: {
            marginBottom: "40px",

            "&:last-child": {
                marginBottom: "0px",
            },
        },

        roleDropdown: {
            marginBottom: "48px",
        },

        regionsDropdown: {
            minWidth: "180px",
        },

        notificationsHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
            marginBottom: "40px",
        },

        optionalFont: {
            color: theme.colors.neutrals.neutral300,
        },

        iconButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },

        label: {
            marginBottom: "0.25rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral400,
            },
        },

        tabContainer: {
            padding: "30px 30px",
        },
    })
);
