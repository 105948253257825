import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const PromptTooltipStyles = makeStyles((theme: Theme) =>
    createStyles({
        customTooltip: {
            backgroundColor: `${theme.colors.neutrals.neutral600}`,
            color: theme.palette.common.white,
            maxWidth: "none",
        },
        customArrow: {
            "&:before": {
                backgroundColor: `${theme.colors.neutrals.neutral600}`,
            },
        },
    })
);
