import { InputAdornment } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, KeyboardEvent } from "react";
import { LynxInput } from "../LynxInput/LynxInput";
import { lynxSearchStyles } from "./LynxSearchStyles";
import { LynxSearchProps } from "./lynxSearchTypes";

export const LynxSearch = (props: LynxSearchProps) => {
    const { searchValue, setSearchValue, request, placeholder } = props;
    const classes = lynxSearchStyles();

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmed = e.target.value.trimLeft().replace(/\s{2,}/g, " ");
        setSearchValue(trimmed);
    };

    const handleSearch = () => {
        request();
    };

    const handleSearchByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleClearSearchValue = () => {
        setSearchValue("");
        request();
    };

    return (
        <LynxInput
            className={classes.searchInput}
            placeholder={placeholder}
            value={searchValue}
            onChange={handleChangeInput}
            onKeyDown={handleSearchByEnter}
            endAdornment={
                <InputAdornment position="end" className={classes.union}>
                    {searchValue.length > 0 && (
                        <LynxIcon name="crossSmall" className={classes.crossIcon} onClick={handleClearSearchValue} />
                    )}
                    <LynxIcon name="search" onClick={handleSearch} className={clsx(classes.searchIcon)} />
                </InputAdornment>
            }
        />
    );
};
