import clsx from "clsx";
import { observer } from "mobx-react";
import { AssessmentStatus } from "models/thorEvents/eventModels";
import React from "react";
import { assessmentStyles } from "../AssessmentStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

export interface AssessmentRowStatusProps {
    status: AssessmentStatus;
}
export default observer(function AssessmentRowStatus({ status }: AssessmentRowStatusProps) {
    const classes = assessmentStyles();
    const getText = (status: AssessmentStatus) => {
        switch (status) {
            case AssessmentStatus.PendingReview:
                return "Pending Review";
            case AssessmentStatus.PendingDecision:
                return " Pending Decision";
            case AssessmentStatus.EvaluationCompleted:
                return "Evaluation Completed";
        }
    };
    return (
        <div>
            <LynxTypography
                className={clsx(classes.assessmentRowStatus, {
                    [classes.pendingReview]: status === AssessmentStatus.PendingReview,
                    [classes.pendingDecision]: status === AssessmentStatus.PendingDecision,
                    [classes.evaluationCompleted]: status === AssessmentStatus.EvaluationCompleted,
                })}
                variant="body-sbold-s-wide"
            >
                {getText(status)}
            </LynxTypography>
        </div>
    );
});
