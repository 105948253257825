import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const filterBubblesStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: theme.colors.primary.blue75,
            padding: "0.25rem 0.25rem 0.25rem 0.5rem",
            borderRadius: "0.3rem",
            marginRight: "0.5rem",
            marginTop: "0.25rem",

            "&:hover": {
                "& .MuiButtonBase-root": {
                    backgroundColor: theme.colors.primary.blue100,
                },
            },
        },

        clearButton: {
            marginTop: "0.25rem",
        },
    })
);
