import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const duplicateDevicesWarningModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            minHeight: "12.5rem",
            maxHeight: "31.25rem",
            border: `0.0625rem solid ${theme.colors.neutrals.neutral300}`,
            borderRadius: theme.borderRadius,
            marginTop: "0.25rem",
            padding: "0.125rem 0.75rem",
        },

        listItem: {
            alignItems: "flex-start",
            margin: "0",
            padding: "0.25rem 1.5625rem",
            lineHeight: "2",
            color: theme.colors.primary.blue500,
        },

        listItemText: {
            margin: "0",
            paddingLeft: "0",
        },
    })
);
