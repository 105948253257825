import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxJustificationModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            borderRadius: theme.borderRadius,
            marginTop: "0.1rem",
            padding: "0.25rem 0.75rem 0.1rem 0.75rem",
        },
    })
);
