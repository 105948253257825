import LoadingIndicator from "./LoadingIndicator";
import { loadingIndicatorStyles } from "./LoadingIndicatorStyles";

export const FullScreenLoadingIndicator = () => {
    const classes = loadingIndicatorStyles();

    return (
        <div className={classes.fullScreenContainer}>
            <LoadingIndicator className={classes.fullScreenIndicator} />
        </div>
    );
};
