import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import Strong from "components/LynxComponents/LynxTypography/Strong";

export interface EventTemperatureRangeDescriptionProps {
    isSiteEvent: boolean;
    deliveryNumber?: string | null;

    minTemperature: number | string | null;
    maxTemperature: number | string | null;
}

export const EventTemperatureRangeDescription = (props: EventTemperatureRangeDescriptionProps) => {
    return (
        <LynxTypography>
            {props.isSiteEvent ? (
                "The temperature range was between "
            ) : (
                <>
                    During transportation
                    {props.deliveryNumber && (
                        <>
                            {" of delivery "}
                            <Strong>{props.deliveryNumber}</Strong>
                        </>
                    )}
                    {", the temperature range was between "}
                </>
            )}
            <Strong>{`${props.minTemperature}°C and ${props.maxTemperature}°C`}</Strong>.
        </LynxTypography>
    );
};
