import { LinkItem } from "models/commonModels";
import { DeviceModel, DuplicatedDeviceEvents } from "models/thorEvents/eventModels";
import routes from "routes";

export const getUniqueSerialNumbers = (devices: DeviceModel[]): string => {
    const serialNumbersSet = new Set(devices.map((device) => `#${device.serialNumber}`));
    const uniqueSerialNumbers = Array.from(serialNumbersSet).join(", ");
    return `TRDs ${uniqueSerialNumbers} are used in the following Event(s):`;
};

export const convertToLinkItems = (events: DuplicatedDeviceEvents[]): LinkItem[] => {
    const result = events.map((event) => ({
        text: `${event.displayId} (delivery #${event.deliveryNumber})`,
        url: routes.eventDetails.to(event.id),
    }));

    return result;
};
