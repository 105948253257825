import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { pluralize } from "helpers/pluralize";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { AssessmentRow } from "./AssessmentRow/AssessmentRow";
import { assessmentStyles } from "./AssessmentStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import EventReview from "./EventReview/EventReview";
import { AssessmentStatus } from "models/thorEvents/eventModels";
import { ReactNode } from "react";

export const Assessment = observer(() => {
    const { thorEventViewStore } = useStore();
    const classes = assessmentStyles();
    const assessmentsCount = thorEventViewStore.eventDetails.assessments.length;
    const isAnyAssessmentInStatusPendingReview = thorEventViewStore.eventDetails.assessments.some(
        (x) => x.status === AssessmentStatus.PendingReview
    );

    const tableColumnHeader = (name: ReactNode) => {
        return (
            <TableCell>
                <LynxTypography variant="h3" color="neutral400">
                    {name}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <>
            <LynxTypography variant="h2" paragraph>
                {pluralize("Assessment", assessmentsCount)} ({assessmentsCount})
            </LynxTypography>
            <br />
            <Table>
                <TableHead>
                    <TableRow className={classes.assessmentHeaderRow}>
                        {tableColumnHeader("Product")}
                        {tableColumnHeader("Batch #")}
                        {tableColumnHeader("Temperature")}
                        {tableColumnHeader("Event Duration")}
                        {tableColumnHeader("Quantity")}
                        {tableColumnHeader("Automated Assessment")}
                        {tableColumnHeader("Final Decision")}
                        {tableColumnHeader("Status")}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {thorEventViewStore.eventDetails.assessments.map((x) => (
                        <AssessmentRow assessment={x} key={x.id} />
                    ))}
                </TableBody>
            </Table>
            {isAnyAssessmentInStatusPendingReview && <EventReview assessmentsCount={assessmentsCount} />}
        </>
    );
});
