import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet, useNavigate } from "react-router-dom";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import routes from "routes";

export const RequiredAuth: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            navigate(routes.login);
        }
    }, [oktaAuth, !!authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated) {
        return <FullScreenLoadingIndicator />;
    }

    return <Outlet />;
};
