import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { NotificationType } from "models/shared/NotificationType";
import React from "react";
import { IconFrameProps, IconFrameSettings } from "./PopupNotificationProps";
import { popupNotificationStyles } from "./PopupNotificationStyles";

export const IconFrame = (props: IconFrameProps) => {
    let icon;
    const classes = popupNotificationStyles();

    const notificationSettings: IconFrameSettings = {
        [NotificationType.INFORMATION]: {
            iconContainerStyles: classes.infoIconBackgroundColor,
            iconStyles: classes.infoIcon,
            defaultIcon: <LynxIcon name="info" className={classes.infoIcon} />,
        },
        [NotificationType.SUCCESS]: {
            iconContainerStyles: classes.successIconBackgroundColor,
            iconStyles: classes.successIcon,
            defaultIcon: <LynxIcon name="checkSmall" className={classes.successIcon} />,
        },
        [NotificationType.WARNING]: {
            iconContainerStyles: classes.warningIconBackgroundColor,
            iconStyles: classes.warningIcon,
            defaultIcon: <LynxIcon name="triangleWarning" className={classes.warningIcon} />,
        },
        [NotificationType.ERROR]: {
            iconContainerStyles: classes.errorIconBackgroundColor,
            iconStyles: classes.errorIcon,
            defaultIcon: <LynxIcon name="crossSmall" className={classes.errorIcon} />,
        },
    };

    if (props.children) {
        icon = React.cloneElement(props.children, { className: notificationSettings[props.type].iconStyles });
    }

    return (
        <div
            className={clsx(
                classes.iconContainer,
                notificationSettings[props.type].iconContainerStyles,
                props.className
            )}
        >
            {icon ?? notificationSettings[props.type].defaultIcon}
        </div>
    );
};
