import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const notFoundPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            margin: "1rem auto",
            fontWeight: "bold",
            width: "40rem",
            backgroundColor: theme.colors.neutrals.white,
            boxShadow: theme.mainShadow,
            padding: "5rem",
        },
        button: {
            margin: "1rem",
        },
    })
);
