import { services } from "api/serviceConfig";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import * as userManagementModels from "models/userManagement/userManagementModels";
import { makeAutoObservable } from "mobx";
import { PageModel } from "api/models/sharedModels/PageModel";
import IdentityStore from "./IdentityStore";
import _ from "lodash";
import CommonStore from "./CommonStore";
import { handleErrors } from "helpers/handleErrors";
import { localStorageService } from "helpers/localStorageService";
import { PaginationArea } from "models/shared/Page";

export class CustomerStore {
    constructor(identityStore: IdentityStore, commonStore: CommonStore) {
        makeAutoObservable(this);

        this.identityStore = identityStore;
        this.commonStore = commonStore;
    }

    identityStore: IdentityStore;
    commonStore: CommonStore;

    customers: userManagementModels.CustomerListItem[] = [];

    modulesForSelection: userManagementModels.MarvelModule[] = [];
    usersForSelection: userManagementModels.CustomerSystemUserInfo[] = [];

    formErrors: string[] = [];
    clearErrors = () => (this.formErrors = []);
    setErrors = (value: string[]) => (this.formErrors = value);

    selectedCustomer: userManagementModels.Customer = {
        id: "",
        name: "",
        email: "",
        address: "",
        status: userManagementModels.CustomerStatus.Active,
        modules: [],
        systemUsers: [],
    };

    addCustomerModel: userManagementModels.AddCustomerModel = {
        name: "",
        email: "",
        address: "",
        modules: [],
        systemUsers: [],
    };

    editCustomerModel: userManagementModels.EditCustomerModel = {
        name: "",
        email: "",
        address: "",
        modules: [],
        systemUsers: [],
    };

    currentPage = 1;
    totalPages = 1;
    pageSize = localStorageService.getPageSize(PaginationArea.Customers) ?? 10;

    progressFlags = {
        changingCustomerStatus: false,
        loadingCustomers: false,
        loadingModulesForSelection: false,
        loadingUsersForSelection: false,
        loadingCustomerDetails: false,
        addCustomerRequest: false,
        editCustomerRequest: false,
    };

    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    setPage = (page: number) => {
        this.currentPage = page;
    };

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    movePage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentPage-- : this.currentPage++;
    };

    clearPagination = () => {
        this.customers = [];
        this.currentPage = 1;
        this.totalPages = 1;
    };

    setUsersForSelection = (values: userManagementModels.CustomerSystemUserInfo[]) => (this.usersForSelection = values);
    setModulesForSelection = (values: userManagementModels.MarvelModule[]) => (this.modulesForSelection = values);

    loadCustomers = async () => {
        this.progressFlags.loadingCustomers = true;

        try {
            const request: PageModel = { pageNumber: this.currentPage, pageSize: this.pageSize };
            const response = await services.Customers.listCustomers(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.customers = response.data.items;
            this.totalPages = response.data.totalPages;
        } finally {
            this.progressFlags.loadingCustomers = false;
        }
    };

    loadModulesForSelection = async () => {
        this.progressFlags.loadingModulesForSelection = true;

        try {
            const response = await services.Customers.listModules();

            if (!_.inRange(response.status, 200, 300)) return;

            this.modulesForSelection = response.data;
        } finally {
            this.progressFlags.loadingModulesForSelection = false;
        }
    };

    loadUsersForSelection = async () => {
        this.progressFlags.loadingUsersForSelection = true;

        try {
            // load all users in dropdown for selection
            const request = { pageSize: 5000, pageNumber: 1 };
            const response = await services.Users.listSystemUsers(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.usersForSelection = response.data.items;
        } finally {
            this.progressFlags.loadingUsersForSelection = false;
        }
    };

    changeCustomerStatus = async (customerId: string, status: userManagementModels.CustomerStatus) => {
        this.progressFlags.changingCustomerStatus = true;

        try {
            const request: userManagementApi.EditCustomerRequest = {
                fieldsToEdit: ["status"],
                customerId,
                status,
            };

            const response = await services.Customers.editCustomer(request);

            if (!_.inRange(response.status, 200, 300)) return;

            const customerListItem = this.customers.find((x) => x.id === customerId);

            if (!customerListItem) {
                throw new Error("Bug, customerListItem should not be undefined here.");
            }

            customerListItem.status = status;

            if (status === userManagementModels.CustomerStatus.Active) {
                this.identityStore.customers.push({ id: customerId, name: customerListItem.name, status });
            } else {
                this.identityStore.customers = this.identityStore.customers.filter((x) => x.id !== customerId);
            }
        } finally {
            this.progressFlags.changingCustomerStatus = false;
        }
    };

    addCustomer = async (callback: (customerName: string) => void) => {
        if (this.progressFlags.addCustomerRequest) {
            return;
        }

        const model = this.addCustomerModel;
        this.progressFlags.addCustomerRequest = true;
        this.clearErrors();

        const request: userManagementApi.CreateCustomerRequest = {
            name: model.name,
            address: model.address || undefined,
            email: model.email || undefined,
            modules: model.modules,
            systemUsers: model.systemUsers.map((x) => x.id),
        };

        try {
            const response = await services.Customers.createCustomer(request);

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            if (
                this.identityStore.currentUser.allTenantsVisibility ||
                model.systemUsers.some((x) => x.id === this.identityStore.currentUser.id)
            ) {
                this.identityStore.customers.push({
                    id: response.data.id,
                    name: response.data.name,
                    status: response.data.status,
                });
            }

            callback(response.data.name);
        } finally {
            this.progressFlags.addCustomerRequest = false;
        }
    };

    loadCustomerDetails = async (customerId: string) => {
        this.progressFlags.loadingCustomerDetails = true;

        try {
            const response = await services.Customers.getCustomerDetails({ customerId });

            if (!_.inRange(response.status, 200, 300)) return;

            this.selectedCustomer = response.data;
        } finally {
            this.progressFlags.loadingCustomerDetails = false;
        }
    };

    populateEditModel = () => {
        this.editCustomerModel.name = this.selectedCustomer.name;
        this.editCustomerModel.email = this.selectedCustomer.email || "";
        this.editCustomerModel.address = this.selectedCustomer.address || "";
        this.editCustomerModel.modules = this.selectedCustomer.modules.map((x) => x.id);
        this.editCustomerModel.systemUsers = this.selectedCustomer.systemUsers;
    };

    editCustomer = async (callback: (customerName: string) => void) => {
        if (this.progressFlags.editCustomerRequest) {
            return;
        }

        this.progressFlags.editCustomerRequest = true;
        this.clearErrors();

        try {
            const request = this.generateEditRequest();
            const response = await services.Customers.editCustomer(request);

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            this.selectedCustomer = response.data;

            const customerListItem = this.customers.find((x) => x.id === this.selectedCustomer.id);

            if (customerListItem) {
                customerListItem.name = this.selectedCustomer.name;
                customerListItem.email = this.selectedCustomer.email;
                customerListItem.address = this.selectedCustomer.address;
                customerListItem.modules = this.selectedCustomer.modules;
            }

            callback(response.data.name);
        } finally {
            this.progressFlags.editCustomerRequest = false;
        }
    };

    generateEditRequest = (): userManagementApi.EditCustomerRequest => {
        const fields = [
            { name: "name", add: this.editCustomerModel.name !== this.selectedCustomer.name },
            { name: "email", add: this.editCustomerModel.email !== (this.selectedCustomer.email || "") },
            { name: "address", add: this.editCustomerModel.address !== (this.selectedCustomer.address || "") },
        ];

        const fieldsToEdit = fields.filter((x) => x.add).map((x) => x.name);

        const request: userManagementApi.EditCustomerRequest = {
            fieldsToEdit,
            customerId: this.selectedCustomer.id,
            name: this.editCustomerModel.name,
            email: this.editCustomerModel.email || null,
            address: this.editCustomerModel.address || null,
            addedModules: this.editCustomerModel.modules,
            removedModules: this.selectedCustomer.modules
                .map((x) => x.id)
                .filter((x) => !this.editCustomerModel.modules.includes(x)),
            addedSystemUsers: this.editCustomerModel.systemUsers.map((x) => x.id),
            removedSystemUsers: this.selectedCustomer.systemUsers
                .map((x) => x.id)
                .filter((x) => !this.editCustomerModel.systemUsers.map((y) => y.id).includes(x)),
        };

        return request;
    };
}
