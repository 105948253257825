import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventDescriptionStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "start",
        },

        innerDescriptionContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
        },

        headerCell: {
            padding: "0.5rem",
            borderBottom: 0,

            "&:first-child": {
                paddingLeft: 0,
            },
        },

        bodyCell: {
            padding: "0.25rem 0.5rem",

            "&:first-child": {
                paddingLeft: 0,
            },
        },

        table: {
            marginBottom: "0.5rem",
        },

        automatedAssessmentColumn: {
            display: "flex",
            gap: "0.25rem",
            alignItems: "center",

            "& > svg": {
                flexShrink: 0,
            },
        },

        missingFormsWarning: {
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem 1rem",
            border: `1px solid ${theme.colors.critical.critical500}`,
            borderRadius: theme.borderRadius,

            "& > svg": {
                flexShrink: 0,
                fill: theme.colors.critical.critical500,
                width: "1.5rem",
                height: "1.5rem",
            },
        },

        missingFormsWarningList: {
            margin: 0,
            paddingLeft: "1.15rem",
        },
    })
);
