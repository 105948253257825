export interface Page<T> {
    items: T[];
    totalPages: number;
    totalCount: number;
}

// string values of this enum are used in local storage so shouldn't be changed
export enum PaginationArea {
    Customers = "customers",
    Groups = "groups",
    StabilityFormHistory = "stabilityFormHistory",
    StabilityForms = "stabilityForms",
    Events = "events",
    BatchHistory = "batchHistory",
    Users = "users",
}
