import { Button, Grid } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import {
    CalculateDevicesExcursionRequest,
    CalculateManualExcursionRequest,
    ExcursionResultPerDevice,
} from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { SearchOption } from "components/LynxComponents/LynxAutocomplete/LynxAutocompleteProps";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { LynxDateTimePickerForm } from "components/ReusableForms/LynxDateTimePickerForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSearchForm from "components/ReusableForms/LynxSearchForm";
import { FieldArray, useFormikContext } from "formik";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { Observer, observer } from "mobx-react";
import {
    DeliveryInformation,
    EventType,
    ExcursionSource,
    ManualEventModel,
    ManualExcursion,
} from "models/thorEvents/eventModels";
import React, { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { manualExcursionValidationSchema } from "validation/ValidationSchemas/EventDetailsSchemas/manualExcursionValidationSchema";
import { transportationConditionsValidationSchema } from "validation/ValidationSchemas/EventDetailsSchemas/transportationConditionsValidationSchema";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";

export interface DevicesSectionProps {
    isEventEditing: boolean;
}

export default observer(function DevicesSection(props: DevicesSectionProps) {
    const classes = eventCreationStyles();
    const { customerDataStore, identityStore, thorEventViewStore } = useStore();
    const formik = useFormikContext<ManualEventModel>();
    const [devicesExcursions, setDevicesExcursions] = useState<ExcursionResultPerDevice[]>([]);
    const [currentTimezone, setCurrentTimezone] = useState(commonConstants.UTCTimezone);

    const isSiteEvent = formik.values.type === EventType.Site;
    const excursionSource = formik.values.excursionSource;
    const delivery = formik.values.deliveryInformation;
    const manualExcursion = formik.values.manualExcursion;

    const getDevices = (searchValue?: string) => {
        customerDataStore.loadCustomerDevices({
            customerId: identityStore.currentCustomer.id,
            searchValue: searchValue,
            pageNumber: 1,
            pageSize: 5,
        });
    };

    const resetManualExcursion = (errorsOnly?: boolean) => {
        if (!errorsOnly) {
            formik.setFieldValue("manualExcursionDeviceIds", "");
            formik.setFieldValue("manualExcursion.startDateTime", "");
            formik.setFieldValue("manualExcursion.endDateTime", "");
            formik.setFieldValue("manualExcursion.lowTemperature", "");
            formik.setFieldValue("manualExcursion.highTemperature", "");
        }

        formik.setFieldError("manualExcursionDeviceIds", undefined);
        formik.setFieldError("manualExcursion.startDateTime", undefined);
        formik.setFieldError("manualExcursion.endDateTime", undefined);
        formik.setFieldError("manualExcursion.lowTemperature", undefined);
        formik.setFieldError("manualExcursion.highTemperature", undefined);
    };

    const resetDevices = (errorsOnly?: boolean) => {
        if (!errorsOnly) {
            formik.setFieldValue("devices", []);
        }

        formik.setFieldError("devices", undefined);
    };

    useEffect(() => {
        if (formik.dirty) {
            // TODO: do not clear devices or manual excursion when switching between excursion type
            if (excursionSource === ExcursionSource.TemperatureRecordingDevice) {
                resetManualExcursion();
                resetDevices(true);
            } else {
                resetDevices();
                resetManualExcursion(true);
            }
        }
    }, [excursionSource]);

    const dropdownDeviceIds = _.uniq(customerDataStore.devices.map((x) => x.id)).sort();
    const selectedDeviceIds = _.uniq(formik.values.devices.map((x) => x.id)).sort();
    const allDeviceIds = _.union(dropdownDeviceIds, selectedDeviceIds).sort();
    const productIds: string[] = _.uniq(
        formik.values.batches.filter((x) => !!x.productId).map((x) => x.productId!)
    ).sort();

    const getTransportationConditions = () => ({
        lowerLimit: delivery.transportationLowerLimit === "" ? null : delivery.transportationLowerLimit,
        lowerLimitOperator: delivery.transportationLowerLimitOperator,
        upperLimit: delivery.transportationUpperLimit === "" ? null : delivery.transportationUpperLimit,
        upperLimitOperator: delivery.transportationUpperLimitOperator,
    });

    const calculateDevicesExcursion = async (deviceIds: string[]) => {
        const request: CalculateDevicesExcursionRequest = {
            customerId: identityStore.currentCustomer.id,
            processType: formik.values.processType,
            deviceIds: deviceIds,
            productIds: productIds,
            allowedRange: getTransportationConditions(),
        };

        const response = await services.Events.calculateDevicesExcursion(request);
        return response;
    };

    const calculateManualExcursion = async (data: ManualExcursion) => {
        const request: CalculateManualExcursionRequest = {
            customerId: identityStore.currentCustomer.id,
            processType: formik.values.processType,
            productIds: productIds,
            allowedRange: isSiteEvent ? null : getTransportationConditions(),
            manualExcursion: data,
        };

        const response = await services.Events.calculateManualExcursion(request);
        return response;
    };

    const transportationConditionsAreValid = () => {
        const transportationConditions: Partial<DeliveryInformation> = {
            transportationLowerLimit: delivery.transportationLowerLimit,
            transportationLowerLimitOperator: delivery.transportationLowerLimitOperator,
            transportationUpperLimit: delivery.transportationUpperLimit,
            transportationUpperLimitOperator: delivery.transportationUpperLimitOperator,
        };

        return transportationConditionsValidationSchema().isValidSync(transportationConditions);
    };

    // Calculate cumulative product excursion based on manually entered excursion and product storage conditions
    useEffect(() => {
        const func = async () => {
            if (excursionSource === ExcursionSource.TemperatureRecordingDevice) {
                return;
            }

            const manualExcursionIsValid = manualExcursionValidationSchema(
                thorEventViewStore.manualEventModelReadOnly.timezone
            ).isValidSync(manualExcursion);
            const siteEventConditions = isSiteEvent && productIds.length > 0;
            const transportationEventConditions = !isSiteEvent && transportationConditionsAreValid();

            if (manualExcursionIsValid && (siteEventConditions || transportationEventConditions)) {
                const response = await calculateManualExcursion(manualExcursion);

                if (_.inRange(response.status, 200, 300)) {
                    thorEventViewStore.setCalculatedExcursion({ ...response.data, startedAt: null, endedAt: null });
                    return;
                }
            }

            thorEventViewStore.resetCalculatedExcursion();
        };

        func();
    }, [excursionSource, manualExcursion, productIds.join(","), delivery.transportationLowerLimit, delivery.transportationLowerLimitOperator, delivery.transportationUpperLimit, delivery.transportationUpperLimitOperator]);

    // Calculate cumulative product excursion based on selected devices and transportation conditions
    useEffect(() => {
        const func = async () => {
            if (excursionSource === ExcursionSource.Manual) {
                return;
            }

            if (transportationConditionsAreValid() && selectedDeviceIds.length > 0) {
                const response = await calculateDevicesExcursion(selectedDeviceIds);

                if (_.inRange(response.status, 200, 300)) {
                    const { excursionResultsPerDevice: _, ...excursion } = response.data;
                    thorEventViewStore.setCalculatedExcursion(excursion);
                    return;
                }
            }

            thorEventViewStore.resetCalculatedExcursion();
        };

        func();
    }, [excursionSource, selectedDeviceIds.join(","), delivery.transportationLowerLimit, delivery.transportationLowerLimitOperator, delivery.transportationUpperLimit, delivery.transportationUpperLimitOperator]);

    // Calculate excursion for each device based on transportation conditions
    useEffect(() => {
        const func = async () => {
            if (excursionSource === ExcursionSource.Manual) {
                return;
            }

            if (transportationConditionsAreValid() && allDeviceIds.length > 0) {
                const response = await calculateDevicesExcursion(allDeviceIds);

                if (_.inRange(response.status, 200, 300)) {
                    setDevicesExcursions(response.data.excursionResultsPerDevice);
                    return;
                }
            }

            setDevicesExcursions([]);
        };

        func();
    }, [excursionSource, allDeviceIds.join(","), delivery.transportationLowerLimit, delivery.transportationLowerLimitOperator, delivery.transportationUpperLimit, delivery.transportationUpperLimitOperator]);

    const checkAlarmForDevice = (id: string): boolean => {
        return devicesExcursions.some((x) => x.deviceId === id && x.hasAlarm);
    };

    useEffect(() => {
        if (
            (props.isEventEditing && !thorEventViewStore.manualEventModelReadOnly.id) ||
            formik.values.type === EventType.Site
        ) {
            return;
        }

        getDevices();
    }, []);

    const getIconTooltipForExcursionDates = () => {
        return (
            <PromptTooltip placement="top-start" title={"UTC by default unless timezone was changed for the event"}>
                <LynxIcon name="info" className={classes.icon} />
            </PromptTooltip>
        );
    };

    useEffect(() => {
        if (props.isEventEditing) {
            setCurrentTimezone(thorEventViewStore.manualEventModelReadOnly.timezone);
        }
    }, [thorEventViewStore.manualEventModelReadOnly]);

    return (
        <SectionCard>
            <LynxTypography variant="h2" className={classes.titleMargin}>
                Excursion Information
            </LynxTypography>
            {!isSiteEvent && (
                <>
                    <Grid container item xs={6} className={classes.titleMargin}>
                        <Grid item xs={6}>
                            <Button
                                disabled={isSiteEvent}
                                onClick={() =>
                                    formik.setFieldValue("excursionSource", ExcursionSource.TemperatureRecordingDevice)
                                }
                                className={clsx(classes.deviceSectionButton, classes.leftButton, {
                                    [classes.selectedButton]:
                                        excursionSource === ExcursionSource.TemperatureRecordingDevice,
                                })}
                            >
                                <LynxTypography
                                    variant={"body-medium"}
                                    color={
                                        excursionSource === ExcursionSource.TemperatureRecordingDevice
                                            ? "blue500"
                                            : "neutral600"
                                    }
                                >
                                    Find TRDs
                                </LynxTypography>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => formik.setFieldValue("excursionSource", ExcursionSource.Manual)}
                                className={clsx(classes.deviceSectionButton, classes.rightButton, {
                                    [classes.selectedButton]: excursionSource === ExcursionSource.Manual,
                                })}
                            >
                                <LynxTypography
                                    variant={"body-medium"}
                                    color={excursionSource === ExcursionSource.Manual ? "blue500" : "neutral600"}
                                >
                                    Enter Excursion Manually
                                </LynxTypography>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            {!isSiteEvent && (
                <>
                    <LynxTypography variant="body-s" color="neutral400">
                        TRD{" "}
                        {excursionSource === ExcursionSource.TemperatureRecordingDevice && (
                            <span className={classes.asterisk}>*</span>
                        )}
                    </LynxTypography>
                    {excursionSource === ExcursionSource.TemperatureRecordingDevice ? (
                        <FieldArray
                            name="devices"
                            render={(helpers) => (
                                <Observer>
                                    {() => (
                                        <>
                                            {/* TODO: replace with LynxSelectWithSearch */}
                                            <LynxSearchForm
                                                inputProps={{
                                                    name: "devices",
                                                    error: formik.errors?.devices !== undefined,
                                                    assistiveText: formik.errors?.devices as string,
                                                }}
                                                className={classes.trdSearch}
                                                loading={customerDataStore.progressFlags.loadDevices}
                                                disableCloseOnSelect
                                                placeholder="Select TRDs"
                                                name="devices"
                                                multiple
                                                options={customerDataStore.devices.map((device) => ({
                                                    id: device.id,
                                                    displayName: device.serialNumber,
                                                    hasAlarm: checkAlarmForDevice(device.id),
                                                }))}
                                                search={getDevices}
                                                renderInput={() => {
                                                    return <div></div>;
                                                }}
                                                customValue={formik.values.devices.map((x) => ({
                                                    id: x.id,
                                                    displayName: x.serialNumber,
                                                }))}
                                                customOnChange={(
                                                    event: React.ChangeEvent<{}>,
                                                    value: string | SearchOption | null | (string | SearchOption)[],
                                                    reason: AutocompleteChangeReason
                                                ) => {
                                                    if (
                                                        event.nativeEvent instanceof KeyboardEvent &&
                                                        (event.nativeEvent.key === "Enter" ||
                                                            event.nativeEvent.key === "Backspace")
                                                    ) {
                                                        // handle select/unselect by enter/backspace.
                                                        return;
                                                    }

                                                    const options = value as SearchOption[];
                                                    formik.setFieldValue(
                                                        `devices`,
                                                        options.map((x) => ({ id: x.id, serialNumber: x.displayName }))
                                                    );

                                                    !!formik.errors?.devices &&
                                                        formik.setFieldError("devices", undefined);
                                                }}
                                            />
                                            <Grid container spacing={1}>
                                                {formik.values.devices.map((x, i) => {
                                                    return (
                                                        <Grid item className={classes.selectedTrd} key={x.id}>
                                                            <LynxTypography
                                                                variant={"body-medium"}
                                                                className={classes.selectedTrdParagraph}
                                                            >
                                                                {checkAlarmForDevice(x.id) && (
                                                                    <LynxIcon
                                                                        name="triangleWarning"
                                                                        className={classes.triangleIcon}
                                                                    />
                                                                )}
                                                                {x.serialNumber}
                                                                <LynxButton
                                                                    variant="icon"
                                                                    className={classes.selectedTrdIcon}
                                                                    onClick={() => helpers.remove(i)}
                                                                >
                                                                    <LynxIcon name="crossSmall" />
                                                                </LynxButton>
                                                            </LynxTypography>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </>
                                    )}
                                </Observer>
                            )}
                        />
                    ) : (
                        <Grid container item xs={6}>
                            <LynxInputForm
                                assistiveText={undefined}
                                name="manualExcursionDeviceIds"
                                placeholder="Enter TRD numbers"
                            />
                        </Grid>
                    )}
                </>
            )}
            <LynxTypography variant="h3" color="neutral400" className={classes.temperatureDataSubtitle}>
                Temperature Data
            </LynxTypography>
            <Grid container justifyContent="space-between" item xs={7}>
                <Grid item xs={4} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Temperature Range
                        {excursionSource === ExcursionSource.Manual && <span className={classes.asterisk}>*</span>}
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Excursion Start Date&Time
                        {excursionSource === ExcursionSource.Manual && <span className={classes.asterisk}>*</span>}
                        {getIconTooltipForExcursionDates()}
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Excursion End Date&Time
                        {excursionSource === ExcursionSource.Manual && <span className={classes.asterisk}>*</span>}
                        {getIconTooltipForExcursionDates()}
                    </LynxTypography>
                </Grid>
                <Grid item xs={2} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Total Duration
                    </LynxTypography>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                item
                xs={7}
                className={clsx(classes.temperatureDataRow, classes.borderBottom)}
            >
                <Grid item xs={4} className={classes.temperateDataGrid}>
                    {excursionSource === ExcursionSource.TemperatureRecordingDevice ? (
                        <LynxTypography>
                            {formatTemperatureRange(
                                thorEventViewStore.calculatedExcursion.minTemperature,
                                thorEventViewStore.calculatedExcursion.maxTemperature
                            )}
                        </LynxTypography>
                    ) : (
                        <Grid container>
                            <Grid item xs={4}>
                                <LynxInputForm
                                    assistiveText={formik.errors?.manualExcursion?.lowTemperature}
                                    name="manualExcursion.lowTemperature"
                                    type="number"
                                    placeholder="Min"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LynxTypography
                                    className={clsx(
                                        classes.smallPaddingTop,
                                        classes.smallPaddingLeft,
                                        classes.smallPaddingRight
                                    )}
                                >
                                    °C
                                </LynxTypography>
                            </Grid>
                            <Grid item xs={1}>
                                <LynxTypography className={clsx(classes.smallPaddingTop, classes.smallPaddingRight)}>
                                    to
                                </LynxTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <LynxInputForm placeholder="Max" type="number" name="manualExcursion.highTemperature" />
                            </Grid>
                            <Grid item xs={1}>
                                <LynxTypography className={clsx(classes.smallPaddingTop, classes.smallPaddingLeft)}>
                                    °C
                                </LynxTypography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    {excursionSource === ExcursionSource.TemperatureRecordingDevice ? (
                        <LynxTypography>
                            {thorEventViewStore.calculatedExcursion.startedAt !== null
                                ? convertDateToSelectedTimezone(
                                      thorEventViewStore.calculatedExcursion.startedAt,
                                      currentTimezone
                                  ).format(commonConstants.dateTimeFormat)
                                : commonConstants.emptyValue}
                        </LynxTypography>
                    ) : (
                        <>
                            <LynxDateTimePickerForm
                                showNow={false}
                                variant="datetime"
                                customRequestFormat={commonConstants.dateTimeFormatWithTimeZone}
                                showTime
                                format={commonConstants.dateTimeFormat}
                                name="manualExcursion.startDateTime"
                                timezone={currentTimezone}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    {excursionSource === ExcursionSource.TemperatureRecordingDevice ? (
                        <LynxTypography>
                            {thorEventViewStore.calculatedExcursion.endedAt !== null
                                ? convertDateToSelectedTimezone(
                                      thorEventViewStore.calculatedExcursion.endedAt,
                                      currentTimezone
                                  ).format(commonConstants.dateTimeFormat)
                                : commonConstants.emptyValue}
                        </LynxTypography>
                    ) : (
                        <>
                            <LynxDateTimePickerForm
                                showNow={false}
                                variant="datetime"
                                customRequestFormat={commonConstants.dateTimeFormatWithTimeZone}
                                showTime
                                format={commonConstants.dateTimeFormat}
                                name="manualExcursion.endDateTime"
                                timezone={currentTimezone}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={2} className={classes.temperateDataGrid}>
                    <LynxTypography
                        className={clsx({
                            [classes.smallPaddingTop]: excursionSource === ExcursionSource.Manual,
                        })}
                    >
                        {formatDurationToString(thorEventViewStore.calculatedExcursion.totalDuration)}
                    </LynxTypography>
                </Grid>
            </Grid>
        </SectionCard>
    );
});
