import { Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { CustomerAuditPage } from "components/userManagement/AuditTrail/CustomerAuditPage";
import UserAuditPage from "components/userManagement/AuditTrail/UserAuditPage";
import CustomerSectionWrapper from "components/userManagement/CustomerSectionWrapper";
import GroupSectionWrapper from "components/userManagement/GroupSectionWrapper";
import CustomersTab from "components/userManagement/tabs/CustomersTab";
import GroupsTab from "components/userManagement/tabs/GroupsTab";
import UsersTab from "components/userManagement/tabs/UsersTab";
import UserSectionWrapper from "components/userManagement/UserSectionWrapper";
import { anyEquals } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { Link } from "react-router-dom";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { userViewStyles } from "./UserViewStyles";

export default observer(function UserView() {
    const { identityStore, permissionsStore, auditStore } = useStore();
    const classes = userViewStyles();

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const permissions = identityStore.isSystemSpace
        ? [
              permissionsStore.getPermissionResult(actions.system.customers.view),
              permissionsStore.getPermissionResult(actions.system.customers.manage),
              permissionsStore.getPermissionResult(actions.system.users.view),
              permissionsStore.getPermissionResult(actions.system.users.manage),
              permissionsStore.getPermissionResult(actions.system.groups.view),
              permissionsStore.getPermissionResult(actions.system.groups.manage),
          ]
        : [
              permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.users.manage, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id),
          ];

    const allTabs = ["users", "groups", "customers"];
    const allowedTabs: string[] = allTabs.filter((x) =>
        anyEquals(
            permissions,
            permissions.filter((y) => y.key.includes(x)),
            MarvelPermissionStatus.Allow
        )
    );

    const { pathname } = useLocation();
    const tab = pathname.split("/")[2];

    const firstAllowedTab = allowedTabs.find((x, index) => index === 0) || "/";

    if (anyEquals(permissions, "all", ...[MarvelPermissionStatus.Loading, undefined])) {
        return (
            <main>
                <FullScreenLoadingIndicator />
            </main>
        );
    }

    return (
        <>
            <Helmet>
                <title>User Management</title>
            </Helmet>

            {/* do not show user management tabs when audit is opened */}
            {!auditStore.currentPageIsAudit && (
                <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                    <Tabs value={tab} className={withSticky(classes.tabList)}>
                        {allowedTabs.includes("users") ? (
                            <Tab label="Users" value="users" component={Link} to="users" className={classes.tab} />
                        ) : null}
                        {allowedTabs.includes("groups") ? (
                            <Tab
                                label="User Groups"
                                value="groups"
                                component={Link}
                                to="groups"
                                className={classes.tab}
                            />
                        ) : null}
                        {allowedTabs.includes("customers") ? (
                            <Tab
                                value="customers"
                                label="Customers"
                                component={Link}
                                to="customers"
                                className={classes.tab}
                            />
                        ) : null}
                    </Tabs>
                </HeaderPortal>
            )}

            <Routes>
                <Route element={<UserSectionWrapper />}>
                    <Route path="users" element={<UsersTab />} />
                    <Route path="users/:userId/audit" element={<UserAuditPage />} />
                </Route>
                <Route element={<GroupSectionWrapper />}>
                    <Route path="groups/*" element={<GroupsTab />} />
                </Route>
                <Route element={<CustomerSectionWrapper />}>
                    <Route path="customers" element={<CustomersTab />} />
                    <Route path="customers/:customerId/audit" element={<CustomerAuditPage />} />
                </Route>
                <Route path="" element={<Navigate to={firstAllowedTab} />} />
                <Route path="*" element={<Navigate to={routes.pageNotFound} />} />
            </Routes>
        </>
    );
});
