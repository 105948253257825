import { Box, CircularProgress, Grid, InputAdornment } from "@material-ui/core";
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteRenderOptionState,
} from "@material-ui/lab";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SearchLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/SearchLoadingIndicator";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import { FieldHookConfig, useField } from "formik";
import useDebounce from "hooks/useDebounce";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { LynxSearchFormProps, SearchOption } from "./Props/LynxSearchFormProps";
import { reusableFormsStyles } from "./ReusableFormsStyles";

export default function LynxSearchForm(props: FieldHookConfig<string> & LynxSearchFormProps) {
    const [field, meta, helpers] = useField(props);
    const [searchValue, setSearchValue] = useState("");
    const debouncedValue = useDebounce<string>(searchValue, 800);

    const { value, onChange, ...fieldRest } = { ...field };
    const {
        onChange: onChangeProps,
        options,
        emptyOption,
        placeholder,
        initialOption,
        loading,
        label,
        avatarEmptyState,
        search,
        customOnChange,
        renderOptionsWithAvatars,
        customValue,
        showCrossIcon = true,
        ...rest
    } = { ...props };

    const classes = reusableFormsStyles({ size: props.inputProps?.size, error: props.inputProps?.error });
    const inputClasses = clsx(classes.inputBackground, props.inputProps?.className);

    const optionsWithEmptyStateIfNotUndefined: SearchOption[] = emptyOption ? [emptyOption, ...options] : [...options];

    const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = e.target.value.trimLeft().replace(/\s{2,}/g, " ") as string;

        if (inputValue.match(/[\\"]/) && inputValue !== "") {
            return;
        }

        setSearchValue(inputValue);
    };

    const handleChange = (
        event: ChangeEvent<{}>,
        value: string | SearchOption | null | (string | SearchOption)[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string | SearchOption> | undefined
    ) => {
        const option = value as SearchOption;
        helpers.setValue(option?.id);
    };

    const renderOption = (option: SearchOption, state: AutocompleteRenderOptionState) => {
        return loading ? (
            <div>
                <SearchLoadingIndicator />
            </div>
        ) : renderOptionsWithAvatars ? (
            <Box display="flex" gridGap="0.5rem" alignItems="center">
                <LynxAvatar firstName={option.id === "" ? avatarEmptyState ?? "-" : option.displayName} />
                <LynxTypography>{option.displayName}</LynxTypography>
            </Box>
        ) : rest.multiple ? (
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    {option.hasAlarm && <LynxIcon name="triangleWarning" className={classes.triangleIcon} />}
                </Grid>
                <Grid item xs={9}>
                    <LynxTypography>{option.displayName}</LynxTypography>
                </Grid>
                <Grid item xs={2}>
                    <LynxCheckBox checked={state.selected} />
                </Grid>
            </Grid>
        ) : (
            <>
                <LynxTypography>{option.displayName}</LynxTypography>
                <LynxButton variant="tertiary">Select</LynxButton>
            </>
        );
    };

    useEffect(() => {
        if (!loading) {
            search(searchValue.trim());
        }
    }, [debouncedValue]);

    return (
        <Autocomplete
            value={customValue ?? rest.value}
            disableClearable
            defaultValue={initialOption}
            options={loading ? [{ id: "-1", displayName: "Loading..." }] : optionsWithEmptyStateIfNotUndefined}
            onChange={customOnChange ?? handleChange}
            filterOptions={(options) => options}
            getOptionLabel={(option) => (option.id === "" ? "" : option.displayName)}
            getOptionSelected={(option, value) => {
                return rest.multiple
                    ? (field.value as unknown as SearchOption[]).some((x) => x.id === value.id) &&
                          option.id === value.id
                    : option.id === value.id;
            }}
            loading={loading}
            renderOption={renderOption}
            classes={{
                listbox: classes.listBox,
                option: classes.option,
            }}
            inputValue={rest.multiple ? searchValue : undefined}
            {...fieldRest}
            {...rest}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <LynxInput
                        name={props.inputProps?.name}
                        error={props.inputProps?.error}
                        assistiveText={props.inputProps?.assistiveText}
                        className={inputClasses}
                        value={searchValue}
                        onChange={props.inputProps?.onChange ?? handleSearch}
                        label={label}
                        placeholder={placeholder}
                        {...params}
                        endAdornment={
                            loading ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <InputAdornment position="end">
                                    {searchValue && showCrossIcon && (
                                        <LynxIcon
                                            name="crossSmall"
                                            className={classes.crossIcon}
                                            onClick={() => {
                                                setSearchValue("");
                                            }}
                                        />
                                    )}
                                    <LynxIcon
                                        name="search"
                                        cursor={"pointer"}
                                        onClick={() => {
                                            if (!loading) {
                                                search(searchValue.trim());
                                            }
                                        }}
                                    />
                                    &nbsp;
                                </InputAdornment>
                            )
                        }
                    />
                </div>
            )}
        />
    );
}
