import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxBreadcrumbsStyles = makeStyles((theme: Theme) =>
    createStyles({
        separatorIcon: {
            margin: "0 0.625rem",
        },

        link: {
            textDecoration: "none",
            color: "inherit",

            "&:focus-visible": {
                outline: theme.outline,
                borderRadius: "0.25rem",
            },

            "&:hover": {
                textDecoration: "underline",
            },
        },

        activeLink: {
            pointerEvents: "none",
        },
    })
);
