import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const thorPaginationStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: "3rem",
            marginBottom: "20px",
        },

        iconContainer: {
            position: "relative",
        },
        leftArrow: {
            position: "absolute",
            top: "19%",
            right: "120%",
        },
        rightArrow: {
            position: "absolute",
            top: "19%",
            left: "120%",
        },

        pagination: {
            "& .MuiPagination-ul": {
                borderRadius: "4px",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: theme.colors.primary.blue100,
                color: theme.colors.primary.blue500,
                cursor: "default",
                "&:hover": {
                    backgroundColor: theme.colors.primary.blue100,
                },
            },
            "& .MuiPaginationItem-page": {
                borderRadius: "4px",
                color: theme.colors.neutrals.neutral400,
                fontFamily: "Gibson-Medium",
                margin: "0px 8px",
                "&:hover": {
                    backgroundColor: theme.colors.primary.blue75,
                },
            },
        },

        select: {
            width: "80px",
            height: "40px",
            marginLeft: "10px",

            "&.Mui-focused": {
                border: `1px solid ${theme.colors.primary.blue400}`,

                "& > .MuiSelect-select": {
                    borderRadius: "4px",
                    backgroundColor: "unset",
                },
            },
        },

        activeIcon: {
            fill: theme.colors.primary.blue500,
        },
        disabledIcon: {
            fill: theme.colors.neutrals.neutral300,
        },
    })
);
